import { css } from '@emotion/css'
import { useState } from 'react'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { Button } from 'components/Button/Button'
import { SharedSettings } from 'settings/SharedSettings'

export const ModalDrawerExample = () => {
  const [primaryModalOpened, setPrimaryModalOpened] = useState<boolean>(false)
  const [secondaryModalOpened, setSecondaryModalOpened] = useState<boolean>(false)

  return (
    <>
      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          padding: 8px 0;
        `}
      >
        <ModalDrawer
          title={'Title of the modal'}
          primaryButtonText={'Primary Action'}
          primaryButtonOnPress={() => alert('Primary button pressed')}
          secondaryButtonText={'Secondary Action'}
          secondaryButtonOnPress={() => alert('Secondary button pressed')}
          opened={primaryModalOpened}
          onClose={() => setPrimaryModalOpened(false)}
          zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
            `}
          >
            <div
              className={css`
                margin-bottom: 30px;
              `}
            >
              Children go here
            </div>
            <button onClick={() => setSecondaryModalOpened(true)}>Open Secondary Drawer</button>
            <ModalDrawer
              title={'Secondary Drawer'}
              primaryButtonText={'Primary Action'}
              primaryButtonOnPress={() => alert('Primary button pressed')}
              opened={secondaryModalOpened}
              onClose={() => setSecondaryModalOpened(false)}
              isSecondaryModal={true}
              zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
            />
          </div>
        </ModalDrawer>
        <Button variant={'primary'} onClick={() => setPrimaryModalOpened(true)}>
          Primary
        </Button>
      </div>
    </>
  )
}
