import { closeModal, openModal } from '@mantine/modals'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import React from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { StackemChartsForModal } from 'swagworld/components/StackemChartsForModal'

const MODAL_ID = 'current-values-popover-stackem-charts'

export const openStackemChartsModal = () => {
  const modalMarginLeftPixels = Math.round(window.innerWidth * 0.1)
  const modalMarginRightPixels = Math.round(window.innerWidth * 0.1)
  const modalWidthPixels = window.innerWidth - modalMarginLeftPixels - modalMarginRightPixels
  const modalPaddingTopPixels = 16
  const modalPaddingRightPixels = 16
  const modalPaddingBottomPixels = 16
  const modalPaddingLeftPixels = 16
  const containerWidthPixels = modalWidthPixels - modalPaddingLeftPixels - modalPaddingRightPixels
  const footerHeightPixels = 40
  const innerPaddingTopPixels = Math.round(window.innerHeight * 0.05)
  const innerPaddingRightPixels = Math.round(window.innerWidth * 0.05)
  const innerPaddingBottomPixels = Math.round(window.innerHeight * 0.05)
  const innerPaddingLeftPixels = Math.round(window.innerWidth * 0.05)

  const stackemChartsWrapperMaxHeightPixels =
    window.innerHeight -
    modalPaddingTopPixels -
    modalPaddingBottomPixels -
    innerPaddingTopPixels -
    innerPaddingBottomPixels -
    footerHeightPixels

  openModal({
    zIndex: SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX - 1,
    size: modalWidthPixels,
    padding: `${modalPaddingTopPixels}px ${modalPaddingRightPixels}px ${modalPaddingBottomPixels}px ${modalPaddingLeftPixels}px`,
    modalId: MODAL_ID,
    fullScreen: false,
    withCloseButton: false,
    styles: {
      inner: {
        paddingTop: `${innerPaddingTopPixels}px !important`,
        paddingRight: `${innerPaddingRightPixels}px !important`,
        paddingBottom: `${innerPaddingBottomPixels}px !important`,
        paddingLeft: `${innerPaddingLeftPixels}px !important`,
      },
    },
    children: (
      <div>
        <div
          css={{
            maxHeight: stackemChartsWrapperMaxHeightPixels,
            minHeight:
              window.innerHeight > SharedSettings.MINIMUM_VIEWPORT_HEIGHT_FOR_DATE_RANGE_PICKER_DROPDOWN
                ? 560 // Set a minHeight to ensure we have enough room for the datepicker drop down
                : undefined, // No need to set a minHeight because the datepicker drop down gets rendered in a ModalDrawer
            overflow: 'auto',
          }}
        >
          <StackemChartsForModal containerWidth={containerWidthPixels} />
        </div>
        <div
          css={{
            height: footerHeightPixels,
            backgroundColor: colors.white,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button onClick={() => closeModal(MODAL_ID)} variant="tertiary">
            {translate.phrases.banyanApp('Done')}
          </Button>
        </div>
      </div>
    ),
  })
}
