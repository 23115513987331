import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import React, { Dispatch, SetStateAction } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { TModalDrawerStep, TNewPresetState } from '../Presets'
import { RequiredMarker } from '../RequiredMarker/RequiredMarker'
import { modalDrawerTitleMaker } from '../_utils/modalDrawerTitleMaker'
import { useModalStepFormState } from '../_utils/useModalStepFormState'
import { ValueGroupCheckbox } from './ValueGroupCheckbox/ValueGroupCheckbox'
import { useOptionsToRenderOut } from './useOptionsToRenderOut'

export const ModalDrawerStep1 = ({
  newPresetState,
  opened,
  setModalDrawerOpen,
  setModalDrawerStep,
  setNewPresetState,
  step,
}: {
  newPresetState: TNewPresetState
  opened: boolean
  setModalDrawerOpen: Dispatch<SetStateAction<boolean>>
  setModalDrawerStep: Dispatch<SetStateAction<TModalDrawerStep>>
  setNewPresetState: Dispatch<SetStateAction<TNewPresetState>>
  step: TModalDrawerStep
}) => {
  const { errorColor, onClose, primaryButtonDisabled, primaryButtonOnPress, showErrors } =
    useModalStepFormState({
      newPresetState,
      opened,
      validator: (s) => !!s?.selectedValueGroups?.length,
      onSuccess: () => setModalDrawerStep((step + 1) as TModalDrawerStep),
      setModalDrawerOpen,
      setModalDrawerStep,
      setNewPresetState,
    })

  const optionsToRenderOut = useOptionsToRenderOut().filter(({ value }) => value !== 'equipment_status')

  return (
    <ModalDrawer
      title={modalDrawerTitleMaker({ isEditing: !!newPresetState?.editingIdHash, step })}
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonText={translate.phrases.banyanApp('Next: Step {{step}}', { step: step + 1 })}
      primaryButtonOnPress={primaryButtonOnPress}
      opened={opened}
      onClose={onClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 300}
    >
      <div css={{ padding: 30, display: 'flex', flexDirection: 'column' }}>
        <h3 css={{ color: showErrors ? errorColor : 'unset' }}>
          <RequiredMarker />
          {translate.phrases.banyanApp('Select the layers to be turned on when this view gets selected.')}
        </h3>
        <p>
          {translate.phrases.banyanApp(
            'Select at least one layer. Selected layer data will be displayed on the map, summary, and details.',
          )}
        </p>
        {optionsToRenderOut.map((o, i, originalArray) => {
          return (
            <React.Fragment key={o.value}>
              {(i === 0 || o.section !== originalArray[i - 1].section) && <h3>{o.section}</h3>}
              <ValueGroupCheckbox
                childLayers={o.childLayers}
                label={o.label}
                newPresetState={newPresetState}
                setNewPresetState={setNewPresetState}
                value={o.value}
              />
            </React.Fragment>
          )
        })}
        {showErrors && (
          <p css={{ color: errorColor }}>{translate.phrases.banyanApp('Select at least one layer')}</p>
        )}
      </div>
    </ModalDrawer>
  )
}
