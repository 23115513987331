import React, { CSSProperties } from 'react'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { useScreenOrientation } from 'stores/smallStore'
import { usePreventPanelDetailsFromBeingOpen } from 'utils/usePreventPanelDetailsFromBeingOpen'
import { useScreenSize } from 'utils/useScreenSize'
import { Interpolation, Theme } from '@emotion/react'

interface MapActionIconsProps {
  children: React.ReactNode
}

export const MapActionIcons: React.FC<MapActionIconsProps> = ({ children }) => {
  const { isWideScreen } = useScreenSize()
  const screenOrientation = useScreenOrientation()
  const preventPanelDetailsFromBeingOpen = usePreventPanelDetailsFromBeingOpen()
  const menuCollapsed = layersNavigationMenuStore.useSelector((s) => s.menuCollapsed)

  const wrapperStyle: Interpolation<Theme> & CSSProperties = isWideScreen
    ? {
        'display': 'flex',
        'flexDirection': 'column-reverse',
        'position': 'absolute',
        'left': menuCollapsed ? '10px' : '320px',
        'bottom': 10,
        'margin':
          'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        '> *': {
          marginBottom: 10,
        },
      }
    : {
        'position': 'absolute',
        'top': 60,
        'right': !preventPanelDetailsFromBeingOpen && screenOrientation === 'landscape' ? '75px' : '12px',
        'margin':
          'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        '> *': {
          marginTop: 10,
        },
      }

  return <div css={wrapperStyle}>{children}</div>
}
