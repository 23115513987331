import moment from 'moment-timezone'
import { EnumIrrigationActivityStatus } from './irrigationActivityChartSettings'

export type TPossibleResponseType = {
  metadata: {
    aggregationInterval: string | null
    flowRate: number
    flowUnitPerHour: string
    flowRateIsDefault: boolean
  }
  timeseries: {
    startTime: string
    endTime: string
    status: string | null
  }[]
}

export const getIntervalForCurrentStamp = ({
  currentTimeStamp,
  activityIntervals,
  timezone,
}: {
  currentTimeStamp: number
  activityIntervals: TPossibleResponseType
  timezone: string
}) => {
  const intervals = activityIntervals?.timeseries || []

  const match = intervals.find((i) => {
    return (
      i.status !== 'error' &&
      moment
        .tz(currentTimeStamp, timezone)
        .isBetween(i.startTime, i.endTime || moment.tz(timezone), undefined, '[)')
    )
  })

  const startDate = match?.startTime
  const start = startDate ? moment.tz(startDate, timezone) : null
  const end = match?.endTime ? moment.tz(match?.endTime, timezone) : null

  return { start, end, status: match?.status || EnumIrrigationActivityStatus.noData }
}
