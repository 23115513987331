import { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  getEmitterTypeToEnum,
  getValueTypeFromEmitterType,
  IRRIGATION_APPLIED_VOLUME_VALUE_TYPE_PREFIX,
  IRRIGATION_PLANNED_VOLUME_VALUE_TYPE_PREFIX,
  TEmitterType,
} from '@semios/app-platform-common'
import { VV } from '@semios/app-platform-value-type-definitions'
import { TIrrigationDeviceStatus } from '@semios/app-platform-value-type-definitions/src/valuesTimeseriesTypes/irrigation'
import { getVolumeUnit } from 'App/Map/PanelDetails/_utils/getVolumeUnit'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import {
  GridTableContentSection,
  GridTableContentSectionItem,
  PossibleValuesRecord,
} from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TValuesCurrentIrrigationZoneValueTypes } from 'stores/mapControlsStore/types'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { getWaterVolumeStringWithUnits } from 'utils/getWaterVolumeStringWithUnits'
import { minutesToHoursAndMinutes } from 'utils/minutesToHoursAndMinutes'
import { sortByKey } from 'utils/sortByKey'
import { irrigationZoneTimestampToValuesDictionaryMaker, TIrrigationZoneValueTypes } from '../../_utils'
import { IrrigationActivityTitleBarChildren } from '../IrrigationActivityTitleBarChildren/IrrigationActivityTitleBarChildren'
import { getRowHeightForLabelCell } from '../_utils/getRowHeightForLabelCell'
import { IrrigationActivityCell } from '../_utils/IrrigationActivityCell/IrrigationActivityCell'
import { IrrigationActivityLabel } from '../_utils/IrrigationActivityLabel/IrrigationActivityLabel'
import { makeLabelForNameAndEmitterType } from '../_utils/makeLabelForNameAndEmitterType'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_IRRIGATION_DETAILS' })

type TGenericValueType =
  VV.DomainTypes.Irrigation.TValuesReturnWithMetaIgnoringKeying['irrigationZones']['irrigationVolumeDurationSemios_emitterBuriedDrip1'][number]

type TValueTypeResponse = {
  metadata: TGenericValueType['metadata']
  timeseries: Record<string, TGenericValueType['timeseries'][number]>
}

type TAppliedAndScheduledValuesType = { applied: TValueTypeResponse[]; scheduled: TValueTypeResponse[] }

type TIrrigationDataType = Record<string, TAppliedAndScheduledValuesType>

const generateItem = ({
  id,
  irrigationZoneId,
  values,
  currentValuesData,
}: {
  id: string
  irrigationZoneId: number
  values: TValueTypeResponse
  currentValuesData: routes.ValuesCurrent.Response['irrigation_zones']
}): {
  height: GridTableContentSectionItem['height']
  id: string
  title: string
  label: React.ReactNode
  labelMinWidth: GridTableContentSectionItem['labelMinWidth']
  render: GridTableContentSectionItem['render']
  valueMinWidth: GridTableContentSectionItem['valueMinWidth']
  values: Record<string, PossibleValuesRecord>
  emitterTypeLabel: string
  name: string
} => {
  const { metadata } = values
  const irrigationZoneName = metadata.name as string
  const emitterTypeForZoneEmitter = metadata.emitterType

  const label = makeLabelForNameAndEmitterType({
    deviceName: irrigationZoneName,
    emitterType: emitterTypeForZoneEmitter,
  })

  const height = getRowHeightForLabelCell({ label, minHeight: 100 })
  const { appliedIrrigationActivityDisplay } = smallStore.getState()
  const isDuration = appliedIrrigationActivityDisplay === 'DURATION'
  const selectedFieldAssetsStorePropertyId = selectedFieldAssetsStore.getState().property
  const propertyData = fieldAssetStore.getState()?.properties?.[selectedFieldAssetsStorePropertyId as number]
  const waterDepthUnit = propertyData?.propertySettings?.waterDepthUnit
  const { rain: rainUnitFromUserSetting } = userDetailsStore.getState()
  const volumeUnit = getVolumeUnit({ waterDepthUnit, rainUnitFromUserSetting })
  const isImperial = volumeUnit === 'INCH'
  const currentValuesForZone = currentValuesData?.[irrigationZoneId]

  let currentValueStatus: TIrrigationDeviceStatus | null = null

  if (currentValuesForZone) {
    const emitterType = getEmitterTypeToEnum(emitterTypeForZoneEmitter)
    const currentValueKey = `irrigationIsOn_${emitterType}_status` as TValuesCurrentIrrigationZoneValueTypes

    currentValueStatus = currentValuesForZone[currentValueKey]?.value as TIrrigationDeviceStatus
  }

  return {
    id,
    title: label,
    emitterTypeLabel: mapApiEmitterTypeToLabel(emitterTypeForZoneEmitter),
    name: irrigationZoneName,
    label: <IrrigationActivityLabel label={label} currentValueStatus={currentValueStatus} />,
    height,
    labelMinWidth: 200,
    valueMinWidth: 120,
    render: (dataPoint: { value: typeof values[keyof typeof values] }) => {
      const noValueDefaultReturn = translate.phrases.templates('-')

      if (!dataPoint) return noValueDefaultReturn

      //@ts-ignore - will be resolved with better PossibleValuesRecord from API
      const { appliedMilliSeconds, appliedVolume, scheduledMilliSeconds, scheduledVolume } = dataPoint

      const getValue = (milliseconds: number | null, volume: number | null): string => {
        if (isNil(milliseconds)) {
          return noValueDefaultReturn
        }

        if (isDuration) {
          const durationMinutes = milliseconds / 60000

          return minutesToHoursAndMinutes(durationMinutes)
        }

        return getWaterVolumeStringWithUnits({
          waterApplied: volume ?? 0,
          volumeUnitToDisplay: isImperial ? 'INCH' : 'MILLIMETER',
          flowUnitPerHour: 'MILLIMETER',
        })
      }

      const value = {
        applied: getValue(appliedMilliSeconds, appliedVolume),
        scheduled: getValue(scheduledMilliSeconds, scheduledVolume),
      }

      return <IrrigationActivityCell label={label} {...value} />
    },
    // @ts-ignore
    values: values?.timeseries || {},
  }
}

export const irrigationZonesActivityContent = ({
  data,
  selectedFieldAssets,
  currentValuesData,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  currentValuesData: routes.ValuesCurrent.Response
}): GridTableContentSection => {
  const title = translate.phrases.banyanApp('Irrigation Activity by Zone Emitter Type - Semios')

  const commonReturnItems = {
    title,
    titleChildren: <IrrigationActivityTitleBarChildren showToggle={false} />,
    id: 'summary-grid-irrigationZones',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const property = fieldAssetStore.getState().properties?.[Number(selectedFieldAssets.property)]

  const allIrrigationZoneEmitters: TFieldAssetValueTypes.TIrrigationZoneEmitter[] = Object.values(
    property?.irrigationZoneEmitters || {},
  )

  // TODO: better message and handling of external control providers
  if (
    !allIrrigationZoneEmitters.filter((emitter) => {
      return !emitter.hasControlProvider
    }).length
  )
    return propertyLacksPermissionSectionMaker(commonReturnItems)

  const allValues = allIrrigationZoneEmitters.reduce((all, irrigationZoneEmitters) => {
    const { irrigationZoneId, emitterType } = getIdAndEmitterTypeFromZoneEmitterTypeKey(
      irrigationZoneEmitters.irrigationZoneEmitterId,
    )

    const appliedValues = irrigationZoneTimestampToValuesDictionaryMaker(
      data,
      getValueTypeFromEmitterType(
        IRRIGATION_APPLIED_VOLUME_VALUE_TYPE_PREFIX,
        emitterType as TEmitterType,
      ) as TIrrigationZoneValueTypes,
      irrigationZoneId,
    )

    const scheduledValues = irrigationZoneTimestampToValuesDictionaryMaker(
      data,
      getValueTypeFromEmitterType(
        IRRIGATION_PLANNED_VOLUME_VALUE_TYPE_PREFIX,
        emitterType as TEmitterType,
      ) as TIrrigationZoneValueTypes,
      irrigationZoneId,
    )

    all[irrigationZoneEmitters.irrigationZoneEmitterId] = {
      applied: appliedValues as TValueTypeResponse[],
      scheduled: scheduledValues as TValueTypeResponse[],
    }

    return all
  }, {} as TIrrigationDataType)

  const gridItems: GridTableContentSectionItem[] = []

  Object.entries(allValues).forEach(
    ([irrigationZoneEmitterId, zoneEmitterData]: [string, TAppliedAndScheduledValuesType]) => {
      const appliedTimeseries = zoneEmitterData?.applied?.[0]?.timeseries || {}
      const scheduledTimeseries = zoneEmitterData?.scheduled?.[0]?.timeseries || {}
      const allKeys = new Set([...Object.keys(appliedTimeseries), ...Object.keys(scheduledTimeseries)])

      const mergedSeries = Array.from(allKeys).reduce(
        (
          acc: Record<
            string,
            {
              appliedMilliSeconds: number | null
              scheduledMilliSeconds: number | null
              appliedVolume: number | null
              scheduledVolume: number | null
            }
          >,
          key,
        ) => {
          const appliedEntry = appliedTimeseries[key]
          const scheduledEntry = scheduledTimeseries[key]

          acc[key] = {
            appliedMilliSeconds: appliedEntry?.value?.duration ?? null,
            appliedVolume: appliedEntry?.value?.volume_mm ?? null,
            scheduledMilliSeconds: scheduledEntry?.value?.duration ?? null,
            scheduledVolume: scheduledEntry?.value?.volume_mm ?? null,
          }

          return acc
        },
        {},
      )

      if (irrigationZoneEmitterId && (zoneEmitterData.applied || zoneEmitterData.scheduled)) {
        const { irrigationZoneId } = getIdAndEmitterTypeFromZoneEmitterTypeKey(
          irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
        )

        const metadata = zoneEmitterData?.applied?.[0]?.metadata || zoneEmitterData?.scheduled?.[0]?.metadata

        gridItems.push(
          generateItem({
            id: irrigationZoneEmitterId,
            irrigationZoneId: +irrigationZoneId,
            values: {
              metadata,
              //@ts-ignore
              timeseries: mergedSeries,
            },
            currentValuesData: currentValuesData.irrigation_zones,
          }),
        )
      }
    },
  )

  return {
    title,
    titleChildren: <IrrigationActivityTitleBarChildren showToggle={true} />,
    id: 'summary-grid-irrigationZones',
    //@ts-ignore
    items: (gridItems || []).sort(sortByKey('emitterTypeLabel')).sort(sortByKey('name')),
  }
}
