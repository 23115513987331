import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { TActiveNode } from 'App/ServiceCenter/types'

export type NodeRemoveMeta = routes.ServiceCenterNodeRemove.Request

export const request = async (param: NodeRemoveMeta) => {
  await apiFetch<routes.ServiceCenterNodeRemove.Request, routes.ServiceCenterNodeRemove.Response>({
    url: routes.ServiceCenterNodeRemove.path,
    body: param,
    params: {
      method: 'POST',
    },
  })

  return param
}

export const callback = async (param: NodeRemoveMeta) => {
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  const updatedNodes = activeNodes.filter(
    (node) => (node as TActiveNode).nodeIdentifier !== param.nodeIdentifier,
  )

  serviceCenterStore.actions.setActiveNodes(updatedNodes)
}

export const serviceCenterNodeRemove = {
  request,
  callback,
}
