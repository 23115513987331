import React, { useMemo, useState } from 'react'
import { Input, Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { installationWorkflowStore } from '../../store/installationWorkflowStore'
import { Footer } from '../components/Footer/Footer'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { showError } from '../../utils/showError'
import { installNodeDevice } from '../../utils/installNodeDevice'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import { TActiveNode, TNodeDevice, TNodeDevices } from '../../types'
import { getDeviceInstallationHeightLabel } from '../../utils/getDeviceInstallationHeightLabel'
import { attachNodeDevices } from 'App/ServiceCenter/utils/attachNodeDevices'
import {
  ManagementTypes,
  NodeDeviceInstallationStatus,
} from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { DEFAULT_MANAGEMENT_TYPE } from 'App/ServiceCenter/utils/constants/defaultManagementType'
import _ from 'lodash'
import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { closeModal, openModal } from '@mantine/modals'
import { ErrorBoundary } from '@sentry/react'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { SharedSettings } from 'settings/SharedSettings'
import { checkAuthorization } from 'utils/checkAuthorization'
import { TPermission } from '@semios/app-platform-value-type-definitions'

interface ConfigureDeviceProps {
  node: TActiveNode
  nodeDevice?: TNodeDevice
  onClose: () => void
}

const connectorOrder = ['RJ11', 'Stereo', 'SDI']

const getDeviceConnectorLabel = (connector: string) => {
  return connector === 'stereo' ? _.upperFirst(connector) : connector.toUpperCase()
}

export const openDeviceFormModal = (node: TActiveNode) => {
  const DEVICE_MODAL_ID = 'device-installation-modal'

  openModal({
    modalId: DEVICE_MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <WideHeader
          title={translate.phrases.placeholder('Install Station')}
          onClose={() => {
            closeModal(DEVICE_MODAL_ID)
          }}
        />
        <div css={{ padding: 10, marginBottom: 50 }}>
          <ConfigureDevice node={node} onClose={() => closeModal(DEVICE_MODAL_ID)} />
        </div>
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

export const ConfigureDevice: React.FC<ConfigureDeviceProps> = ({ node, nodeDevice, onClose }) => {
  const allDevices = fieldAssetStore.useSelector((s) => s.devices)

  const selectedNode = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNode,
  ) as TActiveNode

  const { propertyId, nodeType, maintenanceOwnerId } = selectedNode

  const [selectedDevice, setSelectedDevice] = useState({
    source: nodeDevice?.source || '',
    height: nodeDevice?.height || '',
    port: `${nodeDevice?.connector}-${nodeDevice?.channel}` || '',
  })

  const { source: selectedSource, height: selectedHeight, port: selectedPort } = selectedDevice

  const deviceGroupPermissions: { [deviceGroup: string]: TPermission } = {
    'Frost Fan': 'EDIT_SSC_NODE_DEVICE_FROST_FAN',
    'Leaf Temperature': 'EDIT_SSC_NODE_DEVICE_LEAF_WETNESS',
    'Leaf Wetness': 'EDIT_SSC_NODE_DEVICE_LEAF_WETNESS',
    'Plant Health': 'EDIT_SSC_NODE_DEVICE_DENDROMETER',
    'Soil Moisture': 'EDIT_SSC_NODE_DEVICE_SOIL',
    'Water Flow': 'EDIT_SSC_NODE_DEVICE_WATER_FLOW',
    'Weather': 'EDIT_SSC_NODE_DEVICE_WEATHER',
  }

  const sources = Object.values(allDevices)
    .filter((device) => {
      if (nodeType !== 'rpt') return true

      return device.connector === 'rj11'
    })
    .map((device) => {
      const permission = deviceGroupPermissions[device.deviceGroupName]
      const isInstallable = checkAuthorization({ permission, entity: propertyId })

      return {
        label: device.name,
        value: device.source,
        disabled: !isInstallable,
        group: getDeviceConnectorLabel(device.connector),
      }
    })
    .sort((a, b) => {
      const groupComparison = connectorOrder.indexOf(a.group) - connectorOrder.indexOf(b.group)

      if (groupComparison !== 0) {
        return groupComparison
      }

      // If groups are the same, sort by name
      return a.label.localeCompare(b.label)
    })

  const [isSaving, setIsSaving] = useState(false)

  const configuredDevices = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNodeDevicesAsArray,
  )

  const heightOptions = allDevices[selectedSource]
    ? allDevices[selectedSource].installationHeights.map((height: string) => {
        const heightInUse = configuredDevices
          .filter((device) => device.source === selectedSource)
          .map((device) => device.height)

        return {
          label: getDeviceInstallationHeightLabel(height),
          value: height,
          disabled: heightInUse.includes(height),
        }
      })
    : []

  const connector = allDevices[selectedSource]?.connector
  const portsInUse = configuredDevices?.map((device) => `${device.connector}-${device.channel}`)

  const installationChannelOptions = useMemo(() => {
    return allDevices[selectedSource]?.installationChannels.sort().map((channel) => {
      const value = `${connector}-${channel}`

      // If a device prop is provided, which means user is editing an existing device, we should not disable the port that is currently being edited
      const disabledPorts = nodeDevice
        ? portsInUse.filter((port) => port !== `${nodeDevice.connector}-${nodeDevice.channel}`)
        : portsInUse

      const disabled = disabledPorts.includes(value)

      const configuredSource = configuredDevices.find(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (device) => device.connector === connector && +device.channel! === +channel,
      )?.source

      return {
        label: `${getDeviceConnectorLabel(connector)} - ${channel} ${
          disabled ? ` (${configuredSource})` : ''
        }`,
        value,
        key: channel,
        disabled: disabledPorts.includes(value),
      }
    })
  }, [selectedSource])

  const handleSubmit = async () => {
    setIsSaving(true)

    try {
      if (!selectedHeight) throw new Error(translate.phrases.placeholder('Please select the device height.'))

      const port = selectedPort.split('-')
      const connector = port[0] as keyof TNodeDevices
      const channel: number = parseInt(port[1])
      const { nodeIdentifier } = node

      const shapedDevice = {
        source: selectedSource,
        height: selectedHeight,
        installationStatus: SharedTypes.NodeDeviceInstallationStatus
          .INSTALLED as NodeDeviceInstallationStatus,
        managementType: DEFAULT_MANAGEMENT_TYPE as ManagementTypes,
        maintenanceOwnerId,
      }

      if (nodeDevice?.installationStatus === SharedTypes.NodeDeviceInstallationStatus.PLANNED) {
        // Install planned device
        await installNodeDevice(node.nodeIdentifier, nodeDevice, {
          connector,
          channel,
          height: selectedHeight,
        })

        // Refresh installation steps
        installationWorkflowStore.actions.refreshSteps()
      } else {
        // Update existing devices
        const devicesToAttach = { ...node?.devices }

        if (nodeDevice) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          delete devicesToAttach[nodeDevice.connector!]![nodeDevice.channel!]
        }

        if (!devicesToAttach[connector]) {
          //@ts-ignore
          devicesToAttach[connector] = {
            [channel]: shapedDevice,
          }
        } else {
          //@ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          devicesToAttach[connector]![channel] = shapedDevice
        }

        await attachNodeDevices({ nodeIdentifier, devices: devicesToAttach })

        installationWorkflowStore.actions.refreshSteps()
      }

      onClose()
    } catch (error) {
      setIsSaving(false)

      let errorMessage: string = error as string

      if (error instanceof Error) {
        errorMessage = error.message
      }

      showError(translate.phrases.placeholder('Installation Error'), errorMessage)
    }
  }

  const handleChangeDevice = (value: string, key: string) => {
    if (key === 'source') {
      setSelectedDevice({ source: value, height: '', port: '' })

      return
    }

    setSelectedDevice((prev) => ({ ...prev, [key]: value }))
  }

  return (
    // device selection option is searchable and mobile keyboard hide some options so when keyboard is open, push up entire screen
    <div css={{ padding: 10 }}>
      <h3 css={{ margin: 0 }}>{translate.phrases.placeholder('Configure device')}</h3>

      <p css={{ lineHeight: '24px', marginTop: 10, marginBottom: 20 }}>
        {translate.phrases.placeholder('Select the device height & port below.')}
      </p>
      {nodeDevice?.installationStatus !== SharedTypes.NodeDeviceInstallationStatus.PLANNED && (
        <Input.Wrapper
          id="source"
          label={translate.phrases.placeholder('Device Type')}
          css={{ marginTop: 20 }}
        >
          <Select
            css={{ marginBottom: '16px' }}
            value={selectedSource}
            onChange={(v: string) => handleChangeDevice(v, 'source')}
            placeholder={translate.phrases.placeholder('Device Type')}
            data={sources}
            styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
            searchable
          />
        </Input.Wrapper>
      )}
      {selectedSource && (
        <>
          <Input.Wrapper
            id="height"
            label={translate.phrases.placeholder('Installation Height/Depth')}
            css={{ marginTop: 20 }}
          >
            <Select
              data={heightOptions}
              placeholder={translate.phrases.placeholder('Select height')}
              onChange={(v: string) => handleChangeDevice(v, 'height')}
              value={selectedHeight}
              styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
              searchable
            />
          </Input.Wrapper>
          <Input.Wrapper id="port" label={translate.phrases.placeholder('Port')} css={{ marginTop: 20 }}>
            <Select
              data={installationChannelOptions}
              placeholder={translate.phrases.placeholder('Select port')}
              onChange={(v: string) => handleChangeDevice(v, 'port')}
              value={selectedPort}
              styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
              searchable
            />
          </Input.Wrapper>
        </>
      )}

      <Footer
        onNext={handleSubmit}
        nextButtonLabel={translate.phrases.placeholder('Submit')}
        loading={isSaving}
      />
    </div>
  )
}
