import { useMemo } from 'react'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { translate } from 'i18n/i18n'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { checkAuthorization } from 'utils/checkAuthorization'

export const useFilteredData = ({
  files,
  filterActiveForPermitNumbers,
  filterActiveForProperties,
  filterActiveForTypes,
  filteredPermitNumbers,
  filteredProperties,
  filteredTypes,
  properties,
  searchString,
}: {
  files: routes.PheromonePurRecDownload.Response['files']
  filterActiveForPermitNumbers: boolean
  filterActiveForProperties: boolean
  filterActiveForTypes: boolean
  filteredPermitNumbers: { [permitNumber: string]: boolean }
  filteredProperties: { [propertyId: string]: boolean }
  filteredTypes: { [type: string]: boolean }
  properties: TProperty[]
  searchString: string
}) => {
  return useMemo(() => {
    return files.filter((f) => {
      return [
        {
          /* TYPES */
          isActive: filterActiveForTypes,
          check: () => filteredTypes[f.type],
        },
        {
          /* PROPERTIES */
          isActive: filterActiveForProperties,
          check: () =>
            f.propertyIds.some((propertyId: number) => {
              const property = properties.find((p: TProperty) => p.propertyId === propertyId)

              return property && filteredProperties[propertyId]
            }),
        },
        {
          /* PermitNumbers */
          isActive: filterActiveForPermitNumbers,
          check: () => filteredPermitNumbers[f.permitNumber],
        },
        {
          isActive: !!searchString,
          check: () =>
            searchStringFromStringWithSpaces(
              searchString,
              `${f.propertyIds
                .map((propertyId) => {
                  const property = properties.find((p: TProperty) => p.propertyId === propertyId)

                  return checkAuthorization({ entity: propertyId, permission: 'EXPORT_PHEROMONE_PUR_REC' }) &&
                    property
                    ? property.propertyName
                    : ''
                })
                .join(' ')} ${f.permitNumber} ${f.type} ${
                'year' in f ? f.year : translate.phrases.abbreviations('Not Applicable')
              }`,
            ),
        },
      ].every((f) => !f.isActive || f.check())
    })
  }, [
    files,
    filterActiveForPermitNumbers,
    filterActiveForProperties,
    filterActiveForTypes,
    filteredPermitNumbers,
    filteredProperties,
    filteredTypes,
    properties,
    searchString,
  ])
}
