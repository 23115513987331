import { openStackemChartsModal } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/_utils/openStackemChartsModal'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'

export const ButtonStackemChartsModal = () => {
  return (
    <Button variant="tertiary" fullWidth onClick={() => openStackemChartsModal()}>
      {translate.phrases.banyanApp('Details')}
    </Button>
  )
}
