import { routes } from '@semios/app-platform-banyan-route-definitions'
import { getEmitterTypeToEnum } from '@semios/app-platform-common'
import { TFieldAssetValueTypes } from 'App/Map/types'
import { GridTableContentSection } from 'components/GridTable/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TValuesCurrentIrrigationZoneValueTypes } from 'stores/mapControlsStore/types'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  apiArgsForPressureSensors,
  pressureSensorsActivityContent,
} from './pressureSensorsActivity/pressureSensorsActivity'
import { irrigationZonesActivityContent } from './semiosIrrigationZonesActivity/semiosIrrigationZonesActivity'
import { apiArgsForIrrigationZones } from './_utils/apiArgsForIrrigationZones'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const pressureSensorArgs = apiArgsForPressureSensors({ selectedValueGroups, selectedFieldAssets })
  const irrigationZoneArgs = apiArgsForIrrigationZones({ selectedValueGroups, selectedFieldAssets })

  return {
    ...pressureSensorArgs,
    ...irrigationZoneArgs,
  }
}

export const currentValuesApiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.ValuesCurrent.Request> | null => {
  if (!selectedValueGroups.irrigation_activity || !selectedFieldAssets.property) return null

  const property: TFieldAssetValueTypes.TProperty | undefined =
    fieldAssetStore.getState().properties?.[selectedFieldAssets.property]

  if (!property) return null

  const { irrigationZoneEmitters, points } = property
  const irrigationZoneIds: number[] = []
  const valueTypes: Partial<Record<TValuesCurrentIrrigationZoneValueTypes, boolean>> = {}

  Object.values(irrigationZoneEmitters || {}).forEach((irrigationZoneEmitter) => {
    irrigationZoneIds.push(+irrigationZoneEmitter.irrigationZoneId)

    const emitterType = getEmitterTypeToEnum(irrigationZoneEmitter.emitterType)

    const irrigationStatusKey =
      `irrigationIsOn_${emitterType}_status` as TValuesCurrentIrrigationZoneValueTypes

    valueTypes[irrigationStatusKey] = true
  })

  const returner: Partial<routes.ValuesCurrent.Request> = {}

  if (irrigationZoneIds.length)
    returner.irrigation_zones = {
      irrigationZoneIds: irrigationZoneIds,
      values: valueTypes,
    }

  if (points) {
    const pressureSensorPoints: string[] = []

    Object.entries(points).forEach(([lngLat, point]) => {
      if (point.valuesCurrent.includes('irrigationIsOn_status')) pressureSensorPoints.push(lngLat)
    })

    if (pressureSensorPoints.length)
      returner.points = {
        lngLats: pressureSensorPoints,
        values: valueTypes,
      }
  }

  return returner
}

export const content = ({
  data,
  selectedFieldAssets,
  currentValuesData,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  currentValuesData: routes.ValuesCurrent.Response
}): GridTableContentSection[] => {
  return [
    pressureSensorsActivityContent({ data, selectedFieldAssets, currentValuesData }),
    irrigationZonesActivityContent({ data, selectedFieldAssets, currentValuesData }),
  ].filter((section): section is GridTableContentSection => Boolean(section))
}
