import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { TActiveNode } from 'App/ServiceCenter/types'

export type NodeSwapMeta = routes.ServiceCenterNodeSwap.Request

export const request = async (param: NodeSwapMeta) => {
  await apiFetch<routes.ServiceCenterNodeSwap.Request, routes.ServiceCenterNodeSwap.Response>({
    url: routes.ServiceCenterNodeSwap.path,
    body: param,
    params: {
      method: 'POST',
    },
  })

  return param
}

export const callback = async (param: NodeSwapMeta) => {
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  const swappedInNode = activeNodes.find(
    (node) => (node as TActiveNode).nodeIdentifier === param.nodeIdentifierToInstall,
  )

  // if the request sent when offline, once status back online, active node will be updated by request /service-center-active-nodes-get
  // so we don't need to update active node with swapped in node
  if (swappedInNode) return

  const swappedOutNode = activeNodes.find(
    (node) => (node as TActiveNode).nodeIdentifier === param.nodeIdentifierToRemove,
  ) as TActiveNode

  const updatedNode = {
    ...swappedOutNode,
    nodeIdentifier: param.nodeIdentifierToInstall,
    name: param.newName || null,
  }

  const updatedNodes = activeNodes
    .filter((node) => (node as TActiveNode).nodeIdentifier !== param.nodeIdentifierToRemove)
    .concat([updatedNode])

  serviceCenterStore.actions.setActiveNodes(updatedNodes)
}

export const serviceCenterNodeSwap = {
  request,
  callback,
}
