import { TChartSeries } from 'components/StackedChart/types'
import { alphaColorReplacer, TRGBAColorWith1AtTheEnd } from 'settings/colors'

export const makeCompareSeasonsSeriesFromRegularSeries = (
  baseSeries: TChartSeries & { color?: TRGBAColorWith1AtTheEnd },
  comparisonSeries: Partial<TChartSeries>,
): TChartSeries => {
  return {
    ...baseSeries,
    // IMPORTANT: keep these overrides in sync with generateSeriesForEnvironmentalChart
    ...(baseSeries.color && { color: alphaColorReplacer(baseSeries.color, 0.4) }),
    fillOpacity: 0.3,
    hideFromTooltip: true,
    id: `${baseSeries.id}_compareSeasons`,
    linkedTo: baseSeries.id,
    showInLegend: false,
    /**
     * setting zones as an empty array removes dotted forecast lines. This
     * can't be universalized, but easiest way to do it without bringing
     * in an adjusted latestNonForecast timestamp
     */
    zones: [],
    ...comparisonSeries,
  } as TChartSeries
}
