import React from 'react'
import { TActiveGateway, TActiveNode, TPlannedGateway, TPlannedNode } from '../types'
import { translate } from 'i18n/i18n'
import { openHelpGuideModal } from '../HelpGuideModal/HelpGuideModal'
import { getNodeTypeLabel } from './getNodeTypeLabel'
import { openNodeMaintenanceModal } from '../NodeMaintenanceModal/NodeMaintenanceModal'
import { MenuButton } from '../ActionMenu/MenuButton/MenuButton'
import { isPlannedNode } from './isPlannedNode'
import { getIntercomPrePopulatedMessage } from 'utils/getIntercomPrePopulatedMessage'
import { openRepositionPlannedNodeModal } from './openRepositionPseudonodeModal'
import { openNodeSwapModal } from '../NodeSwapModal/NodeSwapModal'
import { openNodeRemoveModal } from '../NodeRemoveModal/NodeRemoveModal'
import { selectedPropertyHasPermission } from '../../Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { checkAuthorization } from 'utils/checkAuthorization'
import { isSemiosGatewayType } from './getNodeNetworkType'

export function getNodeActions(node: TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway) {
  const equipmentTypeLabel = getNodeTypeLabel(
    (node as TActiveNode).nodeType || (node as TActiveGateway).gatewayType,
  )

  const prePopulatedMessage = getIntercomPrePopulatedMessage({ node: node }) ?? ''

  return (
    <>
      {!isPlannedNode(node) && (
        <MenuButton
          label={translate.phrases.placeholder('{{equipmentType}} Maintenance', {
            equipmentType: equipmentTypeLabel,
          })}
          description={translate.phrases.placeholder('View maintenance actions')}
          onClick={() => openNodeMaintenanceModal(node as TActiveNode)}
        />
      )}

      {isPlannedNode(node) && selectedPropertyHasPermission({ permission: 'EDIT_SSC_INSTALL_PLANNED' }) && (
        <MenuButton
          label={translate.phrases.placeholder('Reposition', {
            equipmentType: equipmentTypeLabel,
          })}
          description={translate.phrases.placeholder('Reposition planned equipment')}
          onClick={() => openRepositionPlannedNodeModal(node)}
        />
      )}

      {!isPlannedNode(node) && !isSemiosGatewayType(node) && (
        <MenuButton
          label={translate.phrases.placeholder('Swap Equipment')}
          description={translate.phrases.placeholder('Swap {{equipmentType}}', {
            equipmentType: equipmentTypeLabel,
          })}
          onClick={() => openNodeSwapModal()}
          disabled={
            !checkAuthorization({
              permission: 'EDIT_SSC_SWAP_NODE',
              entity: (node as TActiveNode).propertyId,
            }) || !checkAuthorization({ permission: 'VIEW_SSC_SWAP_NODE_REASONS', entity: 'FOR_ANY_ENTITY' })
          }
        />
      )}

      {!isPlannedNode(node) && (
        <MenuButton
          label={translate.phrases.placeholder('Remove Equipment', {
            equipmentType: equipmentTypeLabel,
          })}
          disabled={
            !checkAuthorization({
              permission: 'EDIT_SSC_REMOVE_NODE',
              entity: (node as TActiveNode).propertyId,
            }) ||
            !checkAuthorization({ permission: 'VIEW_SSC_REMOVE_NODE_REASONS', entity: 'FOR_ANY_ENTITY' })
          }
          description={translate.phrases.placeholder('Remove {{equipmentType}}', {
            equipmentType: equipmentTypeLabel,
          })}
          onClick={() => openNodeRemoveModal()}
        />
      )}

      <MenuButton
        label={translate.phrases.placeholder('Guides and Resources')}
        description={translate.phrases.placeholder('Access step-by-step instructions')}
        onClick={() => {
          openHelpGuideModal({
            type: 'node',
            equipmentType: (node as TActiveNode).nodeType.toUpperCase(),
          })
        }}
      />

      <MenuButton
        label={translate.phrases.placeholder('Contact Support')}
        description={translate.phrases.placeholder('Reach out to our team for help')}
        onClick={() => {
          // @ts-ignore
          window.Intercom('showNewMessage', prePopulatedMessage)
        }}
      />
    </>
  )
}
