import type { CSSObject } from '@emotion/css'
import { Input } from '@mantine/core'
import { IconSearch } from 'components/icons/IconSearch'
import { ChangeEvent, useState } from 'react'

export const useSearchInput = ({
  placeholder = '',
  maxWidth = 300,
}: {
  placeholder?: string
  maxWidth?: CSSObject['maxWidth']
}) => {
  const [searchString, setSearchString] = useState('')

  const SearchInput = (
    <Input
      css={{ maxWidth }}
      icon={<IconSearch />}
      placeholder={placeholder}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
      value={searchString}
    />
  )

  return {
    SearchInput,
    searchString,
    setSearchString,
  }
}
