import { Select } from '@mantine/core'
import { PanelDetailsContext } from 'App/Map/PanelDetails/PanelDetails'
import { TFieldAssetKeyTypes, TValuesTimeseries } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { useContext, useMemo } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TPointCategory } from 'stores/selectedFieldAssetsStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { sortByKey } from 'utils/sortByKey'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'

type SelectDataItem = { value: TFieldAssetKeyTypes.TLngLat; label: string; group: string }

export type TDropdownSelectorPointProps = {
  valuesTimeseriesToFilterOn?: TValuesTimeseries[]
  pointCategory?: TPointCategory
}

export const DropdownSelectorPoint = ({
  valuesTimeseriesToFilterOn,
  pointCategory,
}: TDropdownSelectorPointProps) => {
  const { selectedPoint } = selectedFieldAssetsStore.useSelector((s) => ({
    selectedPoint: pointCategory ? s[pointCategory] : s.lngLat,
  }))

  const keyToSelectOnChange = pointCategory ?? 'lngLat'
  const { containerWidth } = useContext(PanelDetailsContext)

  const { properties } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties ?? [],
  }))

  const data = useMemo<SelectDataItem[]>(() => {
    return Object.values(properties ?? {})
      .sort(sortByKey('propertyName'))
      .flatMap((p) => {
        if (!p.points) return []

        let filteredPoints = Object.values(p.points)

        if (valuesTimeseriesToFilterOn) {
          filteredPoints = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(p.points),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })
        }

        return filteredPoints
          .sort((a, b) => alphabeticalSort(a.name, b.name))
          .map((point) => ({
            value: point.lngLat as TFieldAssetKeyTypes.TLngLat,
            label: point.name,
            group: p.propertyName,
          }))
      })
  }, [selectedPoint, properties])

  return (
    // can prevent the section from collapsing by passing stopPropagation
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        css={{
          width: containerWidth < 600 ? 200 : Math.min(280, Math.round(containerWidth / 3)),
          border: `1px solid ${colors.grey500}`,
          color: colors.grey800,
          borderRadius: 3,
        }}
        clearable={false}
        data={data}
        filter={mantineSelectSearchFilterProp}
        onChange={(newPoint: TFieldAssetKeyTypes.TLngLat) =>
          setSelectedFieldAsset({ [keyToSelectOnChange]: newPoint })
        }
        placeholder={translate.phrases.banyanApp('Select a Station')}
        searchable
        size="xs"
        spellCheck="false"
        styles={{
          ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
          ...SharedSettings.MANTINE_SELECT_UNDERLINE_SEPARATOR_STYLES,
          dropdown: { marginTop: -6, fontWeight: 'normal' },
          input: { ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input, paddingRight: 28 },
        }}
        value={selectedPoint}
        withinPortal
        zIndex={hubConnectorSettings.zIndexForDropdownSelectorInStackemCharts}
      />
    </div>
  )
}
