import React from 'react'
import { translate } from 'i18n/i18n'
import { Button } from 'components/Button/Button'
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder'
import { Camera, CameraResultType, CameraSource, GalleryPhoto, Photo } from '@capacitor/camera'
import { showError } from '../utils/showError'
import { Group } from '@mantine/core'
import { CapacitorException } from '@capacitor/core'
import * as Sentry from '@sentry/react'
import { checkAuthorization } from 'utils/checkAuthorization'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { TActiveNode } from '../types'

function handlePermissionsError(error: unknown) {
  if (error instanceof CapacitorException) {
    if (error.message.match('denied')) {
      //permission denied
      showError(translate.phrases.placeholder('Permission required'), error.message)

      return
    }

    if (error.message.match('cancelled')) {
      //cancelled
      return
    }
  }

  Sentry.captureException(error)

  throw error
}

const checkSendImagePermission = (propertyId: number) =>
  checkAuthorization({ permission: 'CREATE_SSC_NODE_IMAGE', entity: propertyId })

interface ImageUploadProps {
  image: Photo | GalleryPhoto | null
  setImage: (image: Photo | GalleryPhoto | null) => void
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ image, setImage }) => {
  const selectedNode = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNode,
  ) as TActiveNode

  const handleTakePhoto = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.DataUrl,
      })

      setImage(image)
    } catch (error) {
      handlePermissionsError(error)
    }
  }

  const handleSelectPhoto = async () => {
    try {
      const { photos } = await Camera.pickImages({ limit: 1 })

      setImage(photos[0])
    } catch (error) {
      handlePermissionsError(error)
    }
  }

  return (
    <>
      <ImageWithPlaceholder image={image} />

      <Group spacing="xs" css={{ margin: '10px auto', justifyContent: 'center' }}>
        <Button
          disabled={!checkSendImagePermission(selectedNode?.propertyId)}
          variant="primary"
          onClick={() => handleTakePhoto()}
        >
          {translate.phrases.placeholder('Use Camera')}
        </Button>

        <Button
          disabled={!checkSendImagePermission(selectedNode?.propertyId)}
          variant="secondary"
          onClick={() => handleSelectPhoto()}
        >
          {translate.phrases.placeholder('Select Photo')}
        </Button>
      </Group>
    </>
  )
}
