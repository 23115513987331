import { useDisclosure } from '@mantine/hooks'
import { openStackemChartsModal } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/_utils/openStackemChartsModal'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapStore } from 'stores/mapStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'
import { Label } from '../../Label/Label'
import { BLOCK_VALUE_META, TBlocksValueTypesToPropsDictionary } from '../BlockValuesCache'

export type TBlockBadgeComponentProps = {
  meta: BLOCK_VALUE_META
  valueTypesToPropsDictionary: TBlocksValueTypesToPropsDictionary
}

export const BlockValueBadgeComponent = (props: TBlockBadgeComponentProps) => {
  const [isHovered, { close, open }] = useDisclosure(false)
  const primaryValueGroup = usePrimaryValueGroup()

  const { showMapBadgeNames, valueType } = mapControlsStore.useSelector((s) => ({
    showMapBadgeNames: s.showMapBadgeNames,
    valueType: !primaryValueGroup ? null : s.mapVisualValueGroup.BLOCK[primaryValueGroup]?.valueType,
    otherThingsWeWantToTriggerARefreshOn: [s.heatmapColoring, s.heatmapExtremes],
  }))

  const zoom = mapStore.useSelector((s) => s.zoom)
  const selectedBlock = selectedFieldAssetsStore.useSelector((s) => s.block)
  const ref = useRef<HTMLDivElement>(null)
  const isSelected = Number(props.meta.blockId) === selectedBlock
  const height = 30

  useSetMapOverlayZIndex({ ref, mItem: 'M_Block', isSelected, isHighlighted: false, isHovered })

  useEffect(() => {
    close()
  }, [zoom])

  if (isNil(valueType)) return null

  if (isNil(props.valueTypesToPropsDictionary?.[valueType]?.children)) return null

  return (
    <div
      css={{
        position: 'relative',
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        border: `2px solid ${(isSelected && 'yellow') || 'transparent'}`,
        padding: '0 16px',
        flexDirection: 'column',
        maxWidth: 220,
        ...props.valueTypesToPropsDictionary?.[valueType]?.getContainerCSS?.(),
      }}
      ref={ref}
      onClick={() => {
        setSelectedFieldAsset({ block: props.meta.blockId })

        mapSelectedCardStore.actions.showBlock(props.meta)

        if (HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD) {
          openStackemChartsModal()
        }
      }}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {(isHovered || showMapBadgeNames) && <Label bottom={height + 3}>{props.meta.blockName}</Label>}
      <div
        css={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <div css={{ width: '100%' }}>{props.valueTypesToPropsDictionary?.[valueType]?.children}</div>
      </div>
    </div>
  )
}
