import React from 'react'
import { translate } from 'i18n/i18n'
import { closeModal, openModal } from '@mantine/modals'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { AnyNode, InstallationStep, TActiveNode, TNodeType, TPlannedNode } from '../types'
import { installationWorkflowStore } from '../store/installationWorkflowStore'
import { Review } from './steps/Review'
import { ActivateNode } from './steps/ActivateNode'
import { ConfigureDevice } from './steps/ConfigureDevice'
import { Badge, useMantineTheme } from '@mantine/core'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { Checklist } from './steps/Checklist'
import ConfirmLocation from './steps/ConfirmLocation'
import { GeoJSONPoint, GeoJSONPosition, stringify } from 'wellknown'
import { installPlannedNode } from 'App/ServiceCenter/utils/installPlannedNode'
import { dropInstall } from 'App/ServiceCenter/utils/dropNodeInstall'
import { ManagementTypes } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { fieldAssetStore } from 'stores/fieldAssetStore'

const MODAL_ID = 'node-installation'

export function openNodeInstallationModal() {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <InstallationModal />,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

export const InstallationModal = () => {
  const theme = useMantineTheme()
  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const selectedNode = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNode,
  ) as AnyNode

  const currentStepNumber = installationWorkflowStore.useSelector(
    installationWorkflowStore.selectors.getCurrentStepNumber,
  )

  const installationSteps = installationWorkflowStore.useSelector(
    installationWorkflowStore.selectors.getSteps,
  )

  const dropLocation = installationWorkflowStore.getState().dropInstallLocation as google.maps.LatLngLiteral

  const handleActivateNode = async (param: {
    nodeIdentifier: string
    nodeType?: TNodeType
    propertyId?: string
  }) => {
    const { nodeIdentifier, nodeType, propertyId } = param

    if (dropLocation) {
      // drop install

      const coordinates = [dropLocation.lng, dropLocation.lat] as GeoJSONPosition

      const stringLocation = stringify({
        type: 'Point',
        coordinates,
      })

      await dropInstall({
        nodeIdentifier,
        nodeType: nodeType as TNodeType,
        managementType: 'commercial' as ManagementTypes,
        // TODO: fix API-I to maintenanceOwnerId/managementType to be optional
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        maintenanceOwnerId: properties![parseInt(propertyId as string)].maintenanceOwnerId,
        location: stringLocation,
        propertyId: parseInt(propertyId as string),
      })

      installationWorkflowStore.actions.setDropInstallLocation('INSTALL_COMPLETED')
    } else {
      const location = JSON.parse(selectedNode.location) as GeoJSONPoint

      // pnode install
      await installPlannedNode(selectedNode as TPlannedNode, location, nodeIdentifier)
    }

    installationWorkflowStore.actions.refreshSteps()
  }

  const handleClose = () => {
    closeModal(MODAL_ID)
  }

  const currentStep = currentStepNumber !== null && installationSteps[currentStepNumber]

  if (!currentStep) return null

  return (
    <>
      <WideHeader title={currentStep.title} onClose={handleClose} isSecondaryModal={true} />

      <div css={{ padding: 10, marginBottom: 50 }}>
        <div
          css={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: theme.colors.grey[2],
            textTransform: 'uppercase',
            padding: '10px 10px 0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {currentStep.required && (
            <Badge color="red" css={{ color: theme.colors.midnight[0], textTransform: 'none' }}>
              {translate.phrases.placeholder('Required')}
            </Badge>
          )}
        </div>
        {currentStep.type === InstallationStep.CONFIRM_LOCATION && (
          <ConfirmLocation {...currentStep} onClose={handleClose} />
        )}

        {currentStep.type === InstallationStep.ACTIVATE_NODE && (
          <ActivateNode
            {...currentStep}
            onSubmit={handleActivateNode}
            onClose={handleClose}
            pnode={selectedNode as TPlannedNode}
          />
        )}
        {currentStep.type === InstallationStep.CONFIGURE_DEVICE && (
          <ConfigureDevice
            {...currentStep}
            nodeDevice={currentStep.device}
            node={selectedNode as TActiveNode}
            onClose={handleClose}
          />
        )}

        {currentStep.type === InstallationStep.CHECKLIST && (
          <Checklist {...currentStep} onClose={handleClose} />
        )}

        {currentStep.type === InstallationStep.REVIEW && <Review onClose={handleClose} />}
      </div>
    </>
  )
}
