import { useMantineTheme } from '@mantine/core'
import { isEqual } from 'lodash'
import { CSSProperties, memo } from 'react'
import { colors } from 'settings/colors'
import { TColumn, TDataElement } from '../SummaryTable.types'
import { Cell } from './Cell/Cell'

const BodyRow = ({
  columns,
  onRow,
  row,
  rowCSS: rowCSSProp,
  rowIndex,
}: {
  columns: TColumn[]
  row: TDataElement
  onRow?: (row: TDataElement, rowIndex: number) => { [key: string]: () => void }
  rowCSS?: (row: TDataElement, rowIndex: number) => CSSProperties
  rowIndex: number
}): JSX.Element => {
  const theme = useMantineTheme()
  const onRowThings = onRow ? onRow(row, rowIndex) : {}
  const rowCSS = rowCSSProp ? rowCSSProp(row, rowIndex) : {}

  return (
    <tr
      css={{
        ':nth-of-type(even)': { backgroundColor: colors.grey50 },
        'borderBottom': `1px solid ${theme.colors.grey[1]}`,
        ...rowCSS,
      }}
      {...onRowThings}
    >
      {columns.map((col) => (
        <Cell col={col} key={col.key} row={row} />
      ))}
    </tr>
  )
}

const MemoizedBodyRow = memo(BodyRow, isEqual) as typeof BodyRow

export { MemoizedBodyRow as BodyRow }
