import { colors } from 'settings/colors'

export const IconSidekick = (props: { onMouseEnter?: () => void; onMouseLeave?: () => void }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34 33" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1863 33C26.299 33 33.6863 25.6127 33.6863 16.5C33.6863 7.3873 26.299 0 17.1863 0C8.07358 0 0.686279 7.3873 0.686279 16.5C0.686279 25.6127 8.07358 33 17.1863 33ZM22.3206 16.2766L17.7058 14.5751V14.5464H12.5345C11.9956 14.5464 11.5559 14.0894 11.5559 13.5573C11.5559 13.0252 11.1161 12.5682 10.5772 12.5682C10.0384 12.5682 9.59863 12.9969 9.59863 13.5573C9.59863 14.8804 10.4207 16.0031 11.5559 16.4229V23.226C11.5559 23.5091 11.773 23.7349 12.0452 23.7349H14.0024C14.2746 23.7349 14.4917 23.5091 14.4917 23.2546V19.7211H19.3848V23.2546C19.3848 23.5083 19.6027 23.7349 19.8741 23.7349H21.8313C22.1035 23.7349 22.3206 23.5091 22.3206 23.226V16.2766ZM25.2564 12.0021V11.0129C25.2564 10.7299 25.0393 10.5041 24.7671 10.5041L22.8099 10.5041L22.5922 10.0508C22.4432 9.73674 22.0497 9.48627 21.7169 9.48627L20.0768 9.4863L19.2395 8.61962C18.9337 8.29775 18.4062 8.5242 18.4062 8.97742V13.6868L22.3206 15.1419V14.009L23.2992 14.0089C24.3812 14.0089 25.2564 13.127 25.2564 12.0021ZM21.342 10.5327C21.6134 10.5327 21.8313 10.7307 21.8313 11.0129C21.8313 11.296 21.6134 11.4932 21.342 11.4932C21.0706 11.4932 20.8527 11.2666 20.8527 11.0129C20.8527 10.7593 21.0706 10.5327 21.342 10.5327Z"
        fill={colors.sidekickDark}
      />
    </svg>
  )
}
