import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { DetailsPanelDateRange } from '../../DetailsPanelDateRange/DetailsPanelDateRange'
import { CompareSeasonsSelect } from '../CompareSeasonsSelect/CompareSeasonsSelect'
import { PropertyNameHeader } from '../../_utils/PropertyNameHeader'

export const ChildrenUpper = ({
  containerWidth,
  selectedValueGroups,
}: {
  containerWidth: number
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <div css={{ textAlign: containerWidth < 500 ? 'center' : 'start' }}>
        <PropertyNameHeader />
      </div>
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 20,
          justifyContent: containerWidth < 500 ? 'center' : 'flex-start',
        }}
      >
        <DetailsPanelDateRange selectedValueGroups={selectedValueGroups} />
        <CompareSeasonsSelect />
      </div>
    </div>
  )
}
