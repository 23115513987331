import { translate } from 'i18n/i18n'

export const getTooltipSectionForExternalIntervalsChart = ({
  periodDurationText,
  periodFromDateText,
  periodToDateText,
  appliedWater,
  title,
  chartColor,
  isScheduled,
}: {
  appliedWater: string
  periodDurationText: string
  periodFromDateText: string
  periodToDateText: string
  title: string
  chartColor: string
  isScheduled: boolean
}) => `
<table>
<tr>
<td style="padding-right: 8px;"><div style="width: 16px; height: 16px; border-radius: 2px; border: 2px solid ${chartColor}; background-color: ${chartColor};"></div></td>
<td style="padding-right: 8px;">${title}</td>
  <td><b>${periodDurationText}</b></td>
</tr>
<tr>
  <td style="padding-right: 8px;">&nbsp;</td>
  <td style="padding-right: 8px;">${translate.phrases.banyanApp('From')}</td>
  <td><b>${periodFromDateText}</b></td>
</tr>
<tr>
  <td style="padding-right: 8px;">&nbsp;</td>
  <td style="padding-right: 8px;">${translate.phrases.banyanApp('To')}</td>
  <td><b>${periodToDateText}</b></td>
</tr>
<tr>
<td style="padding-right: 8px;">&nbsp;</td>
<td style="padding-right: 8px;">${
  isScheduled ? translate.phrases.banyanApp('Scheduled Water') : translate.phrases.banyanApp('Applied Water')
}</td>
<td><b>${appliedWater}</b></td>
</tr>
</table>
`
