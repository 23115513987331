import { apiTokenGetter } from 'utils/apiTokenGetter'
import { routeRequiresAuth } from 'utils/routeRequiresAuth'
import { TShowNotificationArgs } from 'utils/showNotification'
import { unauthenticateAppState } from 'utils/unauthenaticateAppState'

export type THubConnectorSettings = {
  enableBlockPolygons: boolean
  enableIrrigationZonePolygons: boolean
  enablePropertyValues: boolean
  onApiFetchStatus401: (res: Response) => Promise<void>
  onShowNotification?: (notification: TShowNotificationArgs) => void
  apiUrl: string
  getAuthHeader: () =>
    | Promise<Record<'X-Token', string>>
    | Promise<Record<'Authorization', `Bearer ${string}`>>
  apiFetchHeaderAppName: string
  zIndexForDropdownSelectorInStackemCharts: number
}

export let hubConnectorSettings: THubConnectorSettings

export const loadHubConnectorSettings = (settings: THubConnectorSettings) => {
  hubConnectorSettings = settings
}

export const loadDefaultHubConnectorSettings = () => {
  loadHubConnectorSettings({
    enableBlockPolygons: true,
    enableIrrigationZonePolygons: true,
    enablePropertyValues: true,
    onApiFetchStatus401: async () => {
      if (routeRequiresAuth()) await unauthenticateAppState()
    },
    onShowNotification: undefined,
    apiUrl: import.meta.env.VITE_API_URL as string,
    getAuthHeader: async () => {
      return { 'X-Token': apiTokenGetter() }
    },
    apiFetchHeaderAppName: 'banyan-app',
    zIndexForDropdownSelectorInStackemCharts: 1,
  })
}
