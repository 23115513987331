import { routes } from '@semios/app-platform-banyan-route-definitions'
import { PrecipitationTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/PrecipitationTitleChildren/PrecipitationTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import {
  StackedChartSection,
  TChartSeries,
  TChartSeriesWithTRGBAColorWith1AtTheEndColor,
} from 'components/StackedChart/types'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { VV } from '@semios/app-platform-value-type-definitions'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { isNil } from 'lodash'

const pointCategory: TPointCategory = 'outOfBlockPoint'
const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WEATHER_DETAILS' })
const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = ['precipitation']

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.precipitation || !selectedFieldAssets[pointCategory]) return {}

  const interval = detailsPanelStore.getState().precipitationAggregation

  const preferredAggregationInterval = {
    preferredAggregationInterval: interval as EAggregationInterval.DAILY | EAggregationInterval.HOURLY,
  }

  const valuesRequested: Partial<
    Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>
  > = {
    precipitation: preferredAggregationInterval,
  }

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.precipitation().categoryTitleWithoutUnit(),
    titleChildren: (
      <PrecipitationTitleChildren
        showAggregationToggle={false}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'stackem-rain',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])
  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const precipitationSeriesId = 'precipitation'
  const series: TChartSeries[] = []

  const precipitationSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
    name: unitConverter.precipitation().shortTitle(),
    id: precipitationSeriesId,
    tooltip: {
      valueDecimals: unitConverter.precipitation().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.precipitation().suffix()}`,
    },
    yAxis: 0,
    data: (data?.points?.[stationLngLat]?.values?.precipitation?.[0]?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.precipitation(d.value.sum).value(),
    ]),
    type: 'area',
    step: 'center',
  }

  series.push(precipitationSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(precipitationSeries, {
        data: (compareSeasonsData?.points?.[stationLngLat]?.values?.precipitation?.[0]?.timeseries ?? []).map(
          (d) => [+new Date(d.timestamp), unitConverter.precipitation(d.value.sum).value()],
        ),
      }),
    )
  }

  const firstForecastTimestamp = !isNil(
    data?.points?.[stationLngLat]?.values?.precipitation?.[0]?.metadata?.forecastStartsAt,
  )
    ? +new Date(
        data?.points?.[stationLngLat]?.values?.precipitation?.[0]?.metadata.forecastStartsAt as string,
      )
    : +new Date()

  const isHourlyAllowed = chooseAmongstUnAggHourlyAndDaily() !== EAggregationInterval.DAILY

  const aggregationOfData =
    data?.points?.[stationLngLat]?.values?.precipitation?.[0]?.metadata.aggregationInterval

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          tooltip: { xDateFormat: getXDateFormat(aggregationOfData === EAggregationInterval.DAILY) },
          series,
        },
      },
    ],
    titleChildren: (
      <PrecipitationTitleChildren
        showAggregationToggle={isHourlyAllowed}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
  }
}
