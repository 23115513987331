import { useDisclosure } from '@mantine/hooks'
import { getMapControlsPointValueTypeAndPointCategory } from 'App/Map/CurrentValuesMap/caches/StationValuesCache/StationBadgeComponent/_utils/getMapControlsPointValueTypeAndPointCategory'
import { openStackemChartsModal } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/_utils/openStackemChartsModal'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'
import { mapStore } from 'stores/mapStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'
import { DataSourceOverlay } from '../../DataSourceOverlay/DataSourceOverlay'
import { Label } from '../../Label/Label'
import { STATION_META, TStationsValueTypesToPropsDictionary } from '../StationValuesCache'

export type TStationBadgeComponentProps = {
  meta: STATION_META
  valueTypesToPropsDictionary: TStationsValueTypesToPropsDictionary
}

export const StationBadgeComponent = (props: TStationBadgeComponentProps) => {
  const [isHovered, { close, open }] = useDisclosure(false)
  const zoom = mapStore.useSelector((s) => s.zoom)
  const primaryValueGroup = usePrimaryValueGroup()

  const { showMapBadgeNames, valueType, pointCategory } = mapControlsStore.useSelector((s) => {
    const { valueType, pointCategory } = getMapControlsPointValueTypeAndPointCategory({
      mapControlsStoreState: s,
      valueGroup: primaryValueGroup,
      isOutOfBlock: props.meta.isOutOfBlock,
    })

    return {
      showMapBadgeNames: s.showMapBadgeNames,
      valueType,
      pointCategory,
      otherThingsWeWantToTriggerARefreshOn: [s.heatmapColoring, s.heatmapExtremes],
    }
  })

  const selectedFieldAssets = selectedFieldAssetsStore.useSelector((s) => s)
  const ref = useRef<HTMLDivElement>(null)
  const isSelected = selectedFieldAssets[pointCategory] === props.meta.lngLat

  useSetMapOverlayZIndex({
    baseZIndex: props.valueTypesToPropsDictionary?.[String(valueType)]?.baseZIndex,
    ref,
    mItem: 'M_InBlockPoint',
    isSelected,
    isHighlighted: false,
    isHovered,
  })

  useEffect(() => {
    close()
  }, [zoom])

  if (isNil(valueType)) return null

  const diameter = props?.valueTypesToPropsDictionary?.[valueType]?.getSize?.() ?? 50

  if (isNil(props.valueTypesToPropsDictionary?.[valueType]?.children)) return null

  const isWeatherStation = Boolean(props.valueTypesToPropsDictionary?.[valueType]?.isWeatherStation)

  return (
    <div
      css={{
        position: 'relative',
        backgroundColor: colors.midnight,
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: isWeatherStation ? 3 : '50%',
        border: `2px solid ${(isSelected && 'yellow') || 'white'}`,
        padding: '0 3px',
        flexDirection: 'column',
        maxWidth: 220,
        width: diameter,
        height: diameter,
        ...props?.valueTypesToPropsDictionary?.[valueType]?.getContainerCSS?.(),
      }}
      ref={ref}
      onClick={() => {
        setSelectedFieldAsset({ lngLat: props.meta.lngLat })

        mapSelectedCardStore.actions.showStation(props.meta)

        if (HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD) {
          openStackemChartsModal()
        }
      }}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {(isHovered || showMapBadgeNames) && <Label bottom={diameter - 3}>{props.meta.name}</Label>}
      <div
        css={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 40,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.valueTypesToPropsDictionary?.[valueType]?.shouldAddDataSourceOverlay && (
          <DataSourceOverlay
            overlayCSS={{
              height: '100%',
              width: '100%',
              left: 0,
            }}
          />
        )}
        <div css={{ width: '100%' }}>{props.valueTypesToPropsDictionary?.[valueType]?.children}</div>
      </div>
    </div>
  )
}
