import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import {
  TValuesCurrentIrrigationZoneValueTypes,
  TValuesCurrentPointsValueTypes,
} from 'stores/mapControlsStore/types'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'
import { sortByKey } from 'utils/sortByKey'

export const useIrrigationSelectData = () => {
  // TODO: determine which properties are in view to dynamically build out the list of emitters
  const { fieldAssetDataMD5Hash, properties } = fieldAssetStore.useSelector((s) => ({
    /**
     * the fieldAssetDataMD5Hash is handy since it is very likely
     * to change when a user's field assets refresh and change
     */
    fieldAssetDataMD5Hash: s.fieldAssetDataMD5Hash,
    properties: s.properties,
  }))

  return useMemo(() => {
    const availableEmittersSet: Set<SharedTypes.TEmitterType> = new Set()

    let hasNorthernHemisphereProperties = false
    let hasSouthernHemisphereProperties = false

    Object.values(properties ?? {}).forEach((property) => {
      hasNorthernHemisphereProperties = hasNorthernHemisphereProperties || !property.isSouthernHemisphere

      hasSouthernHemisphereProperties = hasSouthernHemisphereProperties || property.isSouthernHemisphere

      if (property.points) {
        Object.values(property.points).forEach((pressureTransducer) => {
          if (pressureTransducer.configuration.irrigationEmitterTypesAvailable) {
            pressureTransducer.configuration.irrigationEmitterTypesAvailable.forEach((emitter) =>
              availableEmittersSet.add(emitter),
            )
          }
        })
      }
    })

    const availableEmitters = [...availableEmittersSet]
      .map((emitter) => ({ emitter, translatedName: mapApiEmitterTypeToLabel(emitter) }))
      .sort(sortByKey('translatedName'))

    type TReturnForEmitters = {
      group: string
      label: string
      value: TValuesCurrentPointsValueTypes | TValuesCurrentIrrigationZoneValueTypes
    }

    const optionsData = [
      {
        group: translate.phrases.banyanApp('Any Emitter'),
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Status'),
          labelB: translate.phrases.banyanApp('Any Emitter'),
        }),
        value: 'irrigationIsOn_status',
      },
    ]

    optionsData.push(
      ...availableEmitters.flatMap((e) => {
        const group = e.translatedName
        const emitterType = group

        // TODO: do we need a util for this?
        const camelCasedEmitter = e.emitter
          .split('-')
          .map((word: string, i: number) => (!i ? word : word[0].toUpperCase() + word.slice(1)))
          .join('')

        const returnForEmitter: TReturnForEmitters[] = [
          {
            group,
            label: translate.phrases.templates('{{labelA}} - {{labelB}}', {
              labelA: translate.phrases.banyanApp('Status'),
              labelB: emitterType,
            }),
            value: `irrigationIsOn_${camelCasedEmitter}_status` as TValuesCurrentPointsValueTypes,
          },
          {
            group,
            label: translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
              labelA: translate.phrases.banyanApp('Applied'),
              labelB: translate.phrases.banyanApp('Today'),
              labelC: emitterType,
            }),
            value:
              `irrigationActivity_todaySoFarSum_${camelCasedEmitter}_ms` as TValuesCurrentPointsValueTypes,
          },
          {
            group,
            label: translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
              labelA: translate.phrases.banyanApp('Applied'),
              labelB: translate.phrases.banyanApp('Prev {{count}} Days', { count: 7 }),
              labelC: emitterType,
            }),
            value:
              `irrigationActivity_previous7DaysSum_${camelCasedEmitter}_ms` as TValuesCurrentPointsValueTypes,
          },
          {
            group,
            label: translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
              labelA: translate.phrases.banyanApp('Applied'),
              labelB: translate.phrases.banyanApp('Prev {{count}} Days', { count: 30 }),
              labelC: emitterType,
            }),
            value:
              `irrigationActivity_previous30DaysSum_${camelCasedEmitter}_ms` as TValuesCurrentPointsValueTypes,
          },
        ]

        if (hasNorthernHemisphereProperties) {
          returnForEmitter.push({
            group,
            label: translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
              labelA: translate.phrases.banyanApp('Applied'),
              labelB: translate.phrases.banyanApp('Since {{date}}', {
                date: translate.dates.format(moment.tz('UTC').month(0).date(1), 'MMM D'), // Jan 1
              }),
              labelC: emitterType,
            }),
            value:
              `irrigationActivity_sinceJan1Sum_${camelCasedEmitter}_ms` as TValuesCurrentPointsValueTypes,
          })
        }

        if (hasSouthernHemisphereProperties) {
          returnForEmitter.push({
            group,
            label: translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
              labelA: translate.phrases.banyanApp('Applied'),
              labelB: translate.phrases.banyanApp('Since {{date}}', {
                date: translate.dates.format(moment.tz('UTC').month(3).date(1), 'MMM D'), // Apr 1
              }),
              labelC: emitterType,
            }),
            value:
              `irrigationActivity_sinceApr1Sum_${camelCasedEmitter}_ms` as TValuesCurrentPointsValueTypes,
          })
        }

        if (hasNorthernHemisphereProperties) {
          returnForEmitter.push({
            group,
            label: translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
              labelA: translate.phrases.banyanApp('Applied'),
              labelB: translate.phrases.banyanApp('Since {{date}}', {
                date: translate.dates.format(moment.tz('UTC').month(6).date(1), 'MMM D'), // Jul 1
              }),
              labelC: emitterType,
            }),
            value:
              `irrigationActivity_sinceJul1Sum_${camelCasedEmitter}_ms` as TValuesCurrentPointsValueTypes,
          })
        }

        if (hasSouthernHemisphereProperties) {
          returnForEmitter.push({
            group,
            label: translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
              labelA: translate.phrases.banyanApp('Applied'),
              labelB: translate.phrases.banyanApp('Since {{date}}', {
                date: translate.dates.format(moment.tz('UTC').month(9).date(1), 'MMM D'), // Oct 1
              }),
              labelC: emitterType,
            }),
            value:
              `irrigationActivity_sinceOct1Sum_${camelCasedEmitter}_ms` as TValuesCurrentPointsValueTypes,
          })
        }

        return returnForEmitter
      }),
    )

    return optionsData
  }, [fieldAssetDataMD5Hash])
}
