import { persistentInit } from './_utils/persistentStore/persistentStore'

const storeName = 'feedbackEventStore'

export type TFeedbackEventStore = {
  views: Set<string>
  feedback: Record<string, 'like' | 'dislike' | null>
}

export const feedbackEventStore = persistentInit<TFeedbackEventStore>({
  initialState: {
    views: new Set<string>(),
    feedback: {},
  },
  keysToPutInURL: {
    views: false,
    feedback: false,
  },
  keysToPutInPersistentStorage: {
    views: false,
    feedback: false,
  },
  storeName,
})
