import { TFieldAssetKeyTypes } from 'App/Map/types'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { getZoomThresholds, ZOOM_VISIBILITY } from '../../_utils/zoomVisibility'

export type IRRIGATION_ZONE_META = {
  irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  irrigationZoneEmitterType: TFieldAssetKeyTypes.TEmitterType
  propertyId: number
  irrigationZoneId: number
  bounds: google.maps.LatLngBounds
  latLngs: google.maps.LatLng[]
  onClick: () => void
}

export class IrrigationZonesPolygonsCache extends MapItemsCache<
  IRRIGATION_ZONE_META,
  null,
  google.maps.Polygon
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        const polygon = new o.maps.Polygon({ paths: o.meta.latLngs, map: o.map })

        polygon.addListener('click', () => {
          setSelectedFieldAsset({
            irrigationZoneEmitter: o.meta.irrigationZoneEmitterId,
          })
        })

        return polygon
      },
      renderOverlay: (o) => {
        const isSelected =
          selectedFieldAssetsStore.getState().irrigationZoneEmitter === o.meta.irrigationZoneEmitterId

        let fillOpacity = 0.6

        const currentZoom = o.map.getZoom()

        if (currentZoom !== undefined) {
          const zoomThresholds = getZoomThresholds(currentZoom)

          // if block values or scd values are shown on the map, we want to let them be seen through these
          // polygons, so set fillOpacity to 0
          if (zoomThresholds.IRRIGATION_ZONE === ZOOM_VISIBILITY.OK) {
            fillOpacity = 0
          }
        }

        o.overlay.setOptions({
          map: o.map,
          strokeColor: isSelected ? 'yellow' : 'white',
          strokeWeight: isSelected ? 4 : 2,
          strokePosition: o.maps.StrokePosition.CENTER,
          fillColor: 'transparent',
          fillOpacity,
        })
      },
      hideOverlay: (overlay) => {
        overlay.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
