import React from 'react'
import { GalleryPhoto, Photo } from '@capacitor/camera'
import { IconCamera } from 'components/icons/IconCamera'
import { useMantineTheme } from '@mantine/core'
import { useScreenSize } from 'utils/useScreenSize'

interface ImageWithPlaceholderProps {
  image?: Photo | GalleryPhoto | string | null
  icon?: React.ReactNode
}

export const ImageWithPlaceholder: React.FC<ImageWithPlaceholderProps> = ({ image, icon }) => {
  const theme = useMantineTheme()
  const { isWideScreen } = useScreenSize()

  return (
    <div
      css={{
        aspectRatio: '1 / 1',
        backgroundColor: image ? 'transparent' : theme.colors.grey[1],
        position: 'relative',
        width: isWideScreen ? '400px' : '100%',
        height: '100%',
        marginRight: '10%',
        marginLeft: '10%',
        margin: 'auto',
      }}
    >
      {image ? (
        <img
          src={typeof image === 'string' ? image : (image as Photo).dataUrl || image.webPath}
          css={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <div
          css={{
            width: 150,
            height: 150,
            fontSize: '150px',
            transform: 'translate(-50%, -50%)',
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        >
          {icon || <IconCamera />}
        </div>
      )}
    </div>
  )
}
