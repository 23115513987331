import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { getTimezoneForSelectedProperty } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedProperty'
import { StackedChartSection, TChartSeries, TValueTypeObject } from 'components/StackedChart/types'
import { isEmpty } from 'lodash'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { generateSeriesForEnvironmentalChart } from '../_utils/generateSeriesForEnvironmentalChart/generateSeriesForEnvironmentalChart'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { plotOptionsToHideInactiveSeriesHarderMaker } from '../_utils/plotOptionsToHideInactiveSeriesHarderMaker'
import { lineChartTooltipFormatter } from 'components/StackedChart/_utils/lineChartTooltipFormatter/lineChartTooltipFormatter'
import { updateTooltipForValueSource } from '../_utils/tooltipOptionsForValueSourceCharts'
import { EAggregationInterval, TFieldAssetKeyTypes } from 'App/Map/types'
import { VV } from '@semios/app-platform-value-type-definitions'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import moment from 'moment-timezone'
import { SidekickSummary } from 'components/SidekickSummary/SidekickSummary'
import { translate } from 'i18n/i18n'
import { getTranslatableTemplateForValueTypeGroup } from 'components/SidekickSummary/getTranslatableTemplateForValueTypeGroup'

const pointCategory: TPointCategory = 'weatherPoint'
const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WEATHER_DETAILS' })

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'temperature_ABOVE',
  'temperature_IN',
  'temperature_BELOW',
]

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (
    !selectedValueGroups.air_temperature ||
    !selectedFieldAssets[pointCategory] ||
    !selectedFieldAssets.property
  )
    return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily(),
  }

  const valuesForPoint =
    fieldAssetStore.getState().properties?.[selectedFieldAssets.property]?.points?.[
      selectedFieldAssets[pointCategory]
    ]?.valuesTimeseries || []

  const valuesToRequest = possibleValueTypesToRequest.filter((v) => valuesForPoint.includes(v))

  const valuesRequested = valuesToRequest.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({
  compareSeasonsData,
  data,
}: {
  compareSeasonsData: routes.Values.Response
  data: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.temperatureInCanopy().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'stackem-temperature',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])
  const series: TChartSeries[] = []
  const stationValues = data?.points?.[stationLngLat]?.values
  const hasAboveCanopySensor = !isEmpty(stationValues?.temperature_ABOVE?.[0]?.timeseries)
  const hasInCanopySensor = !isEmpty(stationValues?.temperature_IN?.[0]?.timeseries)
  const hasBelowCanopySensor = !isEmpty(stationValues?.temperature_BELOW?.[0]?.timeseries)

  const isVirtual =
    fieldAssetStore.getState().properties?.[Number(selectedFieldAssetsStore.getState().property)]?.points?.[
      stationLngLat as TFieldAssetKeyTypes.TLngLat
    ]?.isVirtual

  const isDaily =
    stationValues?.temperature_ABOVE?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY ||
    stationValues?.temperature_BELOW?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY ||
    stationValues?.temperature_IN?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY

  const aboveCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasAboveCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.temperature_ABOVE?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsData?.points?.[stationLngLat]?.values
      ?.temperature_ABOVE?.[0] as TValueTypeObject,
    unitConverter: unitConverter.temperatureAboveCanopy,
  })

  const inCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasInCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.temperature_IN?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsData?.points?.[stationLngLat]?.values
      ?.temperature_IN?.[0] as TValueTypeObject,
    unitConverter: unitConverter.temperatureInCanopy,
  })

  const belowCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasBelowCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.temperature_BELOW?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsData?.points?.[stationLngLat]?.values
      ?.temperature_BELOW?.[0] as TValueTypeObject,
    unitConverter: unitConverter.temperatureBelowCanopy,
  })

  series.push(...aboveCanopySeries, ...inCanopySeries, ...belowCanopySeries)

  const timezone = getTimezoneForSelectedProperty()

  const allForecastMoments = [
    stationValues?.temperature_ABOVE?.[0]?.metadata.forecastStartsAt,
    stationValues?.temperature_IN?.[0]?.metadata.forecastStartsAt,
    stationValues?.temperature_BELOW?.[0]?.metadata.forecastStartsAt,
  ]
    .filter(Boolean)
    .map((d) => moment.tz(d, timezone))

  const firstForecastTimestamp = allForecastMoments.length ? +moment.min(allForecastMoments) : +new Date()

  return {
    ...commonReturnItems,
    items: [
      {
        childrenUpper: (
          <div css={{ textAlign: 'right' }}>
            <SidekickSummary<routes.ValuesCurrent.Response>
              context="details-panel"
              queryParams={{
                points: {
                  lngLats: [stationLngLat],
                  values: { air_temperature_sidekick: true },
                },
              }}
              getStatementId={(summaryTemplate: routes.ValuesCurrent.Response) =>
                summaryTemplate.points?.[stationLngLat]?.air_temperature_sidekick?.value?.uuid
              }
              renderFunction={(summaryTemplate: routes.ValuesCurrent.Response) => {
                const dataForPoint = summaryTemplate.points?.[stationLngLat]?.air_temperature_sidekick

                if (dataForPoint) {
                  const {
                    lastYearDiff,
                    lastYearIsWarmer,
                    lastYearMean,
                    nextWeekMax,
                    nextWeekMean,
                    nextWeekMin,
                    nextWeekThreshold,
                    thisWeekMax,
                    thisWeekMin,
                    thisWeekMean,
                    thisWeekThreshold,
                  } = dataForPoint.value.statements.values

                  const templateToUse = getTranslatableTemplateForValueTypeGroup('air_temperature_sidekick')

                  if (!templateToUse) return null

                  const roundingOptions = { decimalPlaces: 0 }

                  return (
                    <div>
                      {translate.phrases.sidekick(templateToUse, {
                        lastYearDiff: unitConverter
                          .temperatureInversionAboveMinusInCanopy(lastYearDiff, roundingOptions)
                          .valueWithSuffix(),
                        lastYearIsWarmer: lastYearIsWarmer
                          ? translate.phrases.sidekick('warmer')
                          : translate.phrases.sidekick('cooler'),
                        lastYearMean: unitConverter
                          .temperatureInCanopy(lastYearMean, roundingOptions)
                          .valueWithSuffix(),
                        nextWeekMax: unitConverter
                          .temperatureInCanopy(nextWeekMax, roundingOptions)
                          .valueWithSuffix(),
                        nextWeekMin: unitConverter
                          .temperatureInCanopy(nextWeekMin, roundingOptions)
                          .valueWithSuffix(),
                        nextWeekMean: unitConverter
                          .temperatureInCanopy(nextWeekMean, roundingOptions)
                          .valueWithSuffix(),
                        nextWeekThreshold: translate.phrases.sidekick(nextWeekThreshold),
                        thisWeekMax: unitConverter
                          .temperatureInCanopy(thisWeekMax, roundingOptions)
                          .valueWithSuffix(),
                        thisWeekMin: unitConverter
                          .temperatureInCanopy(thisWeekMin, roundingOptions)
                          .valueWithSuffix(),
                        thisWeekMean: unitConverter
                          .temperatureInCanopy(thisWeekMean, roundingOptions)
                          .valueWithSuffix(),
                        thisWeekThreshold: thisWeekThreshold,
                      })}
                    </div>
                  )
                }
              }}
            />
          </div>
        ),
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp,
          },
          plotOptions: plotOptionsToHideInactiveSeriesHarderMaker(),
          chart: {
            type: 'line',
          },
          series,
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
            formatter: function (tooltip) {
              let tooltipContents = lineChartTooltipFormatter(this, tooltip, firstForecastTimestamp)

              tooltipContents = updateTooltipForValueSource({
                seriesIds: [aboveCanopySeries[0]?.id, inCanopySeries[0]?.id, belowCanopySeries[0]?.id].filter(
                  Boolean,
                ),
                compareSeasonIds: [
                  aboveCanopySeries[1]?.id,
                  inCanopySeries[1]?.id,
                  belowCanopySeries[1]?.id,
                ].filter(Boolean),
                points: this.points,
                tooltipContents,
                firstForecastTimestamp,
                isVirtual,
              })

              return tooltipContents
            },
          },
        },
      },
    ],
  }
}
