import { TLanguage } from '@semios/app-platform-i18n-core'
import { Select } from 'components/Select/Select'
import { i18n, globalLanguageStore, translate, LANGUAGES } from 'i18n/i18n'
import { FC } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { LanguageSectionProps } from '../../types'
import { updateUserProfile } from 'utils/updateUserProfile'
import { getLanguageLabels } from '../_utils/getLanguageLabel'

export const LanguageSection: FC<LanguageSectionProps> = ({ stateWithTimeout, setStateWithTimeout }) => {
  const { language } = userDetailsStore.getState()
  const globalLanguage = globalLanguageStore.useSelector((lng) => lng)
  const languageLabels = getLanguageLabels()

  const handleLanguageChange = async (newValue: string | null) => {
    if (newValue === null) return

    try {
      setStateWithTimeout('loading')

      const response = await updateUserProfile({ language: newValue as TLanguage })

      if (response) {
        //Success
        userDetailsStore.setState((prevState) => {
          return {
            ...prevState,
            language: newValue as TLanguage,
          }
        })

        await i18n.globalLng.changeLanguage(i18n.findClosestLanguage([newValue]))

        setStateWithTimeout('success')
      }
    } catch (error) {
      //Error
      await i18n.globalLng.changeLanguage(i18n.findClosestLanguage([language]))

      setStateWithTimeout('error')
    }
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0',
        width: '90%',
        maxWidth: 360,
      }}
    >
      <Select
        data={LANGUAGES.map((language) => ({ label: languageLabels[language], value: language }))}
        value={globalLanguage}
        handleOnChange={handleLanguageChange}
        stateWithTimeout={stateWithTimeout}
        label={translate.phrases.banyanApp('Language')}
      />
    </div>
  )
}
