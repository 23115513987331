import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm, zodResolver } from '@mantine/form'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { RequiredMarker } from 'App/Map/UserSettingsMenu/Shared/Presets/RequiredMarker/RequiredMarker'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { NumberInput } from 'components/NumberInput/NumberInput'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TPointCategory, selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { getIsoWithoutTheZ } from 'utils/getIsoWithoutTheZ'
import { showNotification } from 'utils/showNotification'
import { z } from 'zod'
import { defaultValuesRequested } from '../fruitGrowth'

const allowedFruitGrowthForecastCrops: string[] = ['apple', 'citrus']
const pointCategory: TPointCategory = 'fruitGrowthPoint'

type TargetSizeUnitType = 'INCH' | 'MILLIMETER'

type TFruitGrowthSettings = {
  crop: string
  timezone: string
  defaultTargetSize: number | null
  defaultTargetDate: string | null
  defaultTargetSizeUnit: TargetSizeUnitType | null
}

const refreshTheDetailsPanelData = () =>
  detailsPanelStore.setState((s) => ({ ...s, keyForRefreshing: new Date().toISOString() }))

export const FruitGrowthSettings = ({
  crop,
  timezone,
  defaultTargetSize,
  defaultTargetDate,
  defaultTargetSizeUnit,
}: TFruitGrowthSettings) => {
  const [fruitGrowthSettingsOpened, setFruitGrowthSettingsOpened] = useState(false)

  const defaultUnit = userDetailsStore.useSelector(
    (s): TargetSizeUnitType => (s.rain === 'IMPERIAL' ? 'INCH' : 'MILLIMETER'),
  )

  const minTimeMoment = moment.tz(timezone)

  const schema = z.object({
    targetSize: z
      .number({
        required_error: translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('Target Size'),
        }),
      })
      .positive({
        message: translate.phrases.validation('{{label}} must be > {{value}}', {
          label: translate.phrases.banyanApp('Target Size'),
          value: 0,
        }),
      }),
    targetDate: z
      .date({
        required_error: translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('Target Date'),
        }),
      })
      .min(minTimeMoment.toDate(), {
        message: translate.phrases.validation('{{label}} must be >= {{value}}', {
          label: translate.phrases.banyanApp('Target Date'),
          value: translate.dates.format(minTimeMoment, 'ddd, MMM D, YYYY h:mm a (z)'),
        }),
      }) //Target date must be in the future
      .max(moment.tz(timezone).add(1, 'year').toDate()), //Target date must not be more than 1 year from now
    targetSizeUnit: z.enum(['INCH', 'MILLIMETER'], {
      required_error: translate.phrases.validation('{{label}} is required', {
        label: translate.phrases.banyanApp('Target Size Unit'),
      }),
    }),
  })

  const form = useForm({
    validate: zodResolver(schema),
    validateInputOnBlur: true,
  })

  useEffect(() => {
    form.setValues({
      targetSize: !isNil(defaultTargetSize) ? defaultTargetSize : undefined,
      targetDate: !isNil(defaultTargetDate)
        ? new Date(getIsoWithoutTheZ(moment.tz(defaultTargetDate, timezone)))
        : undefined,
      targetSizeUnit: !isNil(defaultTargetSizeUnit) ? defaultTargetSizeUnit : defaultUnit,
    })
  }, [fruitGrowthSettingsOpened, defaultTargetSize, defaultTargetDate, defaultTargetSizeUnit, defaultUnit])

  const fruitGrowthSettingsVisible = allowedFruitGrowthForecastCrops.includes(crop)

  const { selectedFruitDendrometerStation, propertyId } = selectedFieldAssetsStore.useSelector((s) => ({
    propertyId: s.property,
    selectedFruitDendrometerStation: s[pointCategory],
  }))

  const selectedDendrometerName = fieldAssetStore.useSelector(
    (s) =>
      s?.properties?.[Number(propertyId)]?.points?.[
        selectedFruitDendrometerStation as TFieldAssetKeyTypes.TLngLat
      ]?.name ?? 'Unnamed Station', // note: not wrapped in t, for consistency with the dropdowns
  )

  if (
    !selectedFruitDendrometerStation ||
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(defaultValuesRequested),
      pointCategory,
    })
  )
    return null

  const handleUpdate = async () => {
    const newSettingsValues = form.values

    const userTimezoneFormattedDate = moment
      .tz(newSettingsValues.targetDate, moment.tz.guess())
      .format('YYYY-MM-DD')

    const queryParams = {
      url: routes.FieldAssetSettingsSet.path,
      body: {
        fruitGrowthSettingsUpdate: [
          {
            fruitDendrometerLngLat: selectedFruitDendrometerStation as string,
            targetDate: moment.tz(userTimezoneFormattedDate, timezone).toISOString(),
            targetSizeValue: newSettingsValues.targetSize,
            targetSizeUnit: newSettingsValues.targetSizeUnit,
          },
        ],
      },
    }

    try {
      const response = await apiFetch<
        routes.FieldAssetSettingsSet.Request,
        routes.FieldAssetSettingsSet.Response
      >(queryParams)

      if (
        Array.isArray(response?.fruitGrowthSettingsUpdate) &&
        response.fruitGrowthSettingsUpdate[0].fruitDendrometerLngLat
      ) {
        if (+new Date(detailsPanelStore.getState().dateTo) < +new Date(newSettingsValues.targetDate)) {
          detailsPanelStore.setState((s) => ({
            ...s,
            dateTo: getIsoWithoutTheZ(moment.tz(userTimezoneFormattedDate, timezone).endOf('day')),
          }))
        }

        setFruitGrowthSettingsOpened(false)

        showNotification({
          message: translate.phrases.banyanApp('Successfully updated settings'),
          type: 'success',
        })

        refreshTheDetailsPanelData()
      } else {
        showNotification({
          message: translate.phrases.banyanApp('Failed to update fruit growth setting'),
          type: 'error',
        })
      }
    } catch (error) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update fruit growth setting'),
        type: 'error',
      })
    }
  }

  const handleDelete = async () => {
    const queryParams = {
      url: routes.FieldAssetSettingsSet.path,
      body: {
        fruitGrowthSettingsDelete: [{ fruitDendrometerLngLat: selectedFruitDendrometerStation as string }],
      },
    }

    try {
      const response = await apiFetch<
        routes.FieldAssetSettingsSet.Request,
        routes.FieldAssetSettingsSet.Response
      >(queryParams)

      if (
        Array.isArray(response?.fruitGrowthSettingsDelete) &&
        response.fruitGrowthSettingsDelete[0].fruitDendrometerLngLat
      ) {
        setFruitGrowthSettingsOpened(false)

        showNotification({
          message: translate.phrases.banyanApp('Successfully deleted settings'),
          type: 'success',
        })

        refreshTheDetailsPanelData()
      } else {
        showNotification({
          message: translate.phrases.banyanApp('Failed to delete fruit growth setting'),
          type: 'error',
        })
      }
    } catch (error) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to delete fruit growth setting'),
        type: 'error',
      })
    }
  }

  const deleteButtonDisabled =
    isNil(defaultTargetSize) || isNil(defaultTargetDate) || isNil(defaultTargetSizeUnit)

  const formUnchanged =
    form.values.targetSize === defaultTargetSize &&
    form.values.targetSizeUnit === defaultTargetSizeUnit &&
    moment.tz(form.values.targetDate, timezone).toISOString() === defaultTargetDate

  const updateButtonDisabled = !form.isValid() || !form.isTouched() || formUnchanged

  return fruitGrowthSettingsVisible ? (
    <Authorization requires={{ permission: 'EDIT_FRUIT_DENDROMETER_SETTINGS', entity: Number(propertyId) }}>
      <>
        <ModalDrawer
          title={translate.phrases.banyanApp('Edit Fruit Growth Settings')}
          opened={fruitGrowthSettingsOpened}
          onClose={() => {
            form.reset()

            setFruitGrowthSettingsOpened(false)
          }}
          primaryButtonText={translate.phrases.banyanApp('Save Changes')}
          primaryButtonOnPress={handleUpdate}
          primaryButtonDisabled={updateButtonDisabled}
          secondaryButtonText={translate.phrases.banyanApp('Delete')}
          secondaryButtonDisabled={deleteButtonDisabled}
          secondaryButtonOnPress={handleDelete}
          zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
        >
          <div css={{ padding: 20 }}>
            <div css={{ fontWeight: 'bold', marginTop: 20 }}>{selectedDendrometerName}</div>

            <div css={{ marginTop: 30 }}>
              <form onSubmit={form.onSubmit(() => null)}>
                <div css={{ display: 'flex', flexDirection: 'row' }}>
                  <div css={{ fontWeight: 'bold', marginRight: 5 }}>
                    {translate.phrases.banyanApp('Target Size')}
                  </div>
                  <RequiredMarker />
                </div>
                <div css={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                  <NumberInput
                    precision={2}
                    style={{ width: 110 }}
                    step={0.01}
                    stepHoldDelay={500}
                    stepHoldInterval={100}
                    {...form.getInputProps('targetSize')}
                  />
                  <div css={{ marginLeft: 25 }}>
                    <Select
                      data={[
                        { value: 'INCH', label: translate.phrases.banyanApp('Inch') },
                        { value: 'MILLIMETER', label: translate.phrases.banyanApp('Millimeter') },
                      ]}
                      style={{ width: 165 }}
                      {...form.getInputProps('targetSizeUnit')}
                      value={form.values?.targetSizeUnit}
                      onChange={(newTargetSizeUnit: TargetSizeUnitType) => {
                        form.setFieldValue('targetSizeUnit', newTargetSizeUnit)
                      }}
                    />
                  </div>
                </div>
                <div css={{ marginTop: 30 }}>
                  <div css={{ display: 'flex', flexDirection: 'row' }}>
                    <div css={{ fontWeight: 'bold', marginRight: 5 }}>
                      {translate.phrases.banyanApp('Target Date')}
                    </div>
                    <RequiredMarker />
                  </div>
                  <DatePickerInput
                    rightSection={
                      <FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />
                    }
                    style={{ width: 270, marginTop: 5 }}
                    {...form.getInputProps('targetDate')}
                    value={!isNil(form.values?.targetDate) ? form.values?.targetDate : undefined}
                    onChange={(newTargetDate) =>
                      !isNil(newTargetDate) && form.setFieldValue('targetDate', newTargetDate)
                    }
                    minDate={moment.tz(timezone).add(1, 'day').toDate()}
                    maxDate={moment.tz(timezone).add(1, 'years').toDate()}
                    valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
                  />
                </div>
              </form>
            </div>
          </div>
        </ModalDrawer>
        <Button
          variant="tertiary"
          onClick={() => setFruitGrowthSettingsOpened(true)}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          style={{ marginLeft: 20, marginBottom: 20 }}
        >
          {translate.phrases.banyanApp('Edit Fruit Growth Settings')}
        </Button>
      </>
    </Authorization>
  ) : null
}
