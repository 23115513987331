import { TFieldAssetValueTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { checkAuthorization } from 'utils/checkAuthorization'

export const PropertyCell = ({
  properties,
  propertyIds,
}: {
  properties: TFieldAssetValueTypes.TProperty[]
  propertyIds: number[]
}) => {
  const propertyMap = properties.reduce((map, property) => {
    map[property.propertyId] = property.propertyName

    return map
  }, {} as Record<number, string>)

  let firstNonNullItem = true

  return (
    <>
      {propertyIds
        .filter((entity) => checkAuthorization({ entity, permission: 'EXPORT_PHEROMONE_PUR_REC' }))
        .map((propertyId) => {
          let name = translate.phrases.banyanApp('Unknown')

          try {
            name = propertyMap[propertyId]
          } catch (err) {}

          if (!name) {
            return null
          }

          const isFirstNonNullItem = firstNonNullItem

          firstNonNullItem = false

          return (
            <div key={propertyId} css={{ display: 'flex', flexDirection: 'column' }}>
              {!isFirstNonNullItem && <div css={{ marginTop: 12 }} />}
              <strong>{name}</strong>
            </div>
          )
        })}
    </>
  )
}
