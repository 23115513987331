import { defaultValuesRowHeight } from '../../_utils'

export const getRowHeightForLabelCell = ({
  label,
  minHeight = defaultValuesRowHeight,
}: {
  label: string
  minHeight?: number
}) => {
  const charCount = label.length
  const defaultHeight = minHeight > defaultValuesRowHeight ? minHeight : defaultValuesRowHeight

  if (charCount < 30) return defaultHeight

  if (charCount >= 30 && charCount < 40) return defaultHeight > 90 ? defaultHeight : 90

  if (charCount >= 40 && charCount < 60) return defaultHeight > 110 ? defaultHeight : 110

  if (charCount >= 60 && charCount < 80) return defaultHeight > 140 ? defaultHeight : 140

  if (charCount >= 80 && charCount < 90) return defaultHeight > 170 ? defaultHeight : 170

  return defaultHeight > 200 ? defaultHeight : 200
}
