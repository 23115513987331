import { translate } from 'i18n/i18n'
import { getConvertedWaterVolume } from 'utils/getConvertedWaterVolume'

export const getWaterVolumeStringWithUnits = ({
  waterApplied,
  volumeUnitToDisplay,
  flowUnitPerHour,
}: {
  waterApplied: number
  volumeUnitToDisplay: 'INCH' | 'MILLIMETER'
  flowUnitPerHour: string
}): string => {
  const appliedWater = getConvertedWaterVolume({ waterApplied, volumeUnitToDisplay, flowUnitPerHour })

  if (volumeUnitToDisplay === 'MILLIMETER')
    return translate.measurements.millimeters.valueWithUnit(appliedWater, 2)

  return translate.measurements.inches.valueWithUnit(appliedWater, 2)
}
