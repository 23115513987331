import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { colors } from 'settings/colors'
import { DownloadedFilesContext } from '../../PheromonePurRecDownloadModal'

export const DownloadCell = ({
  pdfUrl,
  setDownloadedFiles,
}: {
  pdfUrl: string
  setDownloadedFiles: Dispatch<SetStateAction<Record<string, boolean>>>
}) => {
  const downloadedFiles: Record<string, boolean> = useContext(DownloadedFilesContext)
  const downloaded = !!downloadedFiles[pdfUrl]

  return (
    <React.Fragment>
      <a
        href={pdfUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => setDownloadedFiles((prev: Record<string, boolean>) => ({ ...prev, [pdfUrl]: true }))}
      >
        <FontAwesomeIcon
          css={{
            'transition': '200ms ease all',
            'color': colors.primary,
            ':hover': { color: colors.primaryLight },
          }}
          icon={faDownload}
          size="lg"
        />
      </a>
      {downloaded && <FontAwesomeIcon icon={faCheckCircle} css={{ marginLeft: 20, color: colors.green }} />}
    </React.Fragment>
  )
}
