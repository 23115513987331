import { StackedChartSection } from 'components/StackedChart/types'
import { alphaColorReplacer, colors, TRGBAColorWithOpacityAtTheEnd } from 'settings/colors'
import { SeriesOptionsType } from 'highcharts'
import { groupBy } from 'lodash'

export const applyColorPalette = (content: StackedChartSection[]): StackedChartSection[] => {
  return content.map((section) => {
    return {
      ...section,
      items: section.items.map((item) => {
        return {
          ...item,
          chartConfig: {
            ...item.chartConfig,
            series: applyColorToSeries(item.chartConfig.series || []),
          },
        }
      }),
    }
  })
}

const applyColorToSeries = (series: SeriesOptionsType[]): SeriesOptionsType[] => {
  const originalGroupedSeries = groupBy(series, 'name')
  const groupedSeries = sanitizeGroup(originalGroupedSeries)
  const groupCount = Object.keys(groupedSeries).length
  const groupedSeriesValue = Object.values(groupedSeries)
  const fadingOpacity = 0.4

  let colorsRule: TRGBAColorWithOpacityAtTheEnd[][]

  switch (groupCount) {
    case 1:
      colorsRule = [[colors.midnight, colors.green]]

      break

    case 2:
      colorsRule = [
        [colors.green, colors.green800],
        [colors.orange, colors.orange800],
      ]

      break

    case 3:
      colorsRule = [
        [colors.green, colors.green800],
        [colors.orange, colors.orange800],
        [colors.blue, colors.blue800],
      ]

      break

    default:
      colorsRule = [
        [colors.green, alphaColorReplacer(colors.green, fadingOpacity)],
        [colors.orange, alphaColorReplacer(colors.orange, fadingOpacity)],
        [colors.blue, alphaColorReplacer(colors.blue, fadingOpacity)],
        [colors.pink, alphaColorReplacer(colors.pink, fadingOpacity)],
        [colors.purple, alphaColorReplacer(colors.purple, fadingOpacity)],
        [colors.yellow, alphaColorReplacer(colors.yellow, fadingOpacity)],
        [colors.red, alphaColorReplacer(colors.red, fadingOpacity)],
        [colors.pink800, alphaColorReplacer(colors.pink800, fadingOpacity)],
        [colors.green800, alphaColorReplacer(colors.green800, fadingOpacity)],
        [colors.blue800, alphaColorReplacer(colors.blue800, fadingOpacity)],
        [colors.purple800, alphaColorReplacer(colors.purple800, fadingOpacity)],
        [colors.yellow800, alphaColorReplacer(colors.yellow800, fadingOpacity)],
        [colors.red800, alphaColorReplacer(colors.red800, fadingOpacity)],
        [colors.orange800, alphaColorReplacer(colors.orange800, fadingOpacity)],
        [colors.pink500, alphaColorReplacer(colors.pink500, fadingOpacity)],
        [colors.green500, alphaColorReplacer(colors.green500, fadingOpacity)],
        [colors.blue600, alphaColorReplacer(colors.blue600, fadingOpacity)],
        [colors.purple300, alphaColorReplacer(colors.purple300, fadingOpacity)],
        [colors.yellow500, alphaColorReplacer(colors.yellow500, fadingOpacity)],
        [colors.red500, alphaColorReplacer(colors.red500, fadingOpacity)],
        [colors.orange500, alphaColorReplacer(colors.orange500, fadingOpacity)],
        [colors.pink200, alphaColorReplacer(colors.pink200, fadingOpacity)],
        [colors.green200, alphaColorReplacer(colors.green200, fadingOpacity)],
        [colors.blue200, alphaColorReplacer(colors.blue200, fadingOpacity)],
        [colors.purple200, alphaColorReplacer(colors.purple200, fadingOpacity)],
        [colors.yellow200, alphaColorReplacer(colors.yellow200, fadingOpacity)],
        [colors.red200, alphaColorReplacer(colors.red200, fadingOpacity)],
        [colors.orange200, alphaColorReplacer(colors.orange200, fadingOpacity)],
      ]
  }

  for (let i = 0; i < colorsRule.length; i++) {
    for (let j = 0; j < colorsRule[i].length; j++) {
      if (groupedSeriesValue[i] && groupedSeriesValue[i][j] && !groupedSeriesValue[i][j].color) {
        if (groupedSeriesValue[i][j].type === 'arearange') {
          // IMPORTANT: keep these overrides in sync with
          // generateSeriesForEnvironmentalChart and makeCompareSeasonsSeriesFromRegularSeries
          groupedSeriesValue[i][j].color = alphaColorReplacer(colorsRule[i][j - 1], 0.4)
        } else {
          groupedSeriesValue[i][j].color = colorsRule[i][j]
        }
      }
    }
  }

  return groupedSeriesValue.flat()
}

const sanitizeGroup = (groupedSeries: {
  [key: string]: SeriesOptionsType[]
}): {
  [key: string]: SeriesOptionsType[]
} => {
  const result: { [key: string]: SeriesOptionsType[] } = {}

  Object.keys(groupedSeries).forEach((key) => {
    const nestedKeys = Object.keys(groupedSeries).filter((k) => k.startsWith(key) && k !== key)

    if (nestedKeys.length > 0) {
      result[key] = [
        ...groupedSeries[key],
        ...nestedKeys.reduce((acc, k) => {
          return acc.concat(groupedSeries[k])
        }, [] as SeriesOptionsType[]),
      ]
    } else if (!Object.keys(result).some((k) => key.startsWith(k))) {
      result[key] = groupedSeries[key]
    }
  })

  return result
}
