import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { getWaterVolumeStringWithUnits } from 'utils/getWaterVolumeStringWithUnits'

export const getAppliedWater = ({
  flowRatePerHour,
  flowUnitPerHour,
  intervalData,
  timezone,
  volumeUnitToDisplay,
}: {
  flowRatePerHour: number
  timezone: string
  flowUnitPerHour: string
  intervalData: {
    startTime: string
    endTime: string | null
    status: string
  }
  volumeUnitToDisplay: 'INCH' | 'MILLIMETER'
}) => {
  const noDataValue = translate.phrases.templates('-')

  if (!intervalData) return noDataValue

  const { startTime, endTime, status } = intervalData

  if (status !== 'on' && status !== 'off') return noDataValue

  let waterApplied = 0

  if (status === 'on') {
    const endTimeMoment = endTime ? moment.tz(endTime, timezone) : moment.tz(timezone)
    const minutesApplied = endTimeMoment.diff(startTime, 'minutes')

    if (minutesApplied) {
      waterApplied = +((minutesApplied / 60) * flowRatePerHour)
    }
  }

  return getWaterVolumeStringWithUnits({ waterApplied, volumeUnitToDisplay, flowUnitPerHour })
}
