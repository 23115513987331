import { EMITTER_TYPES, getValueTypeFromEmitterType } from '@semios/app-platform-common'
import { css } from '@emotion/css'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { translate } from 'i18n/i18n'
import { smallStore, TAppliedIrrigationActivityDisplayOption } from 'stores/smallStore'

const irrigationActivityValuesPrefixes = [
  'irrigationActivityIntervalsSemios_',
  'irrigationActivityIntervalsSemios_',
  'irrigationActivityPlannedIntervalsSemios_',
  'irrigationActivityPlannedIntervalsSemios_',
  'irrigationPlannedVolumeDurationSemios_',
  'irrigationPlannedVolumeDurationSemios_',
  'irrigationVolumeDurationSemios_',
  'irrigationVolumeDurationSemios_',
] as const

const everyPossibleIrrigationActivityTimeseriesValue = irrigationActivityValuesPrefixes.flatMap((key) =>
  EMITTER_TYPES.map((emitterType) => getValueTypeFromEmitterType(key, emitterType)),
)

export const IrrigationActivityTitleBarChildren = ({ showToggle }: { showToggle: boolean }) => {
  const appliedIrrigationActivityDisplay = smallStore.useSelector((s) => s.appliedIrrigationActivityDisplay)

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '4px 12px' }}>
      {/* TODO: We'll probably need to modify this for wiseconn/control providers? */}
      <DropdownSelectorProperty valuesTimeseriesToFilterOn={everyPossibleIrrigationActivityTimeseriesValue} />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {showToggle && (
          <ToggleButton
            buttonProps={{ size: 'xs', className: css({ fontSize: 13 }) }}
            data={[
              { label: translate.phrases.banyanApp('Volume'), value: 'VOLUME' },
              { label: translate.phrases.banyanApp('Duration'), value: 'DURATION' },
            ]}
            onChange={(newValue: TAppliedIrrigationActivityDisplayOption) =>
              smallStore.setState((s) => ({
                ...s,
                appliedIrrigationActivityDisplay: newValue,
              }))
            }
            value={appliedIrrigationActivityDisplay}
          />
        )}
      </div>
    </div>
  )
}
