import { APP_PLATFORM_VERSION } from '@semios/app-platform-version'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { init } from 'stores/_utils/simple-store'
import { ionicStore } from '../../../../ionicStorage'
import { convertSearchToObject } from './convertSearchToObject'

export const populateStoreMaker =
  <TPersistentInitState extends Record<string, unknown>>({
    initialState,
    store,
    storeName,
  }: {
    initialState: TPersistentInitState
    store: ReturnType<typeof init<TPersistentInitState>>
    storeName: string
  }) =>
  async () => {
    /**
     * when populating the store, we'll use the
     * following order of preference:
     *   1. from the URL
     *   2. from ionic's persisted state
     *   3. from the default state for the store
     *
     * to do this, we'll fetch the URL state, and the ionic
     * state. We can loop over the initial state, and for
     * each key, we'll pluck it from the URL if available,
     * otherwise we'll pluck it from the persisted storage
     * if available, otherwise we'll just use the initialState
     */
    const stateFromURL: Partial<TPersistentInitState> = HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD
      ? {}
      : convertSearchToObject(storeName)

    const persistedState: Partial<TPersistentInitState> = await ionicStore
      .get(storeName)
      .then(
        async (
          res: (Partial<TPersistentInitState> & { storeVersion: string }) | null,
        ): Promise<Partial<TPersistentInitState>> => {
          const { storeVersion, ...restOfStore } = res ?? {}

          if (storeVersion !== APP_PLATFORM_VERSION) {
            await ionicStore.remove(storeName)

            return {}
          }

          return restOfStore as unknown as Partial<TPersistentInitState>
        },
      )

    const stateToSet = Object.entries(initialState).reduce(
      (a: Partial<TPersistentInitState>, [key, initialStateVal]) => {
        if (key in stateFromURL) {
          return { ...a, [key]: stateFromURL[key] }
        }

        if (key in persistedState) {
          return { ...a, [key]: persistedState[key] }
        }

        return { ...a, [key]: initialStateVal }
      },
      {},
    ) as TPersistentInitState

    store.setState(() => stateToSet)
  }
