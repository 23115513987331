import { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortByKey } from '@semios/app-platform-common'
import { FilterListPopover } from 'components/FilterListPopover/FilterListPopover'
import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction } from 'react'

export const TypeTitle = ({
  data,
  filterActiveForTypes,
  filteredTypes,
  setFilteredTypes,
}: {
  data: routes.PheromonePurRecDownload.Response['files']
  filterActiveForTypes: boolean
  filteredTypes: { [type: string]: boolean }
  setFilteredTypes: Dispatch<SetStateAction<{ [type: string]: boolean }>>
}) => {
  const typesInData: { [type: string]: boolean } = data.reduce((a: { [type: string]: boolean }, b) => {
    a[b.type] = true

    return a
  }, {})

  return (
    <div css={{ display: 'flex' }}>
      {translate.phrases.banyanApp('Type')}
      {data && !!data.length && (
        <FilterListPopover
          filterIsActive={filterActiveForTypes}
          optionsGroups={[
            {
              options: Object.keys(typesInData)
                .map((type) => ({
                  key: type,
                  title: type,
                  checked: !!filteredTypes[type],
                  onChange: () =>
                    setFilteredTypes({
                      ...filteredTypes,
                      [type]: !filteredTypes[type],
                    }),
                }))
                .sort(sortByKey('title')),
            },
          ]}
          onClear={() => setFilteredTypes({})}
          showSearch={false}
          width={100}
        />
      )}
    </div>
  )
}
