import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'
import { getStyles } from './getStyles'

export const PropertySelect = ({
  clearable = true,
  data,
  disabled = false,
  onChange,
  selectedPropertyId,
  placeholder = translate.phrases.banyanApp('Select a Property'),
}: {
  clearable?: boolean
  data: { value: string; label: string; disabled?: boolean }[]
  disabled?: boolean
  onChange?: (propertyId: number) => void
  selectedPropertyId: number | null
  placeholder?: string
}) => {
  return (
    <Select
      css={{ borderRadius: 3 }}
      clearable={clearable}
      data={data}
      disabled={disabled}
      filter={mantineSelectSearchFilterProp}
      onChange={
        onChange
          ? (propertyId) => {
              onChange(Number(propertyId))
            }
          : undefined
      }
      searchable
      size="sm"
      spellCheck="false"
      placeholder={placeholder}
      styles={getStyles(clearable, disabled)}
      value={String(selectedPropertyId)}
      zIndex={1}
    />
  )
}
