import { getTooltipBody } from './getTooltipBody'

export const getTooltipForActivityIntervals = ({
  currentTimeStamp,
  sensorName,
  appliedWater,
  showPressure,
  pressureValue,
  statusColor,
  statusText,
  statusBorderColor,
  periodDurationText,
  periodFromDateText,
  periodToDateText,
}: {
  currentTimeStamp: string
  sensorName: string
  appliedWater: string
  showPressure: boolean
  pressureValue: string
  statusColor: string
  statusText: string
  statusBorderColor: string
  periodDurationText: string
  periodFromDateText: string
  periodToDateText: string
}) =>
  `<div style="padding-bottom: 4px;">${showPressure ? currentTimeStamp : ''}</div>
   ${getTooltipBody({
     sensorName,
     appliedWater,
     showPressure,
     pressureValue,
     statusColor,
     statusText,
     statusBorderColor,
     periodDurationText,
     periodFromDateText,
     periodToDateText,
   })}
  `
