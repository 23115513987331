import { TIrrigationDeviceStatus } from '@semios/app-platform-value-type-definitions/src/valuesCurrentTypes/irrigation'
import { getIrrigationStatusColors } from 'App/Map/_utils/getIrrigationStatusColors'

export const IrrigationCurrentStatusBadge = ({
  currentValueStatus,
}: {
  currentValueStatus: TIrrigationDeviceStatus
}) => {
  const statusColors = getIrrigationStatusColors(currentValueStatus)

  return (
    <div
      css={{
        border: '2px solid white',
        background: statusColors.backgroundColor,
        borderRadius: '95.2px',
        padding: '4px 10px',
        color: statusColors.color,
      }}
    >
      {currentValueStatus ? currentValueStatus.toUpperCase() : ''}
    </div>
  )
}
