import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import {
  MAP_VISUAL,
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentIrrigationZoneValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from 'stores/mapControlsStore/types'
import { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

type TPossibleValueTypes = TValuesCurrentPointsValueTypes &
  TValuesCurrentBlocksValueTypes &
  TValuesCurrentHeatmapPointsValueTypes &
  TValuesCurrentPropertiesValueTypes

export const getValueType = (
  mapVis: MAP_VISUAL,
  valueGroup: TValueGroup,
  isOutOfBlock = false,
): TValuesCurrentPointsValueTypes &
  TValuesCurrentBlocksValueTypes &
  TValuesCurrentHeatmapPointsValueTypes &
  TValuesCurrentPropertiesValueTypes &
  TValuesCurrentIrrigationZoneValueTypes => {
  const valueType = mapControlsStore.getState().mapVisualValueGroup[mapVis]?.[valueGroup]
    ?.valueType as TPossibleValueTypes

  if (isOutOfBlock) {
    return (
      (mapControlsStore.getState().mapVisualValueGroup[mapVis]?.[valueGroup]
        ?.outOfBlockValueType as TPossibleValueTypes) || valueType
    )
  }

  return valueType
}
