import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconQR = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 18')}>
      <path
        d="M6.5 3.6V6H4.1V3.6H6.5ZM7.7 2.4H2.9V7.2H7.7V2.4ZM6.5 10V12.4H4.1V10H6.5ZM7.7 8.8H2.9V13.6H7.7V8.8ZM12.9 3.6V6H10.5V3.6H12.9ZM14.1 2.4H9.3V7.2H14.1V2.4ZM9.3 8.8H10.5V10H9.3V8.8ZM10.5 10H11.7V11.2H10.5V10ZM11.7 8.8H12.9V10H11.7V8.8ZM9.3 11.2H10.5V12.4H9.3V11.2ZM10.5 12.4H11.7V13.6H10.5V12.4ZM11.7 11.2H12.9V12.4H11.7V11.2ZM12.9 10H14.1V11.2H12.9V10ZM12.9 12.4H14.1V13.6H12.9V12.4ZM16.5 4H14.9V1.6H12.5V0H16.5V4ZM16.5 16V12H14.9V14.4H12.5V16H16.5ZM0.5 16H4.5V14.4H2.1V12H0.5V16ZM0.5 0V4H2.1V1.6H4.5V0H0.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
