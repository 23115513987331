import { PanelDetailsContext } from 'App/Map/PanelDetails/PanelDetails'
import { StackemCharts } from 'App/Map/PanelDetails/StackemCharts/StackemCharts'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'

export const StackemChartsForModal = (props: { containerWidth: number }) => {
  const primaryValueGroup = usePrimaryValueGroup()

  if (!primaryValueGroup) return null

  const containerWidth = props.containerWidth

  return (
    <PanelDetailsContext.Provider value={{ containerWidth }}>
      <div css={{ userSelect: 'none' }}>
        <StackemCharts
          containerWidth={containerWidth}
          preventFetch={false}
          selectedValueGroups={{ [primaryValueGroup]: +new Date() }}
        />
      </div>
    </PanelDetailsContext.Provider>
  )
}
