import { apiFetch } from 'utils/apiFetch'
import { routes } from '@semios/app-platform-banyan-route-definitions'

export const serviceCenterNodeSwapReasonsGet = async () => {
  const swapReasons = await apiFetch<
    routes.ServiceCenterNodeSwapReasonsGet.Request,
    routes.ServiceCenterNodeSwapReasonsGet.Response
  >({
    url: routes.ServiceCenterNodeSwapReasonsGet.path,
    body: null,
    params: {
      method: 'POST',
    },
  })

  return swapReasons
}
