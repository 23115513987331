import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { VIEW_PESTS_TRAP_CATCHES_ID_insectId } from '@semios/app-platform-value-type-definitions'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { GridTableContentPestSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { EAggregationInterval, TFieldAssetValueTypes } from 'App/Map/types'
import { doesSelectedPropertyHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { filter, intersection } from 'lodash'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import { SummaryTableLabel } from './components/SummaryTableLabel'
import { SummaryTableContent } from './components/SummaryTableContent'
import { renderToStaticMarkup } from 'react-dom/server'
import { createElement } from 'react'

type TPoint = TFieldAssetValueTypes.TPoint

type TBlock = TFieldAssetValueTypes.TBlock

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX}${insectId}` as VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets.property) return {}

  const { valuesRequested, insectIds } = getValuesRequestedAndInsectIds(selectedValueGroups)
  const { property: propertyId } = selectedFieldAssets

  const pointsOfProperty = fieldAssetStore.useSelector((s) => {
    return getAssociatedPoints({
      properties: s.properties,
      propertyId: propertyId,
      insectIds,
    })
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  if (
    Object.keys(valuesRequested).length &&
    doesSelectedPropertyHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  )
    valuesToBeRequested.points = {
      lngLats: pointsOfProperty.map(({ lngLat }) => {
        return lngLat
      }),
      valuesRequested,
    }

  return valuesToBeRequested
}

const transpose = (matrix: { timestamp: string; value: number | null }[][]) => {
  return matrix[0]?.map((_, colIndex) => matrix?.map((row) => row[colIndex]))
}

const calTotalSectionHeight = (block: TBlock, points: TPoint[], targetWidth: number) => {
  const summaryTableLabelHtml = renderToStaticMarkup(
    createElement(SummaryTableLabel, { block, associatedPoints: points }),
  )

  const padding = 15
  const border = 1
  const hiddenContainer = document.createElement('div')

  hiddenContainer.className = 'hidden-container-insect-trap-summary-table-label'

  hiddenContainer.style.width = `${targetWidth - padding * 2 - border * 2}px`

  hiddenContainer.innerHTML = summaryTableLabelHtml

  document.body.appendChild(hiddenContainer)

  const offsetHeight = hiddenContainer.offsetHeight

  document.body.removeChild(hiddenContainer)

  return offsetHeight
}

export const content = ({
  data,
  selectedValueGroups,
}: {
  data: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): GridTableContentPestSection[] => {
  const { insects, properties } = fieldAssetStore.getState()
  const selectedProperty = selectedFieldAssetsStore.getState().property
  const pestSectionCategory: TPestSectionCategory = 'trap-catches-property-level'

  if (!insects || !selectedProperty) return []

  const { insectIds } = getValuesRequestedAndInsectIds(selectedValueGroups)

  const pointsOfProperty = getAssociatedPoints({
    properties,
    propertyId: selectedProperty,
    insectIds,
  })

  const blocksOfProperty = getAssociatedBlocks({
    properties,
    propertyId: selectedProperty,
    insectIds,
  })

  const trapCatchesContent: GridTableContentPestSection[] = Object.values(insects)
    .filter((insect) => selectedValueGroups[`trap_catches_insect_id_${insect.insectId}` as TValueGroup])
    .map(({ insectId }) => {
      const valueTypeKey = getValueTypeIdForInsectId(insectId)

      const hasPermission = selectedPropertyHasPermission({
        permission: VIEW_PESTS_TRAP_CATCHES_ID_insectId(insectId),
      })

      const commonReturnItems = {
        title: unitConverter.insectTrapCatchesPropertyLevel(null, { insectId }).titleWithoutUnit(),
        titleChildren: <DropdownSelectorProperty valuesTimeseriesToFilterOn={[valueTypeKey]} />,
        id: `summary-grid-insect-trap-catches-insect-id-${insectId}-${selectedProperty}`,
        pestSectionCategory,
        insectId,
      }

      if (!hasPermission) {
        return { ...propertyLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      const trapCatchRows = data?.points ? data.points : {}

      const associatedBlocks = blocksOfProperty.filter((block) =>
        block.valuesTimeseries.includes(`insectTrapCatches_${insectId}`),
      )

      const items = associatedBlocks
        .map((block) => {
          const associatedPoints = pointsOfProperty.filter(
            (point) =>
              point.blockIds.includes(block.blockId) &&
              (point.configuration.trapInsectIds || []).includes(insectId),
          )

          const associatedValues = associatedPoints.sort(sortByKey('name')).map((point) => {
            const value = trapCatchRows[point.lngLat]?.values[valueTypeKey]

            if (!value) {
              return []
            }

            const { timeseries } = value[0]

            return timeseries.map((dataPoint) => {
              return {
                timestamp: dataPoint.timestamp,
                value: dataPoint.value,
                name: point.name,
              }
            })
          })

          const transposedValues = transpose(associatedValues)

          const finalValues = transposedValues?.reduce((result, currentValue) => {
            return {
              ...result,
              [currentValue[0].timestamp]: currentValue,
            }
          }, {})

          const targetWidth = 160
          const adjustmentWidth = 4

          return {
            id: `${block.name} ${block.blockId}`,
            label: <SummaryTableLabel block={block} associatedPoints={associatedPoints} />,
            height: calTotalSectionHeight(block, associatedPoints, targetWidth),
            labelMinWidth: targetWidth + adjustmentWidth,
            valueMinWidth: targetWidth,
            render: (dataPoints: { timestamp: string; value: number | null; name: string }[] | undefined) => {
              if (!dataPoints) {
                return <></>
              }

              return <SummaryTableContent dataPoints={dataPoints} block={block} />
            },
            values: finalValues,
          }
        })
        .sort(sortByKey('id')) as GridTableContentSectionItem[]

      return {
        ...commonReturnItems,
        items,
      }
    })
    .sort(sortByKey('title'))

  return trapCatchesContent
}

const getValuesRequestedAndInsectIds = (
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups'],
): {
  valuesRequested: Partial<
    Record<
      VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  >
  insectIds: number[]
} => {
  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  let insectIds: number[] = []

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('trap_catches_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (!selectedPropertyHasPermission({ permission: VIEW_PESTS_TRAP_CATCHES_ID_insectId(insectId) }))
        return

      insectIds.push(insectId)

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  return { valuesRequested, insectIds }
}

const getAssociatedPoints = ({
  properties,
  propertyId,
  insectIds,
}: {
  properties: Record<number, routes.UserAppStartup.TFieldAssetValueTypes.TProperty> | undefined
  propertyId: number | null
  insectIds: number[]
}): TPoint[] => {
  const property = propertyId && properties?.[propertyId]
  const points = property ? Object.values(property['points'] || {}) : []

  return filter<TPoint>(
    points,
    (point) => intersection(point.configuration.trapInsectIds, insectIds).length > 0,
  )
}

const getAssociatedBlocks = ({
  properties,
  propertyId,
  insectIds,
}: {
  properties: Record<number, routes.UserAppStartup.TFieldAssetValueTypes.TProperty> | undefined
  propertyId: number | null
  insectIds: number[]
}): TBlock[] => {
  const property = propertyId && properties?.[propertyId]
  const blocks = property ? Object.values(property['blocks'] || {}) : []

  return filter<TBlock>(
    blocks,
    (block) =>
      intersection(
        block.valuesTimeseries,
        insectIds.map((insectId) => `insectTrapCatches_${insectId}`),
      ).length > 0,
  )
}
