import { TChartSeries } from 'components/StackedChart/types'
import { TooltipOptions } from 'highcharts'
import { EAggregationInterval } from 'App/Map/types'

export const environmentalLineChartSeriesMaker = (series: {
  aggregationInterval?: string | null
  color?: string
  name: string
  tooltip?: TooltipOptions
  values: {
    x: number | string
    y: number | null
    high?: number | null
    low?: number | null
    isDeviceReported?: boolean | null
  }[]
}): TChartSeries[] => {
  const { aggregationInterval, color, name, tooltip, values } = series
  const isDaily = aggregationInterval === EAggregationInterval.DAILY
  const lineSeriesId = `${aggregationInterval}-${name}`

  const lineSeries: TChartSeries = {
    color,
    name,
    tooltip,
    yAxis: 0,
    type: 'line',
    // @ts-ignore
    data: values.map(({ x, low, high, y, isDeviceReported }) => ({
      x: +new Date(x),
      low: isDaily ? low : undefined,
      high: isDaily ? high : undefined,
      y,
      isDeviceReported,
    })),
    id: lineSeriesId,
  }

  if (!isDaily) {
    return [lineSeries]
  }

  const arearangeSeries: TChartSeries = {
    // @ts-ignore
    color,
    name: `${name} hidden low/high`,
    tooltip,
    yAxis: 0,
    type: 'arearange',
    // @ts-ignore
    data: values.map(({ x, low, high }) => ({ x: +new Date(x), low, high })),
    linkedTo: lineSeriesId,
    opacity: 0.3,
    zIndex: 0,
    id: `${lineSeriesId}-hidden-arearange`,
    hideFromTooltip: true,
  }

  return [lineSeries, arearangeSeries] as TChartSeries[]
}
