import { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { getTimezoneForSelectedProperty } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedProperty'
import { StackedChartSection, TChartSeries, TValueTypeObject } from 'components/StackedChart/types'
import { isEmpty } from 'lodash'
import {
  selectedFieldAssetsStore,
  TPointCategory,
  TSelectedFieldAssetsStoreState,
} from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { generateSeriesForEnvironmentalChart } from '../_utils/generateSeriesForEnvironmentalChart/generateSeriesForEnvironmentalChart'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { plotOptionsToHideInactiveSeriesHarderMaker } from '../_utils/plotOptionsToHideInactiveSeriesHarderMaker'
import { lineChartTooltipFormatter } from 'components/StackedChart/_utils/lineChartTooltipFormatter/lineChartTooltipFormatter'
import { updateTooltipForValueSource } from '../_utils/tooltipOptionsForValueSourceCharts'
import { EAggregationInterval, TFieldAssetKeyTypes } from 'App/Map/types'
import { VV } from '@semios/app-platform-value-type-definitions'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import moment, { Moment } from 'moment-timezone'

const pointCategory: TPointCategory = 'weatherPoint'
const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WEATHER_DETAILS' })

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'humidity_ABOVE',
  'humidity_IN',
  'humidity_BELOW',
]

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.humidity || !selectedFieldAssets[pointCategory] || !selectedFieldAssets.property)
    return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily(),
  }

  const valuesForPoint =
    fieldAssetStore.getState().properties?.[selectedFieldAssets.property]?.points?.[
      selectedFieldAssets[pointCategory]
    ]?.valuesTimeseries || []

  const valuesToRequest = possibleValueTypesToRequest.filter((v) => valuesForPoint.includes(v))

  const valuesRequested = valuesToRequest.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.humidityInCanopy().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'stackem-humidity',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])
  const stationValues = data?.points?.[stationLngLat]?.values
  const hasAboveCanopySensor = !isEmpty(stationValues?.humidity_ABOVE?.[0]?.timeseries)
  const hasInCanopySensor = !isEmpty(stationValues?.humidity_IN?.[0]?.timeseries)
  const hasBelowCanopySensor = !isEmpty(stationValues?.humidity_BELOW?.[0]?.timeseries)
  const series: TChartSeries[] = []

  const isVirtual =
    fieldAssetStore.getState().properties?.[Number(selectedFieldAssetsStore.getState().property)]?.points?.[
      stationLngLat as TFieldAssetKeyTypes.TLngLat
    ]?.isVirtual

  const isDaily =
    stationValues?.humidity_ABOVE?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY ||
    stationValues?.humidity_BELOW?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY ||
    stationValues?.humidity_IN?.[0]?.metadata?.aggregationInterval === EAggregationInterval.DAILY

  const aboveCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasAboveCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.humidity_ABOVE?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsData?.points?.[stationLngLat]?.values
      ?.humidity_ABOVE?.[0] as TValueTypeObject,
    unitConverter: unitConverter.humidityAboveCanopy,
  })

  const inCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasInCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.humidity_IN?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsData?.points?.[stationLngLat]?.values
      ?.humidity_IN?.[0] as TValueTypeObject,
    unitConverter: unitConverter.humidityInCanopy,
  })

  const belowCanopySeries = generateSeriesForEnvironmentalChart({
    hasSensor: hasBelowCanopySensor,
    valueTypeObject: data.points?.[stationLngLat]?.values?.humidity_BELOW?.[0] as TValueTypeObject,
    compareSeasonsValueTypeObject: compareSeasonsData?.points?.[stationLngLat]?.values
      ?.humidity_BELOW?.[0] as TValueTypeObject,
    unitConverter: unitConverter.humidityBelowCanopy,
  })

  series.push(...aboveCanopySeries, ...inCanopySeries, ...belowCanopySeries)

  const timezone = getTimezoneForSelectedProperty()

  const allForecastMoments: Moment[] = [
    stationValues?.humidity_ABOVE?.[0]?.metadata.forecastStartsAt,
    stationValues?.humidity_IN?.[0]?.metadata.forecastStartsAt,
    stationValues?.humidity_BELOW?.[0]?.metadata.forecastStartsAt,
  ]
    .filter(Boolean)
    .map((d) => moment.tz(d, timezone))

  const firstForecastTimestamp = allForecastMoments.length ? +moment.min(allForecastMoments) : +new Date()

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          plotOptions: plotOptionsToHideInactiveSeriesHarderMaker(),
          series,
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
            formatter: function (tooltip) {
              let tooltipContents = lineChartTooltipFormatter(this, tooltip, firstForecastTimestamp)

              tooltipContents = updateTooltipForValueSource({
                seriesIds: [aboveCanopySeries[0]?.id, inCanopySeries[0]?.id, belowCanopySeries[0]?.id].filter(
                  Boolean,
                ),
                compareSeasonIds: [
                  aboveCanopySeries[1]?.id,
                  inCanopySeries[1]?.id,
                  belowCanopySeries[1]?.id,
                ].filter(Boolean),
                points: this.points,
                tooltipContents,
                firstForecastTimestamp,
                isVirtual,
              })

              return tooltipContents
            },
          },
        },
      },
    ],
  }
}
