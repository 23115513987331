import { colors, TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { defaultMapVisualValueGroup, TMapVisualValueGroup } from './mapVisualValueGroup'
import { MAP_VISUAL } from './types'

const storeName = 'mapControlsStore'

export const generateDefaultHeatmapHighLowColors = () => [
  colors.highLowHeatmapStrongBlue,
  colors.highLowHeatmapMediumBlue,
  colors.highLowHeatmapMediumYellow,
  colors.highLowHeatmapMediumOrange,
  colors.highLowHeatmapStrongRed,
]

export const generateDefaultHeatmapDangerColors = () => [
  colors.dangerHeatmapSoftYellow,
  colors.dangerHeatmapStrongYellow,
  colors.dangerHeatmapSoftOrange,
  colors.dangerHeatmapStrongOrange,
  colors.dangerHeatmapSoftRed,
  colors.dangerHeatmapStrongRed,
]

export const mapControlsStore = persistentInit<{
  manualExtremes: boolean
  heatmapColoring: TRGBAColorWith1AtTheEnd[]
  heatmapExtremes: {
    min: number
    max: number
  }
  loadingCurrentValues: boolean
  mapVisuals: Record<MAP_VISUAL, boolean>
  mapVisualValueGroup: TMapVisualValueGroup
  showBlockPolygons: boolean
  showCurrentValuesOnSwagworld: boolean
  showMapBadgeNames: boolean
  useAggregation: boolean
  keyForRefreshingMap: string | null
}>({
  initialState: {
    manualExtremes: false,
    heatmapColoring: generateDefaultHeatmapHighLowColors(),
    heatmapExtremes: { min: 5, max: 30 },
    loadingCurrentValues: false,
    mapVisuals: {
      [MAP_VISUAL.BLOCK]: true,
      [MAP_VISUAL.PROPERTY]: true,
      [MAP_VISUAL.POINT]: true,
      [MAP_VISUAL.SCD]: true,
      [MAP_VISUAL.IRRIGATION_ZONE]: true,
      [MAP_VISUAL.TRAP]: true,
    },
    mapVisualValueGroup: defaultMapVisualValueGroup,
    showBlockPolygons: false,
    showCurrentValuesOnSwagworld: true,
    showMapBadgeNames: false,
    useAggregation: true,
    keyForRefreshingMap: null,
  },
  keysToPutInPersistentStorage: {
    heatmapColoring: false,
    heatmapExtremes: true,
    loadingCurrentValues: false,
    manualExtremes: true,
    mapVisuals: false,
    mapVisualValueGroup: true,
    showBlockPolygons: true,
    showCurrentValuesOnSwagworld: true,
    showMapBadgeNames: true,
    useAggregation: true,
    keyForRefreshingMap: false,
  },
  keysToPutInURL: {
    heatmapColoring: false,
    heatmapExtremes: true,
    loadingCurrentValues: false,
    manualExtremes: true,
    mapVisuals: false,
    mapVisualValueGroup: false,
    showBlockPolygons: false,
    showCurrentValuesOnSwagworld: false,
    showMapBadgeNames: false,
    useAggregation: true,
    keyForRefreshingMap: false,
  },
  storeName,
})

// doing this will allow our heatmap to scale dynamically when a new valueType is on the map
selectedValueGroupsStore.subscribeToChanges(
  (s) => [s.primaryValueGroup],
  () => {
    mapControlsStore.setState((s) => ({ ...s, manualExtremes: false }))
  },
)
