import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconNFC = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 18')}>
      <path
        fill="currentColor"
        d="M8.5 0C4.084 0 0.5 3.584 0.5 8C0.5 12.416 4.084 16 8.5 16C12.916 16 16.5 12.416 16.5 8C16.5 3.584 12.916 0 8.5 0ZM5.668 9.96L4.58 9.464C4.804 8.976 4.908 8.472 4.9 7.976C4.892 7.472 4.788 6.984 4.58 6.536L5.668 6.032C5.948 6.632 6.092 7.28 6.1 7.952C6.108 8.64 5.964 9.312 5.668 9.96ZM8.124 11.208L7.084 10.616C7.5 9.88 7.708 9.032 7.708 8.096C7.708 7.144 7.492 6.232 7.068 5.376L8.14 4.84C8.652 5.864 8.908 6.96 8.908 8.096C8.908 9.24 8.644 10.288 8.124 11.208ZM10.636 12.264L9.556 11.736C10.18 10.456 10.5 9.192 10.5 7.984C10.5 6.776 10.18 5.528 9.556 4.272L10.628 3.736C11.348 5.16 11.7 6.584 11.7 7.984C11.7 9.376 11.348 10.816 10.636 12.264Z"
      />
    </svg>
  )
}
