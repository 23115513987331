import { calculateTextWidth } from 'utils/calculateTextWidth'

const getCellBottomPadding = (labelWidth: number) => {
  if (labelWidth > 215) return '2px'

  if (labelWidth > 120 && labelWidth <= 215) return '11px'

  return '19px'
}

export const IrrigationActivityCell = ({
  label,
  applied,
  scheduled,
}: {
  label: string
  applied: string | null
  scheduled: string | null
}) => {
  const marginLeft = '6px'
  const labelWidth = calculateTextWidth({ fontSize: '14px', fontWeight: 'bold', text: label })

  const commonStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }

  return (
    <div
      css={{
        flexDirection: 'column',
        padding: `0 12px ${getCellBottomPadding(labelWidth)} 0`,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div />
      <div>
        <div
          css={{
            padding: '0px 12px',
            ...commonStyles,
          }}
        >
          <div css={{ marginLeft }}>{applied}</div>
        </div>
        <div
          css={{
            padding: '0px 12px',
            marginTop: '4px',
            ...commonStyles,
          }}
        >
          <div css={{ marginLeft }}>{scheduled}</div>
        </div>
      </div>
    </div>
  )
}
