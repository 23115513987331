import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { OfflineRequestError } from 'utils/errorCodes'
import { NodeSwapMeta, serviceCenterNodeSwap } from './api/serviceCenterNodeSwap'

export async function swapNode(param: NodeSwapMeta) {
  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.SWAP_NODE, param)

    if (status === ApiRequestStatus.COMPLETED) return
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      throw error
    }
  }

  serviceCenterNodeSwap.callback(param)
}
