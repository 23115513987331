import { Input } from '@mantine/core'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { NumberInput } from 'components/NumberInput/NumberInput'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { userDetailsStore } from 'stores/userDetailsStore'
import { TModalDrawerStep, TNewPresetState } from '../Presets'
import { RequiredMarker } from '../RequiredMarker/RequiredMarker'
import { modalDrawerTitleMaker } from '../_utils/modalDrawerTitleMaker'
import { newPresetInitialStateMaker } from '../_utils/newPresetInitialStateMaker'
import { useModalStepFormState } from '../_utils/useModalStepFormState'
import { submitEditedPreset } from './submitEditedPreset'
import { submitNewPreset } from './submitNewPreset'

export const ModalDrawerStep3 = ({
  newPresetState,
  opened,
  setModalDrawerOpen,
  setModalDrawerStep,
  setNewPresetState,
  step,
}: {
  newPresetState: TNewPresetState
  opened: boolean
  setModalDrawerOpen: Dispatch<SetStateAction<boolean>>
  setModalDrawerStep: Dispatch<SetStateAction<TModalDrawerStep>>
  setNewPresetState: Dispatch<SetStateAction<TNewPresetState>>
  step: TModalDrawerStep
}) => {
  const nameRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (step === 3) {
      setTimeout(() => nameRef?.current?.focus(), 250)
    }
  }, [step])

  const isEditing = !!newPresetState?.editingIdHash

  const existingPresets = userDetailsStore.useSelector((s) => {
    return Object.values({ ...s.availableSemiosDefinedPresets, ...s.availableUserDefinedPresets })
  })

  const dateFromIsValid = !isNil(newPresetState?.dayOffsetDateFrom)
  const dateToIsValid = !isNil(newPresetState?.dayOffsetDateTo)

  const nameIsInvalid = useMemo(() => {
    const cleanedName = (newPresetState?.name ?? '').trim()

    if (!cleanedName || isNil(cleanedName)) {
      return translate.phrases.validation('{{label}} is required', {
        label: translate.phrases.banyanApp('View Name'),
      })
    }

    const minCharacters = 3

    if (cleanedName.length <= minCharacters) {
      return translate.phrases.validation('{{label}} must be more than {{count}} characters', {
        label: translate.phrases.banyanApp('View Name'),
        count: minCharacters,
      })
    }

    if (!newPresetState.editingIdHash && existingPresets.some((p) => p.name === cleanedName)) {
      return translate.phrases.banyanApp('A view named {{name}} already exists', { name: cleanedName })
    }

    return false
  }, [newPresetState?.name, existingPresets])

  const { errorColor, onClose, primaryButtonDisabled, primaryButtonOnPress, showErrors } =
    useModalStepFormState({
      newPresetState,
      opened,
      validator: () => dateFromIsValid && dateToIsValid && !nameIsInvalid,
      onSuccess: async () => {
        setModalDrawerStep(1 as TModalDrawerStep)

        setModalDrawerOpen(false)

        const functionToUse = isEditing ? submitEditedPreset : submitNewPreset

        functionToUse({ newPresetState }).finally(() => {
          setNewPresetState(newPresetInitialStateMaker)
        })
      },
      setModalDrawerOpen,
      setModalDrawerStep,
      setNewPresetState,
    })

  return (
    <ModalDrawer
      title={modalDrawerTitleMaker({ isEditing, step })}
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonText={translate.phrases.banyanApp('Save View')}
      primaryButtonOnPress={primaryButtonOnPress}
      opened={opened}
      onClose={onClose}
      secondaryButtonText={translate.phrases.banyanApp('Back: Step {{step}}', { step: String(step - 1) })}
      secondaryButtonOnPress={() => setModalDrawerStep((step - 1) as TModalDrawerStep)}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 300}
    >
      <div css={{ padding: 30, display: 'flex', flexDirection: 'column' }}>
        <h3>{translate.phrases.banyanApp('Select the default date range')}</h3>
        <p>
          {translate.phrases.banyanApp('Selected date range will be displayed on summary and detail view.')}
        </p>
        <h4>
          {isNil(newPresetState.dayOffsetDateFrom) && <RequiredMarker />}
          {translate.phrases.banyanApp('Days in the past')}
        </h4>
        <NumberInput
          styles={{ input: { borderColor: showErrors && !dateFromIsValid ? errorColor : 'unset' } }}
          css={{ maxWidth: 300 }}
          max={380}
          min={0}
          onChange={(newFromOffset) =>
            setNewPresetState((s) => ({
              ...s,
              dayOffsetDateFrom: newFromOffset === '' ? undefined : newFromOffset,
            }))
          }
          value={newPresetState.dayOffsetDateFrom}
        />
        {showErrors && !dateFromIsValid && (
          <p css={{ color: errorColor }}>
            {translate.phrases.banyanApp('Select a default number of days in the past')}
          </p>
        )}
        <h4>
          {isNil(newPresetState.dayOffsetDateTo) && <RequiredMarker />}
          {translate.phrases.banyanApp('Days in the future')}
        </h4>
        <NumberInput
          styles={{ input: { borderColor: showErrors && !dateToIsValid ? errorColor : 'unset' } }}
          css={{ maxWidth: 300 }}
          max={14}
          min={0}
          onChange={(newToOffset) =>
            setNewPresetState((s) => ({
              ...s,
              dayOffsetDateTo: newToOffset === '' ? undefined : newToOffset,
            }))
          }
          value={newPresetState.dayOffsetDateTo}
        />
        {showErrors && !dateToIsValid && (
          <p css={{ color: errorColor }}>
            {translate.phrases.banyanApp('Select a default number of days in the future')}
          </p>
        )}
        <h3 css={{ marginTop: 48 }}>
          <RequiredMarker />
          {translate.phrases.banyanApp('Give the view a name')}
        </h3>
        <p>
          {translate.phrases.banyanApp(
            'Help identify the view. This is also the name that will be displayed under Current View in the main menu.',
          )}
        </p>
        <Input
          css={{ maxWidth: 300, borderColor: !nameIsInvalid ? errorColor : 'unset' }}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewPresetState((s) => ({ ...s, name: e.target.value }))
          }
          maxLength={40}
          ref={nameRef}
          styles={{ input: { borderColor: showErrors && nameIsInvalid ? errorColor : 'unset' } }}
          value={newPresetState.name ?? ''}
        />
        {showErrors && nameIsInvalid && <p css={{ color: errorColor }}>{nameIsInvalid}</p>}
      </div>
    </ModalDrawer>
  )
}
