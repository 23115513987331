import { Select } from '@mantine/core'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { TModalDrawerStep, TNewPresetState } from '../Presets'
import { RequiredMarker } from '../RequiredMarker/RequiredMarker'
import { modalDrawerTitleMaker } from '../_utils/modalDrawerTitleMaker'
import { useModalStepFormState } from '../_utils/useModalStepFormState'
import { useOptionsToRenderOut } from './useOptionsToRenderOut'

export const ModalDrawerStep2 = ({
  newPresetState,
  opened,
  setModalDrawerOpen,
  setModalDrawerStep,
  setNewPresetState,
  step,
}: {
  newPresetState: TNewPresetState
  opened: boolean
  setModalDrawerOpen: Dispatch<SetStateAction<boolean>>
  setModalDrawerStep: Dispatch<SetStateAction<TModalDrawerStep>>
  setNewPresetState: Dispatch<SetStateAction<TNewPresetState>>
  step: TModalDrawerStep
}) => {
  const { errorColor, onClose, primaryButtonDisabled, primaryButtonOnPress, showErrors } =
    useModalStepFormState({
      newPresetState,
      opened,
      validator: (s) => !!s?.valueGroupForMap,
      onSuccess: () => setModalDrawerStep((step + 1) as TModalDrawerStep),
      setModalDrawerOpen,
      setModalDrawerStep,
      setNewPresetState,
    })

  /**
   * because this state is dependent on the selectedValueGroups,
   * we should reset it when they change if the valueGroupForMap
   * isn't a member of the array
   */
  useEffect(() => {
    if (!(newPresetState.selectedValueGroups ?? []).includes(String(newPresetState?.valueGroupForMap))) {
      setNewPresetState((s) => ({ ...s, valueGroupForMap: undefined }))
    }
  }, [newPresetState.selectedValueGroups])

  const optionsToRenderOut = useOptionsToRenderOut()

  return (
    <ModalDrawer
      title={modalDrawerTitleMaker({ isEditing: !!newPresetState?.editingIdHash, step })}
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonText={translate.phrases.banyanApp('Next: Step {{step}}', { step: String(step + 1) })}
      primaryButtonOnPress={primaryButtonOnPress}
      opened={opened}
      onClose={onClose}
      secondaryButtonText={translate.phrases.banyanApp('Back: Step {{step}}', { step: String(step - 1) })}
      secondaryButtonOnPress={() => setModalDrawerStep((step - 1) as TModalDrawerStep)}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 300}
    >
      <div css={{ padding: 30, display: 'flex', flexDirection: 'column' }}>
        <h3>
          <RequiredMarker />
          {translate.phrases.banyanApp(
            'Select what will be displayed on the map by default when this view gets selected',
          )}
        </h3>
        {/* TODO: this should be fed by value groups when we have a centralized place available */}
        <Select
          data={optionsToRenderOut
            .map((o) => ({ label: o.label, value: o.value, group: o.section }))
            .filter((vg) => newPresetState?.selectedValueGroups?.includes(vg?.value))}
          onChange={(newValueGroupForMap) =>
            setNewPresetState((s) => ({ ...s, valueGroupForMap: newValueGroupForMap as string }))
          }
          placeholder={translate.phrases.banyanApp('Select a value')}
          spellCheck="false"
          styles={{
            ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
            input: {
              ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input,
              borderColor: showErrors ? errorColor : 'unset',
            },
          }}
          value={newPresetState?.valueGroupForMap}
        />
        {showErrors && (
          <p css={{ color: errorColor }}>{translate.phrases.banyanApp('Select a layer for the map')}</p>
        )}
      </div>
    </ModalDrawer>
  )
}
