import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import {
  TActiveGateway,
  TActiveNode,
  TDevice,
  THelpGuides,
  TInstallationChecklist,
  TNodeLog,
  TNodeServiceIssueSummary,
  TNodeStatus,
  TPlannedGateway,
  TPlannedNode,
} from '../types'
import { actions } from './actions/serviceCenter'
import { selectors } from './selectors/serviceCenter'
import { routes } from '@semios/app-platform-banyan-route-definitions'

const storeName = 'serviceCenterStore'

export type TEquipmentType = 'trap' | 'gateway' | 'dispenser' | 'repeater' | 'station' | 'external'

export type TNodeDeviceType = 'frost-fan' | 'weather' | 'leaf-wetness' | 'water-flow' | 'dendrometer' | 'soil'

export type NodeSwapReasons = routes.ServiceCenterNodeSwapReasonsGet.Response

export type NodeRemoveReasons = routes.ServiceCenterNodeRemoveReasonsGet.Response

export type TServiceCenterStore = {
  mapEquipmentStatuses: TNodeStatus[]
  mapEquipmentTypes: TEquipmentType[]
  mapEquipmentDevices: TNodeDeviceType[]
  devices: Record<string, TDevice>
  helpGuides: {
    nodes: Record<string, THelpGuides>
    devices: Record<string, THelpGuides>
  }
  installationChecklists: {
    nodes: Record<string, TInstallationChecklist>
    devices: Record<string, TInstallationChecklist>
  }
  plannedNodes: Array<TPlannedNode | TPlannedGateway>
  activeNodes: Array<TActiveNode | TActiveGateway>
  nodeStatuses: Record<string, TNodeStatus>
  nodeLogs: Record<string, TNodeLog>
  mostRecentLogTime: string | null
  selectedPlannedEquipmentIds: string[]
  selectedActiveEquipmentIds: string[]
  serviceIssues: Record<string, TNodeServiceIssueSummary[]>
  swapReasons: NodeSwapReasons
  removeReasons: NodeRemoveReasons
  offlineContents: {
    isBannerOpened: boolean
    plannedNodes: Array<TPlannedNode | TPlannedGateway>
    activeNodes: Array<TActiveNode | TActiveGateway>
    nodeLogs: Record<string, TNodeLog>
    serviceIssues: Record<string, TNodeServiceIssueSummary[]>
    lastTimeDownloaded: string | null
    downloadStatus: 'default' | 'success' | 'error' | string
  }
}

const store = persistentInit<TServiceCenterStore>({
  initialState: {
    mapEquipmentStatuses: [],
    mapEquipmentTypes: [],
    mapEquipmentDevices: [],
    devices: {},
    helpGuides: {
      nodes: {},
      devices: {},
    },
    installationChecklists: {
      nodes: {},
      devices: {},
    },
    plannedNodes: [],
    activeNodes: [],
    nodeStatuses: {},
    nodeLogs: {},
    mostRecentLogTime: null,
    selectedPlannedEquipmentIds: [],
    selectedActiveEquipmentIds: [],
    serviceIssues: {},
    swapReasons: {},
    removeReasons: {},
    offlineContents: {
      isBannerOpened: false,
      plannedNodes: [],
      activeNodes: [],
      nodeLogs: {},
      serviceIssues: {},
      lastTimeDownloaded: null,
      downloadStatus: 'default',
    },
  },
  keysToPutInPersistentStorage: {
    mapEquipmentStatuses: true,
    mapEquipmentTypes: true,
    mapEquipmentDevices: true,
    devices: true,
    helpGuides: true,
    installationChecklists: true,
    plannedNodes: true,
    activeNodes: true,
    nodeStatuses: true,
    nodeLogs: true,
    mostRecentLogTime: true,
    selectedPlannedEquipmentIds: false,
    selectedActiveEquipmentIds: false,
    serviceIssues: true,
    swapReasons: true,
    removeReasons: true,
    offlineContents: true,
  },
  keysToPutInURL: {
    mapEquipmentStatuses: true,
    mapEquipmentTypes: true,
    mapEquipmentDevices: true,
    devices: false,
    helpGuides: false,
    installationChecklists: false,
    plannedNodes: false,
    activeNodes: false,
    nodeStatuses: false,
    nodeLogs: false,
    mostRecentLogTime: false,
    selectedPlannedEquipmentIds: true,
    selectedActiveEquipmentIds: true,
    serviceIssues: false,
    swapReasons: false,
    removeReasons: false,
    offlineContents: false,
  },
  storeName,
})

export const serviceCenterStore = {
  ...store,
  actions,
  selectors,
}
