import { TColumn, TDataElement } from 'components/SummaryTable/SummaryTable.types'
import { get, isEqual } from 'lodash'
import { memo } from 'react'

const Cell = ({ col, row }: { col: TColumn; row: TDataElement }): JSX.Element => {
  const renderedCell: React.ReactNode = col.render
    ? col.render(get(row, col.dataIndex), row)
    : (get(row, col.dataIndex) as React.ReactNode)

  return (
    <td
      css={{
        '&:first-of-type': {
          fontWeight: 'bold',
          paddingLeft: '14px !important',
        },
        'textAlign': col.align || 'left',
        'minWidth': col.width || undefined,
        'padding': '11px 8px',
      }}
    >
      {renderedCell}
    </td>
  )
}

const MemoizedCell = memo(Cell, isEqual) as typeof Cell

export { MemoizedCell as Cell }
