import { colors } from 'settings/colors'

export const InProgressEventWave = ({ progressPercent }: { progressPercent: number }) => {
  const backgroundColor: string = colors.royalBlue
  const waveHeight = `${150 - progressPercent}%`
  const waveWidth = `${150 - progressPercent}%`

  return (
    <div
      css={{
        'position': 'relative',
        'width': '100%',
        'height': '100%',
        'background': backgroundColor,
        'borderRadius': '95.2',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          width: waveWidth,
          height: waveHeight,
          top: 0,
          left: '50%',
          transform: 'translate(-50%,-50%)',
          background: backgroundColor,
        },
        '&::before': {
          borderRadius: '45%',
          background: colors.green,
          animation: 'animate 5s linear infinite',
        },
        '&::after': {
          borderRadius: '40%',
          background: colors.idealBadge,
          animation: 'animate 11s linear infinite',
        },
      }}
    ></div>
  )
}
