import { routes } from '@semios/app-platform-banyan-route-definitions'
import { BlockScdsValuesCache } from 'App/Map/CurrentValuesMap/caches/BlockScdsValuesCache/BlockScdsValuesCache'
import { BlocksDefaultPolygonsCache } from 'App/Map/CurrentValuesMap/caches/BlocksDefaultPolygonsCache/BlocksDefaultPolygonsCache'
import { BlockValuesCache } from 'App/Map/CurrentValuesMap/caches/BlockValuesCache/BlockValuesCache'
import { PropertyValuesCache } from 'App/Map/CurrentValuesMap/caches/PropertyValuesCache/PropertyValuesCache'
import { StationValuesCache } from 'App/Map/CurrentValuesMap/caches/StationValuesCache/StationValuesCache'
import { getMapOverlaysPadding } from 'App/Map/CurrentValuesMap/_utils/getMapOverlaysPadding'
import { translate } from 'i18n/i18n'
import polylabel from 'polylabel'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapStore } from 'stores/mapStore'
import { lngLatStringToLngLatObject } from 'utils/lngLatStringToLngLatObject'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'
import { IrrigationZonesPolygonsCache } from '../CurrentValuesMap/caches/IrrigationZonesPolygonsCache/IrrigationZonesPolygonsCache'
import { IrrigationZoneValuesCache } from '../CurrentValuesMap/caches/IrrigationZoneValuesCache/IrrigationZoneValuesCache'
import { TrapValuesCache } from '../CurrentValuesMap/caches/TrapValuesCache/TrapValuesCache'
import { getIdForLngLat } from '../CurrentValuesMap/_utils/getIdForLngLat'
import { TFieldAssetKeyTypes, TFieldAssetType } from '../types'

export const populateMapFromUserAppStartup = (o: {
  maps: typeof google.maps
  map: google.maps.Map
  blocksDefaultPolygonsCache: BlocksDefaultPolygonsCache
  blockScdsValuesCache: BlockScdsValuesCache
  blockValuesCache: BlockValuesCache
  properties: routes.UserAppStartup.Response['properties']
  propertyValuesCache: PropertyValuesCache
  stationValuesCache: StationValuesCache
  trapValuesCache: TrapValuesCache
  irrigationZonePolygonsCache: IrrigationZonesPolygonsCache
  irrigationZoneValuesCache: IrrigationZoneValuesCache
}) => {
  // TODO: Note: all this should be doing is prepping the cache with location stuff: latLngs, bounds, etc.
  const allPropertyBounds = new o.maps.LatLngBounds()

  Object.entries(o.properties ?? {}).forEach(([propertyId, property]) => {
    const propertyBounds = new o.maps.LatLngBounds()

    if (property.blocks) {
      Object.values(property.blocks).forEach((block) => {
        const { blockId } = block
        const blockIdString = String(blockId)
        const { coordinates } = JSON.parse(block.geometry) as { coordinates: Array<Array<number[]>> }
        const blockBounds = new o.maps.LatLngBounds()

        const blockLatLngs = coordinates[0].map(([lng, lat]) => {
          blockBounds.extend({ lat, lng })

          return new o.maps.LatLng({ lat, lng })
        })

        const [lng, lat] = polylabel([coordinates[0]], 1.0)

        o.blockValuesCache.addItem({
          id: blockIdString,
          value: {},
          meta: {
            blockId: Number(blockId),
            blockName: block.name ?? translate.phrases.banyanApp('Unnamed Block'),
            bounds: blockBounds,
            latLng: new o.maps.LatLng({ lng, lat }),
            latLngs: blockLatLngs,
            propertyId: Number(propertyId),
          },
        })

        if (hubConnectorSettings.enableBlockPolygons) {
          o.blocksDefaultPolygonsCache.addItem({
            id: blockIdString,
            value: {},
            meta: {
              blockId,
              bounds: blockBounds,
              latLngs: blockLatLngs,
            },
          })
        }

        o.blockScdsValuesCache.addItem({
          id: blockId.toString(),
          meta: {
            bounds: blockBounds,
            blockId: blockIdString,
            scds: [],
          },
          value: {},
        })

        blockLatLngs.forEach((latLng) => {
          propertyBounds.extend(latLng)

          allPropertyBounds.extend(latLng)
        })
      })
    }

    if (property.points) {
      Object.values(property.points).forEach((point) => {
        const { lngLat } = point
        const { lng, lat } = lngLatStringToLngLatObject(lngLat)
        const isTrap = Boolean(point.configuration.trapInsectIds)

        if (isTrap) {
          const fieldAssetType: TFieldAssetType = 'traps'

          o.trapValuesCache.addItem({
            id: getIdForLngLat({ fieldAssetType, lngLat: lngLat }),
            meta: {
              type: fieldAssetType,
              insectId: point.configuration.trapInsectIds?.[0],
              propertyId: propertyId.toString(),
              blockId: point.blockIds?.[0],
              lngLat: lngLat,
              name: point.name ?? translate.phrases.banyanApp('Unnamed Trap'),
              latLng: new o.maps.LatLng({ lng, lat }),
            },
            value: {},
          })
        } else {
          o.stationValuesCache.addItem({
            id: getIdForLngLat({ fieldAssetType: 'points', lngLat: lngLat }),
            value: {},
            meta: {
              lngLat: lngLat,
              name: point.name ?? translate.phrases.banyanApp('Unnamed Station'), // TODO station name should maybe have crop name
              propertyId: property.propertyId,
              isOutOfBlock: point.valuesCurrent.some((vt) => vt.includes('_out_')),
              latLng: new o.maps.LatLng({ lng, lat }),
            },
          })
        }
      })
    }

    if (property.irrigationZoneEmitters) {
      Object.entries(property.irrigationZoneEmitters).forEach(([zoneIdEmitterTypeKey, irrigationZone]) => {
        const { coordinates } = JSON.parse(irrigationZone.geometry) as {
          coordinates: Array<Array<number[][]>>
        }

        const irrigationZoneBounds = new o.maps.LatLngBounds()

        const irrigationZoneLatLngs = coordinates[0][0].map(([lng, lat]) => {
          irrigationZoneBounds.extend({ lat, lng })

          return new o.maps.LatLng({ lat, lng })
        })

        const [lng, lat] = polylabel([coordinates[0][0]], 1.0)

        o.irrigationZoneValuesCache.addItem({
          id: zoneIdEmitterTypeKey,
          value: {},
          meta: {
            irrigationZoneEmitterId: irrigationZone.irrigationZoneEmitterId,
            irrigationZoneEmitterType: irrigationZone.emitterType as TFieldAssetKeyTypes.TEmitterType,
            irrigationZoneName: irrigationZone.name ?? translate.phrases.banyanApp('Unnamed Zone'),
            irrigationZoneId: irrigationZone.irrigationZoneId,
            propertyId: property.propertyId,
            bounds: irrigationZoneBounds,
            latLng: new o.maps.LatLng({ lng, lat }),
            latLngs: irrigationZoneLatLngs,
            onClick: () =>
              setSelectedFieldAsset({
                irrigationZoneEmitter: irrigationZone.irrigationZoneEmitterId,
              }),
          },
        })

        if (hubConnectorSettings.enableIrrigationZonePolygons) {
          o.irrigationZonePolygonsCache.addItem({
            id: zoneIdEmitterTypeKey,
            value: {},
            meta: {
              irrigationZoneEmitterId: irrigationZone.irrigationZoneEmitterId,
              irrigationZoneEmitterType: irrigationZone.emitterType as TFieldAssetKeyTypes.TEmitterType,
              irrigationZoneId: irrigationZone.irrigationZoneId,
              propertyId: property.propertyId,
              bounds: irrigationZoneBounds,
              latLngs: irrigationZoneLatLngs,
              onClick: () =>
                setSelectedFieldAsset({
                  irrigationZoneEmitter: irrigationZone.irrigationZoneEmitterId,
                }),
            },
          })
        }
      })
    }

    if (hubConnectorSettings.enablePropertyValues) {
      o.propertyValuesCache.addItem({
        id: propertyId.toString(),
        value: {},
        meta: {
          propertyId: propertyId.toString(),
          propertyName: property.propertyName,
          bounds: propertyBounds,
        },
      })
    }
  })

  // Have the map fit all the user's properties!
  if (!HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD && !mapStore.getState().bounds) {
    o.map.fitBounds(allPropertyBounds, getMapOverlaysPadding())
  }
}
