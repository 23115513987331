import { routes, SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import {
  getValueTypeFromEmitterType,
  IRRIGATION_PRESSURE_VALUE_TYPE_PREFIX,
} from '@semios/app-platform-common'
import { VV } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorIrrigationZone } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorIrrigationZone/DropdownSelectorIrrigationZone'
import {
  getIdAndEmitterTypeFromZoneEmitterTypeKey,
  makeDeviceNameAndEmitterTypeLabel,
} from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { TooltipFormatterContextObject } from 'highcharts'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { getPointLngLatsForIrrigationEmitterZoneId } from 'utils/getPointLngLatsForIrrigationEmitterZoneId'
import { getWaterVolumeStringWithUnits } from 'utils/getWaterVolumeStringWithUnits'
import { minutesToHoursAndMinutes } from 'utils/minutesToHoursAndMinutes'
import { sortByKey } from 'utils/sortByKey'
import { getVolumeUnit } from '../../../../../../_utils/getVolumeUnit'
import { convertPressureFromPSIToKPa } from '../../_utils/convertPressureFromPSIToKPa'
import { getAppliedWater } from '../../_utils/getAppliedWater'
import { getIntervalForCurrentStamp, TPossibleResponseType } from '../../_utils/getIntervalForCurrentStamp'
import { getTooltipForActivityIntervals } from '../../_utils/getTooltipForActivityIntervals'
import {
  EnumIrrigationActivityStatus,
  getIrrigationActivityChartSettings,
} from '../../_utils/irrigationActivityChartSettings'
import { IrrigationChartLegend } from '../../_utils/IrrigationChartLegend'
import { getActivityIntervalsFromData } from './getActivityIntervalsFromData'

const pressureSeries = 'pressure'

type TSplineSeriesData = { x: number; y?: number }

interface TXRangeSeriesType {
  color: string
  key: number
  status: string | null
  x: number
  x2: number
  y: number
}

type TShapedDataForChart = {
  deviceName: string
  onDurationMinutes: number
  pressureData: TSplineSeriesData[]
  intervalsForDevice: TPossibleResponseType
  intervalsForChart: TXRangeSeriesType[]
  totalWaterApplied: number
}

export const getChartDataWithPressureAndActivity = ({
  activityIntervals,
  property,
  timezone,
  selectedFieldAssets,
  data,
  showPressure,
  title,
}: {
  activityIntervals: ReturnType<typeof getActivityIntervalsFromData>
  property: number | null
  timezone: string
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  showPressure: boolean
  title: string
}) => {
  const { dateFrom, dateTo } = detailsPanelStore.getState()
  const blankSeries: TSplineSeriesData[] = [] // an invisible series so that we get a tooltip even when no data

  let stamp = +new Date(dateFrom)

  while (stamp <= +new Date(dateTo)) {
    blankSeries.push({ x: stamp, y: 10 })

    stamp = stamp + 60 * 60 * 1000
  }

  const propertyData = fieldAssetStore.getState()?.properties?.[property as number]
  const points = propertyData?.points || {}
  const waterDepthUnit = propertyData?.propertySettings?.waterDepthUnit
  const { rain: rainUnitFromUserSetting, pressure: pressureUnitFromUserSetting } = userDetailsStore.getState()
  const volumeUnitToDisplay = getVolumeUnit({ waterDepthUnit, rainUnitFromUserSetting })

  let emitterType: SharedTypes.TEmitterType | null = null

  if (selectedFieldAssets.irrigationZoneEmitter)
    emitterType = getIdAndEmitterTypeFromZoneEmitterTypeKey(
      selectedFieldAssets.irrigationZoneEmitter,
    ).emitterType

  const lngLatsForZone =
    selectedFieldAssets?.property && selectedFieldAssets?.irrigationZoneEmitter
      ? getPointLngLatsForIrrigationEmitterZoneId({
          propertyId: selectedFieldAssets.property,
          irrigationEmitterZoneId: selectedFieldAssets.irrigationZoneEmitter,
        })
      : []

  const valueType = getValueTypeFromEmitterType(
    IRRIGATION_PRESSURE_VALUE_TYPE_PREFIX,
    emitterType as SharedTypes.TEmitterType,
  ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysMergedForByPointIrrigation

  const pressureDataLngLatMap = lngLatsForZone.reduce<
    Record<
      string,
      NonNullable<NonNullable<VV.Response['points']>[string]['values']['irrigationPressureSemios_pump']>
    >
  >((acc, lngLat) => {
    const pressureDataForPoint = data?.points?.[lngLat]?.values?.[valueType]

    if (pressureDataForPoint) {
      acc[lngLat] = (acc[lngLat] || []).concat(pressureDataForPoint as typeof acc[string])
    }

    return acc
  }, {})

  const isPressureMetric = pressureUnitFromUserSetting === 'METRIC'
  const showPressureInMetric = showPressure && isPressureMetric
  const irrigationActivitySettings = getIrrigationActivityChartSettings({ pressureUnitFromUserSetting })

  const shapedDataForCharts = Object.entries(activityIntervals || {})
    .reduce((acc: TShapedDataForChart[], [lngLat, intervals]) => {
      intervals.forEach((interval) => {
        const matchingPT = Object.values(points).find(
          (d) =>
            d.lngLat === lngLat &&
            emitterType &&
            d.configuration.irrigationEmitterTypesAvailable?.includes(emitterType),
        )

        if (!matchingPT) return

        const deviceName = matchingPT.name

        let onDurationMinutes = 0

        const intervalsForChart: TXRangeSeriesType[] = (interval.timeseries ?? []).map((s, index) => {
          if (s.status === 'on') {
            const periodMinutes = moment.tz(s.endTime, timezone).diff(s.startTime, 'minutes')

            onDurationMinutes += periodMinutes
          }

          return {
            key: index,
            x: +new Date(s.startTime),
            x2: s.endTime ? +new Date(s.endTime) : +new Date(),
            y: showPressureInMetric ? 300 : 50,
            color: irrigationActivitySettings[(s.status || 'noData') as EnumIrrigationActivityStatus].color,
            status: s.status,
            pointWidth: s.status === 'on' ? 24 : 14,
          }
        })

        const totalWaterApplied = onDurationMinutes
          ? +((onDurationMinutes / 60) * interval.metadata.flowRate)
          : 0

        const shapedData: TShapedDataForChart = {
          deviceName,
          onDurationMinutes,
          intervalsForDevice: interval,
          intervalsForChart,
          totalWaterApplied,
          pressureData: showPressure
            ? (pressureDataLngLatMap[lngLat] ?? []).flatMap((p) =>
                p?.timeseries
                  ? (p.timeseries ?? []).map((d) => {
                      const average = d.value?.average

                      const value = average
                        ? convertPressureFromPSIToKPa({
                            desiredUnitIMPERIALxorMETRIC: pressureUnitFromUserSetting,
                            psiAmount: average,
                          })
                        : average

                      return { x: +new Date(d.timestamp), y: isNil(average) ? undefined : Number(value) }
                    })
                  : [],
              )
            : [],
        }

        acc.push(shapedData)
      })

      return acc
    }, [])
    .sort(sortByKey('deviceName'))

  return {
    title,
    titleChildren: [
      <DropdownSelectorIrrigationZone key={'irrigationZonesDropdown'} isPressureSensor={true} />,
    ],
    id: 'stackem-pressure-sensors-chart',
    items: shapedDataForCharts.map((chartRow, i) => {
      const pressureSeriesId = `${pressureSeries}-${i}`

      const {
        totalWaterApplied,
        intervalsForChart,
        deviceName,
        pressureData,
        onDurationMinutes,
        intervalsForDevice,
      } = chartRow

      const allChartSeries = [
        {
          color: 'rgba(0, 0, 0, 0)',
          type: 'spline',
          name: 'invisible',
          showInLegend: false,
          data: blankSeries,
          enableMouseTracking: true,
          id: 'blank',
          marker: {
            enabled: false,
          },
        },
        {
          type: 'xrange',
          name: translate.phrases.banyanApp('Irrigation Activity'),
          showInLegend: false,
          minPointLength: 3,
          data: intervalsForChart,
          enableMouseTracking: true,
          id: 'irrigationIntervals',
        },
      ]

      if (pressureData.length > 0)
        allChartSeries.push({
          color: irrigationActivitySettings.psi.color,
          type: 'spline',
          name: translate.phrases.banyanApp('Pressure'),
          showInLegend: false,
          data: pressureData,
          enableMouseTracking: true,
          id: pressureSeriesId,
          marker: {
            enabled: false,
          },
        })

      const maxTickForYAxis = showPressureInMetric ? 360 : 60
      const tickIntervalForYAxis = showPressureInMetric ? 90 : 20

      return {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: `Pressure Sensors - ${deviceName}`,
            firstForecastTimestamp: +new Date(),
          },
          chart: {
            type: 'spline',
            height: 200,
          },
          tooltip: {
            xDateFormat: '%Z',
            formatter: function (this: TooltipFormatterContextObject) {
              const timestamp = this.x

              const { start, end, status } = getIntervalForCurrentStamp({
                currentTimeStamp: timestamp as number,
                activityIntervals: intervalsForDevice,
                timezone,
              })

              if (!start) return false

              const {
                color: statusColor,
                text: statusText,
                border: statusBorderColor,
              } = irrigationActivitySettings[(status || 'noData') as EnumIrrigationActivityStatus]

              if (!start) return false

              const intervalData: {
                startTime: string
                endTime: string | null
                status: string
              } = {
                startTime: start.toISOString(),
                endTime: end?.toISOString() || null,
                status,
              }

              const pressureRow = this?.points?.find(
                (pData) => pData?.series.userOptions?.id === pressureSeriesId,
              )

              const toolTipParams = {
                currentTimeStamp: translate.dates.format(
                  moment.tz(timestamp, timezone),
                  'ddd, MMM D, YYYY h:mm a (z)',
                ),
                sensorName: makeDeviceNameAndEmitterTypeLabel({
                  deviceName,
                  emitterType,
                }),
                appliedWater: getAppliedWater({
                  flowRatePerHour: intervalsForDevice.metadata.flowRate,
                  flowUnitPerHour: intervalsForDevice.metadata.flowUnitPerHour,
                  intervalData,
                  timezone,
                  volumeUnitToDisplay,
                }),
                showPressure,
                pressureValue:
                  status !== EnumIrrigationActivityStatus.noData && !isNil(pressureRow?.y)
                    ? `${pressureRow?.y} ${irrigationActivitySettings.psi.text}`
                    : translate.phrases.templates('-'),
                statusColor,
                statusText,
                statusBorderColor: statusBorderColor || statusColor,
                periodDurationText: end
                  ? minutesToHoursAndMinutes(moment.tz(end, timezone).diff(start, 'minutes'))
                  : translate.phrases.templates('-'),
                periodFromDateText: translate.dates.format(start, 'ddd, MMM D, YYYY h:mm a (z)'),
                periodToDateText: end
                  ? translate.dates.format(end, 'ddd, MMM D, YYYY h:mm a (z)')
                  : translate.phrases.templates('-'),
              }

              return getTooltipForActivityIntervals(toolTipParams)
            },
          },
          yAxis: {
            min: 0,
            max: maxTickForYAxis,
            tickInterval: tickIntervalForYAxis,
            visible: showPressure,
            labels: {
              style: {
                fontSize: '14px',
              },
            },
          },
          plotOptions: {
            series: {
              groupPadding: 0,
              pointPadding: 0,
              states: {
                // don't fade out these series when hovering over the chart
                inactive: {
                  opacity: 1,
                },
              },
            },
          },
          series: allChartSeries,
        },

        childrenLower: (
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <div css={{ flex: 1 }}>
              <IrrigationChartLegend
                showPressure={showPressure}
                pressureUnitFromUserSetting={pressureUnitFromUserSetting}
              />
            </div>
            <div
              css={{
                textAlign: 'center',
                fontSize: '14px',
                color: colors.grey900,
                margin: '10px 10px 15px',
                display: 'flex',
              }}
            >
              <div>
                {translate.phrases.templates('{{label}}: {{value}}', {
                  label: translate.phrases.banyanApp('On Duration'),
                  value: minutesToHoursAndMinutes(onDurationMinutes),
                })}
              </div>
              <div css={{ padding: '0 10px' }}>{'|'}</div>
              <div>
                {translate.phrases.templates('{{label}}: {{value}}', {
                  label: translate.phrases.banyanApp('Total Volume'),
                  value: getWaterVolumeStringWithUnits({
                    waterApplied: totalWaterApplied,
                    volumeUnitToDisplay,
                    flowUnitPerHour: intervalsForDevice.metadata.flowUnitPerHour,
                  }),
                })}
              </div>
            </div>
          </div>
        ),
        childrenUpper: <div css={{ fontWeight: 'bold', padding: '20px 30px 0 30px' }}>{deviceName}</div>,
      }
    }),
  }
}
