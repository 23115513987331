const CONVERSION_RATE_FOR_MM_AND_INCHES = 25.4

const convertInchesToMM = (waterAppliedInInches: number) =>
  waterAppliedInInches * CONVERSION_RATE_FOR_MM_AND_INCHES

const convertMMToInches = (waterAppliedInMM: number) => waterAppliedInMM / CONVERSION_RATE_FOR_MM_AND_INCHES

export const getConvertedWaterVolume = ({
  flowUnitPerHour,
  volumeUnitToDisplay,
  waterApplied,
  decimalPlacesToRound = 2,
}: {
  waterApplied: number
  volumeUnitToDisplay: 'INCH' | 'MILLIMETER'
  flowUnitPerHour: string
  decimalPlacesToRound?: number
}): number | null => {
  const currentUnitOfWaterAppliedIsInches = flowUnitPerHour === 'INCH'

  switch (volumeUnitToDisplay) {
    case 'MILLIMETER': {
      const waterAppliedInMM = currentUnitOfWaterAppliedIsInches
        ? convertInchesToMM(waterApplied)
        : waterApplied

      return Number(waterAppliedInMM.toFixed(decimalPlacesToRound))
    }

    case 'INCH': {
      const waterAppliedInInches = !currentUnitOfWaterAppliedIsInches
        ? convertMMToInches(waterApplied)
        : waterApplied

      return Number(waterAppliedInInches.toFixed(decimalPlacesToRound))
    }

    default: {
      throw new Error('Only Inch and Millimeter are implemented')
    }
  }
}
