import { includeAggregateSwitch } from 'App/Map/MapControls/MapControlItems/MapControlItems'
import { isNil } from 'lodash'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import { doesPointHaveValueType } from 'utils/doesFieldAssetHaveValueType'
import { BlockScdsValuesCache } from '../caches/BlockScdsValuesCache/BlockScdsValuesCache'
import { BlocksDefaultPolygonsCache } from '../caches/BlocksDefaultPolygonsCache/BlocksDefaultPolygonsCache'
import { BlockValuesCache } from '../caches/BlockValuesCache/BlockValuesCache'
import { IrrigationZonesPolygonsCache } from '../caches/IrrigationZonesPolygonsCache/IrrigationZonesPolygonsCache'
import { IrrigationZoneValuesCache } from '../caches/IrrigationZoneValuesCache/IrrigationZoneValuesCache'
import { PropertyValuesCache } from '../caches/PropertyValuesCache/PropertyValuesCache'
import { StationValuesCache } from '../caches/StationValuesCache/StationValuesCache'
import { TrapValuesCache } from '../caches/TrapValuesCache/TrapValuesCache'
import { TCurrentValuesMapCacheKeys } from './by-domain/_types'
import { getCacheUpdatesFromResponse } from './getCacheUpdatesFromResponse'
import { getZoomThresholds, ZOOM_VISIBILITY } from './zoomVisibility'

export const updateMapWithAnyNeededCurrentValues = async ({
  propertyValuesCache,
  blockValuesCache,
  stationValuesCache,
  blockScdsValuesCache,
  blocksDefaultPolygonsCache,
  trapValuesCache,
  irrigationZonePolygonsCache,
  irrigationZoneValuesCache,
}: {
  blocksDefaultPolygonsCache: BlocksDefaultPolygonsCache
  blockScdsValuesCache: BlockScdsValuesCache
  blockValuesCache: BlockValuesCache
  propertyValuesCache: PropertyValuesCache
  stationValuesCache: StationValuesCache
  trapValuesCache: TrapValuesCache
  irrigationZonePolygonsCache: IrrigationZonesPolygonsCache
  irrigationZoneValuesCache: IrrigationZoneValuesCache
}) => {
  const primaryValueGroup = getPrimaryValueGroup()
  const { mapVisualValueGroup, mapVisuals, useAggregation, showBlockPolygons } = mapControlsStore.getState()

  if (isNil(primaryValueGroup)) return

  const cacheKeys: TCurrentValuesMapCacheKeys = {
    blockCacheKey: 'STATIC',
    blockValuesCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.BLOCK]?.[primaryValueGroup]?.valueType
    }`,
    propertyCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.PROPERTY]?.[primaryValueGroup]?.valueType
    }`,
    scdCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.SCD]?.[primaryValueGroup]?.valueType
    }`,
    stationCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.POINT]?.[primaryValueGroup]?.valueType
    }`,
    trapsCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.POINT]?.[primaryValueGroup]?.valueType
    }`,
    irrigationZoneCacheKey: 'IRRIGATION_ZONE_STATIC',
    irrigationZoneValuesCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.IRRIGATION_ZONE]?.[primaryValueGroup]?.valueType
    }`,
  }

  const blockPolygons = blocksDefaultPolygonsCache.getItemsToProcess({
    cacheKey: cacheKeys.blockCacheKey,
    shouldShowItem: (() => {
      if (!showBlockPolygons) return () => false

      const currentBounds = blocksDefaultPolygonsCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const propertyValues = propertyValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.propertyCacheKey,
    shouldShowItem: (() => {
      const currentZoom = propertyValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).PROPERTY !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = propertyValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const blockValues = blockValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.blockValuesCacheKey,
    shouldShowItem: (() => {
      if (includeAggregateSwitch[primaryValueGroup] && !useAggregation) return () => false

      if (!mapVisuals.BLOCK || !mapVisualValueGroup[MAP_VISUAL.BLOCK]?.[primaryValueGroup]) return () => false

      const currentZoom = blockValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).BLOCK !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = blockValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const stationValues = stationValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.stationCacheKey,
    shouldShowItem: (() => {
      const currentZoom = stationValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).POINT !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = stationValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => {
        const isInBounds = currentBounds.contains(o.meta.latLng)
        const mapVisual = MAP_VISUAL.POINT

        const expectedValueTypeForThisStation =
          mapVisuals[mapVisual] && mapVisualValueGroup[mapVisual]?.[primaryValueGroup]

        if (isInBounds && expectedValueTypeForThisStation && expectedValueTypeForThisStation.valueType) {
          let valueType = expectedValueTypeForThisStation.valueType

          if (primaryValueGroup === 'soil') {
            // TODO SG this is hacky and not awesome but...
            valueType = 'soilAWC_eachDepth_pct'
          }

          if (o.meta.isOutOfBlock) {
            valueType = expectedValueTypeForThisStation.outOfBlockValueType ?? valueType
          }

          const doesStationHaveThisValueType = doesPointHaveValueType({
            propertyId: o.meta.propertyId,
            lngLat: o.meta.lngLat,
            currentValueToCheck: valueType,
          })

          return doesStationHaveThisValueType
        }

        return false
      }
    })(),
  })

  const blockScdsValues = blockScdsValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.scdCacheKey,
    shouldShowItem: (() => {
      if (includeAggregateSwitch[primaryValueGroup] && useAggregation) return () => false

      if (!mapVisuals.SCD || !mapVisualValueGroup[MAP_VISUAL.SCD]?.[primaryValueGroup]) return () => false

      const currentZoom = blockScdsValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).SCD !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = blockScdsValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const trapsValues = trapValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.trapsCacheKey,
    shouldShowItem: (() => {
      if (includeAggregateSwitch[primaryValueGroup] && useAggregation) return () => false

      if (!mapVisuals.TRAP || !mapVisualValueGroup[MAP_VISUAL.TRAP]?.[primaryValueGroup]) return () => false

      const currentZoom = trapValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).TRAP !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = trapValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) =>
        currentBounds.contains(o.meta.latLng) &&
        !!o.meta.insectId &&
        o.meta.insectId === Number(primaryValueGroup.replace('trap_catches_insect_id_', ''))
    })(),
  })

  const irrigationZonePolygons = irrigationZonePolygonsCache.getItemsToProcess({
    cacheKey: cacheKeys.irrigationZoneCacheKey,
    shouldShowItem: (() => {
      const currentBounds = irrigationZonePolygonsCache.map.getBounds()

      if (!currentBounds || showBlockPolygons) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const irrigationZoneValues = irrigationZoneValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.irrigationZoneValuesCacheKey,
    shouldShowItem: (() => {
      if (
        !mapVisuals.IRRIGATION_ZONE ||
        !mapVisualValueGroup[MAP_VISUAL.IRRIGATION_ZONE]?.[primaryValueGroup]
      )
        return () => false

      const currentZoom = irrigationZoneValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).IRRIGATION_ZONE !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = irrigationZoneValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const processedCaches = {
    blockPolygons,
    blockScdsValues,
    blockValues,
    propertyValues,
    stationValues,
    trapsValues,
    irrigationZonePolygons,
    irrigationZoneValues,
  }

  const processed = await getCacheUpdatesFromResponse({ cacheKeys, processedCaches })

  if (showBlockPolygons) {
    blocksDefaultPolygonsCache.process({
      itemsWithinViewThatNowHaveValues: [],
      cacheKey: cacheKeys.blockCacheKey,
      itemIdsWithinView: blockPolygons.itemIdsWithinView,
    })
  } else {
    irrigationZonePolygonsCache.process({
      itemsWithinViewThatNowHaveValues: [],
      cacheKey: cacheKeys.irrigationZoneCacheKey,
      itemIdsWithinView: irrigationZonePolygons.itemIdsWithinView,
    })

    if (processed.properties) propertyValuesCache.process(processed.properties)

    if (processed.irrigationZones) {
      irrigationZoneValuesCache.process(processed.irrigationZones)
    }

    if (processed.stations) stationValuesCache.process(processed.stations)
  }

  if (processed.properties) propertyValuesCache.process(processed.properties)

  if (processed.blocks) blockValuesCache.process(processed.blocks)

  if (processed.stations) stationValuesCache.process(processed.stations)

  if (processed.heatmapPoints) blockScdsValuesCache.process(processed.heatmapPoints)

  if (processed.traps) trapValuesCache.process(processed.traps)
}
