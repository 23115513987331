import React, { PropsWithChildren, useState } from 'react'
import { closeModal, openModal } from '@mantine/modals'
import { ErrorBoundary } from '@sentry/react'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import { serviceCenterStore } from '../store/serviceCenterStore'
import { TActiveNode } from '../types'
import { getNodeTypeLabel } from '../utils/getNodeTypeLabel'
import { Input, Modal, Select, TextInput } from '@mantine/core'
import { Footer } from '../HelpGuideModal/Footer/Footer'
import { swapNode } from '../utils/swapNode'
import { useForm } from '@mantine/form'
import { Button } from 'components/Button/Button'
import { ImageUpload } from '../ImageUpload/ImageUpload'
import { GalleryPhoto, Photo } from '@capacitor/camera'
import { notifications } from '@mantine/notifications'
import { uploadInstallationImage } from '../utils/uploadInstallationImage'
import { NfcScanner } from 'components/NfcScanner/NfcScanner'
import { QrScanner } from 'components/QrCodeScanner/QrCodeScanner'
import { IconQR } from 'components/icons/IconQR'
import { IconNFC } from 'components/icons/IconNFC'
import { useScannerSupportCheck } from '../utils/hooks/useScannerSupportCheck'
import { IconCameraFill } from 'components/icons/IconCameraFill'
import { ErrorMessage } from '../Shared/ErrorMessage/ErrorMessage'
import { validateNodeIdentifier } from '../utils/validateNodeIdentifier'
import { checkAuthorization } from 'utils/checkAuthorization'
import { SharedSettings } from 'settings/SharedSettings'

const MODAL_ID = 'node-swap-modal'

export function openNodeSwapModal(onClose?: () => void) {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <NodeSwapModal
          onClose={() => {
            onClose && onClose()

            closeModal(MODAL_ID)
          }}
        />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

const NodeSwapModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [isModalOpenedImage, setIsModalOpenedImage] = useState(false)
  const [image, setImage] = useState<Photo | GalleryPhoto | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const { NFC, QR, scanError, handleScan } = useScannerSupportCheck()

  const selectedNode = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNode,
  ) as TActiveNode

  const swapReasons = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSwapReasonsBySelectedNode,
  )

  const form = useForm({
    initialValues: {
      swapReason: '',
      newName: selectedNode?.name || '',
      nodeIdentifierToSwapIn: '',
      reasonDescription: '',
    },
    validate: {
      swapReason: (value) => {
        if (!value) return translate.phrases.placeholder('Required')
      },
      nodeIdentifierToSwapIn: (value) => {
        if (!value) return translate.phrases.placeholder('Required')

        if (!validateNodeIdentifier(selectedNode.nodeType, value.trim().toUpperCase())) {
          return translate.phrases.placeholder(
            'The node identifier that you have entered is invalid. Please try again.',
          )
        }
      },
      reasonDescription: (value) => {
        if (form.values.swapReason === 'OTHER' && !value)
          return translate.phrases.placeholder('Please provide a reason description when selecting "Other"')
      },
    },
    transformValues(values) {
      return {
        ...values,
        nodeIdentifierToSwapIn: values.nodeIdentifierToSwapIn.trim().toUpperCase(),
      }
    },
  })

  const handleSubmit = async () => {
    const { hasErrors } = form.validate()

    if (hasErrors) return

    setIsSaving(true)

    const { newName, nodeIdentifierToSwapIn, swapReason, reasonDescription } = form.values

    try {
      const payload = {
        nodeIdentifierToRemove: selectedNode?.nodeIdentifier,
        nodeIdentifierToInstall: nodeIdentifierToSwapIn,
        reason: swapReason,
        newName,
        reasonDescription: reasonDescription || undefined,
        propertyId: selectedNode?.propertyId,
      }

      await swapNode(payload)

      if (image) {
        await uploadInstallationImage(nodeIdentifierToSwapIn, image, 'EQUIP_SWAP_IN')
      }

      notifications.show({
        title: translate.phrases.placeholder('Swap Node'),
        message: translate.phrases.placeholder('Node has been swapped successfully'),
      })

      serviceCenterStore.actions.selectEquipment(nodeIdentifierToSwapIn, 'active')

      onClose()
    } catch (e) {
      notifications.show({
        title: translate.phrases.placeholder('Error'),
        message: (e as Error).message,
        color: 'red',
      })
    }

    setIsSaving(false)
  }

  const openImageModal = () => {
    setIsModalOpenedImage(true)
  }

  const onSuccessfulScan = (value: string) => {
    form.setFieldValue('nodeIdentifierToSwapIn', value.toUpperCase())
  }

  const IconWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return (
      <span
        css={{
          fontSize: '20px',
          display: 'inline-block',
          marginLeft: '4px',
          position: 'relative',
          top: '4px',
        }}
      >
        {children}
      </span>
    )
  }

  return (
    <>
      {isModalOpenedImage && (
        <Modal zIndex={300} onClose={() => setIsModalOpenedImage(false)} opened={isModalOpenedImage}>
          <ImageUpload image={image} setImage={setImage} />
        </Modal>
      )}
      <WideHeader onClose={onClose} title={translate.phrases.placeholder('Swap Equipment')} />

      <div css={{ padding: '16px' }}>
        <strong css={{ fontSize: '20px' }}>{selectedNode?.name}</strong>
        <div css={{ marginTop: '4px', fontSize: 14 }}>{selectedNode.nodeIdentifier}</div>
        <div css={{ marginTop: '4px', fontSize: 14 }}>{getNodeTypeLabel(selectedNode?.nodeType)}</div>

        <Input.Wrapper
          id="newName"
          label={translate.phrases.placeholder('Equipment Name')}
          css={{ marginTop: 20 }}
        >
          <TextInput
            placeholder={translate.phrases.placeholder('Insert New Equipment Name')}
            css={{ margin: '10px auto', display: 'block' }}
            {...form.getInputProps('newName')}
          />
        </Input.Wrapper>
        <Input.Wrapper
          id="nodeIdentifier"
          label={translate.phrases.placeholder('Node Identifier')}
          css={{ marginTop: 20 }}
        >
          <TextInput
            placeholder={translate.phrases.placeholder('Type or Scan ID')}
            css={{ margin: '10px auto', display: 'block' }}
            {...form.getInputProps('nodeIdentifierToSwapIn')}
            value={form.values.nodeIdentifierToSwapIn.toUpperCase()}
          />
        </Input.Wrapper>
        {!!scanError && <ErrorMessage style={{ marginBottom: '8px' }} errorMessage={scanError} />}
        <div css={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {QR.supported && (
            <>
              {QR.active && (
                <QrScanner
                  onScan={(prop: { value?: string; error?: string }) => {
                    handleScan('QR', prop, onSuccessfulScan)
                  }}
                />
              )}
              <Button
                variant="tertiary"
                css={{ display: 'block', width: '48%', height: '36px' }}
                onClick={() => {
                  QR.setActive(true)
                }}
              >
                <span>{translate.phrases.placeholder('QR')}</span>
                <IconWrapper>
                  <IconQR />
                </IconWrapper>
              </Button>
            </>
          )}
          {NFC.supported && (
            <>
              {NFC.active && (
                <NfcScanner
                  onScan={(prop: { value?: string; error?: string }) => {
                    handleScan('NFC', prop, onSuccessfulScan)
                  }}
                ></NfcScanner>
              )}
              <Button
                variant="tertiary"
                css={{ display: 'block', width: '48%', height: '36px' }}
                onClick={() => {
                  NFC.setActive(true)
                }}
              >
                <span>{translate.phrases.placeholder('NFC')}</span>
                <IconWrapper>
                  <IconNFC />
                </IconWrapper>
              </Button>
            </>
          )}
        </div>

        <Input.Wrapper id="reason" label={translate.phrases.placeholder('Reason')} css={{ marginTop: 20 }}>
          <Select
            data={swapReasons.map((item) => ({ label: item.swapReason, value: item.swapId }))}
            {...form.getInputProps('swapReason')}
            styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
            placeholder={translate.phrases.placeholder('Select Reason for Swap')}
          />
        </Input.Wrapper>

        {form.values.swapReason === 'OTHER' && (
          <Input.Wrapper
            id="reasonDescription"
            label={translate.phrases.placeholder('Description')}
            css={{ marginTop: 20 }}
          >
            <TextInput
              css={{ margin: '10px auto', display: 'block' }}
              {...form.getInputProps('reasonDescription')}
            />
          </Input.Wrapper>
        )}
        {checkAuthorization({ permission: 'CREATE_SSC_NODE_IMAGE', entity: selectedNode.propertyId }) && (
          <Button
            onClick={openImageModal}
            variant="tertiary"
            css={{
              width: '100%',
              height: '40px',
              marginTop: '16px',
            }}
          >
            <span>{translate.phrases.placeholder(image ? 'Change Image' : 'Add Image')}</span>
            <IconWrapper>
              <IconCameraFill />
            </IconWrapper>
          </Button>
        )}
      </div>
      <Footer
        onPrevious={onClose}
        onNext={handleSubmit}
        nextButtonLabel={translate.phrases.placeholder('Submit')}
        disableNextButton={isSaving}
      />
    </>
  )
}

export default NodeSwapModal
