import { Radio, Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { SharedSettings } from 'settings/SharedSettings'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import {
  MAP_VISUAL,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from 'stores/mapControlsStore/types'
import { smallStore, TAppliedIrrigationActivityDisplayOption } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { useScreenSize } from 'utils/useScreenSize'
import { useIrrigationSelectData } from './useIrrigationSelectData'

const updateMapVisuals = (newValueType: TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        irrigation_activity: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].irrigation_activity ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        irrigation_activity: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY].irrigation_activity ?? { mItem: 'M_Property' }),
          valueType: newValueType.replace('Sum_', 'SumMinMedianMax_') as TValuesCurrentPropertiesValueTypes,
        },
      },
      [MAP_VISUAL.IRRIGATION_ZONE]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.IRRIGATION_ZONE],
        irrigation_activity: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.IRRIGATION_ZONE]?.irrigation_activity ?? {
            mItem: 'M_IrrigationZone',
          }),
          valueType: newValueType,
        },
      },
    },
  }))
}

const VOLUME: TAppliedIrrigationActivityDisplayOption = 'VOLUME'
const DURATION: TAppliedIrrigationActivityDisplayOption = 'DURATION'

export const IrrigationActivitySecondaryDropdown = () => {
  const { isWideScreen } = useScreenSize()
  const appliedIrrigationActivityDisplay = smallStore.useSelector((s) => s.appliedIrrigationActivityDisplay)

  const selectedValueType = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.irrigation_activity?.valueType,
  )

  const precipitationUnit = userDetailsStore.useSelector((s) => s?.rain)
  const data = useIrrigationSelectData()

  const radioStyles = {
    label: {
      color: HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD ? colors.midnight : colors.white,
      cursor: 'pointer',
    },
    radio: { cursor: 'pointer' },
  }

  const showAppliedIrrigationOptions =
    selectedValueType && !selectedValueType?.endsWith('_status') && !selectedValueType?.endsWith('_interval')

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <Select
        data={data}
        filter={(value, item) => {
          const fullStringToSearch = `${item?.label ?? ''}${item?.group ?? ''}`

          return searchStringFromStringWithSpaces(value, fullStringToSearch)
        }}
        onChange={updateMapVisuals}
        styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
        searchable={isWideScreen}
        value={selectedValueType}
        withinPortal
      />
      {showAppliedIrrigationOptions && (
        <Radio.Group
          css={{ marginTop: 12, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '0px 12px' }}
          onChange={(newVal: TAppliedIrrigationActivityDisplayOption) =>
            smallStore.setState((s) => ({ ...s, appliedIrrigationActivityDisplay: newVal }))
          }
          value={appliedIrrigationActivityDisplay}
        >
          <Radio
            label={translate.phrases.templates('{{label}} ({{unitSymbol}})', {
              label: translate.phrases.banyanApp('Duration'),
              unitSymbol: translate.phrases.banyanApp('Hours'),
            })}
            styles={radioStyles}
            value={DURATION}
          />
          <Radio
            label={translate.phrases.templates('{{label}} ({{unitSymbol}})', {
              label: translate.phrases.banyanApp('Volume'),
              unitSymbol: unitConverter.precipitation().suffix(),
            })}
            styles={radioStyles}
            value={VOLUME}
            key={precipitationUnit} // lets use this useSelector value to force a rerender of this component when the unit changes
          />
        </Radio.Group>
      )}
    </div>
  )
}
