import { TLanguage, TPhrases, TMomentFormats } from '@semios/app-platform-i18n-core'
import { I18nReact } from '@semios/app-platform-i18n-react'
import * as Sentry from '@sentry/react'
// eslint-disable-next-line local-rules/no-dayjs-import
import dayjs from 'dayjs'
import moment, { type LocaleSpecification } from 'moment-timezone'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { init } from 'stores/_utils/simple-store'
import { userDetailsStore } from 'stores/userDetailsStore'

// We use a store for the global language so that we can use its useSelector to listen to changes when the global language is changed.
export const globalLanguageStore = init<TLanguage | ''>('') // Note that '' also refers to unknown language as seen in <html lang="">

const phraseModules = import.meta.glob('i18nLanguages/**/phrases.*.js', { import: 'default' })
const momentLocaleModules = import.meta.glob('i18nLanguages/**/momentLocale.js', { import: 'momentLocale' })

const momentFormatsModules = import.meta.glob('i18nLanguages/**/momentFormats.js', {
  import: 'momentFormats',
})

export const i18n = new I18nReact({
  namespaces: [
    'abbreviations',
    'abbreviationsCardinalDirections',
    'administrationAreaLevel1',
    'banyanApp',
    'countries',
    'dbAppPresetsSemiosDefined',
    'dbCropsName',
    'dbInsectName',
    'emitterTypes',
    'sidekick',
    'templates',
    'unitSymbols',
    'validation',
    'weatherConditions',
  ],
  onGlobalLanguageChange: async (lng) => {
    if (!HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD) {
      document.documentElement.setAttribute('lang', lng)
    }

    dayjs.locale(lng)

    globalLanguageStore.setState(() => lng)
  },
  fetchMomentResources: async (lng) => {
    const momentLocaleKey = `../i18n-resources/dist-esm/languages/${lng}/momentLocale.js`
    const momentFormatsKey = `../i18n-resources/dist-esm/languages/${lng}/momentFormats.js`

    let momentLocale: LocaleSpecification | null = null
    let momentFormats: TMomentFormats | null = null

    if (momentLocaleModules[momentLocaleKey]) {
      momentLocale = (await momentLocaleModules[momentLocaleKey]()) as LocaleSpecification | null
    }

    if (momentFormatsModules[momentFormatsKey]) {
      momentFormats = (await momentFormatsModules[momentFormatsKey]()) as TMomentFormats | null
    }

    if (momentLocale) {
      // This defines the local and changes it. Note that we also change the dayjs local in the onGlobalLanguageChange function above.
      dayjs.locale(lng, momentLocale as Partial<ILocale>)
    }

    return {
      momentFormats,
      momentLocale,
    }
  },
  fetchPhraseResources: async (lng, ns) => {
    const key = `../i18n-resources/dist-esm/languages/${lng}/phrases.${ns}.js`

    if (phraseModules[key]) {
      return (await phraseModules[key]()) as Promise<Record<TPhrases[typeof ns], string>>
    } else {
      return {} as Promise<Record<TPhrases[typeof ns], string>>
    }
  },
  momentLibrary: moment,
  makeCustomTranslation: (o) => {
    type TGoogleMapLocale = { language: string; region: string }

    type TCustomTranslation = {
      getGoogleMapLocale: () => TGoogleMapLocale
    }

    const googleMapLocales: Record<TLanguage, TGoogleMapLocale> = {
      'en': { language: 'en-US', region: 'US' },
      'en-AU': { language: 'en-AU', region: 'AU' },
      'es': { language: 'es-US', region: 'US' },
      'it': { language: 'it', region: 'IT' },
    }

    return {
      getGoogleMapLocale: () => {
        return googleMapLocales[o.lng || (o.i18nInstance.language as TLanguage)]
      },
    } as TCustomTranslation
  },
  onFetchResourceError: (error) => {
    // eslint-disable-next-line no-console
    console.error(error)

    Sentry.captureException(error) // TODO: i18n Sentry.captureException needed if we have the console.error above?
  },
})

export const initI18n = async () => {
  await i18n.init()

  const userProfileLanguage = userDetailsStore.getState().language

  await i18n.globalLng.changeLanguage(i18n.findClosestLanguage([userProfileLanguage, ...navigator.languages]))
}

export const LANGUAGES = I18nReact.LANGUAGES

export const translate = i18n.globalLng.translate
