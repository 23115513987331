import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil, sortByKey } from '@semios/app-platform-common'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingOverlay, Select, Table } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { getTimezoneForSelectedProperty } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedProperty'
import { TFieldAssetKeyTypes } from 'App/Map/types'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal'
import { InfoMessageBox } from 'components/InfoMessageBox/InfoMessageBox'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'
import { useApiREST } from 'utils/useApiREST'
import { useScreenSize } from 'utils/useScreenSize'

const refreshTheDetailsPanelData = () =>
  detailsPanelStore.setState((s) => ({ ...s, keyForRefreshing: new Date().toISOString() }))

const MODEL_PREDICTED = 'MODEL_PREDICTED'
const ONE_PERCENT_BLOOM = 'ONE_PERCENT_BLOOM'
const bloomSettingsSetPermission = 'EDIT_STATION_BLOOM_SETTINGS'

type TFieldAssetSettings = {
  blockId: number
  blockName: string
  calculationMethod: string
  overrideDate: Date | null
  year: number
}

type TBloomSettingsSet = Record<
  number,
  Record<
    TFieldAssetKeyTypes.TBlockId,
    {
      calculationMethod: string
      overrideDate: Date | null
      modified?: boolean
      autoPopulated?: boolean
    }
  >
>

export const AlmondBloomSettings = () => {
  const timezone = getTimezoneForSelectedProperty()
  const [almondEventSettingsOpened, setAlmondEventSettingsOpened] = useState(false)
  const [selectedYear, setSelectedYear] = useState<number>(moment.tz(timezone).startOf('year').year())
  const [stagedUpdates, setStagedUpdates] = useState<TBloomSettingsSet>({ [selectedYear]: {} })
  const [blockWithAllSettingsApplied, setBlockWithAllSettingsApplied] = useState<number | null>(null)
  const [confirmModalOpened, setConfirmModalOpened] = useState<boolean>(false)
  const { isWideScreen } = useScreenSize()

  const OverrideDatePicker = ({
    block,
    selectedDate,
  }: {
    block: TFieldAssetSettings
    selectedDate: Date | null
  }) => {
    const parentStyle = isWideScreen
      ? { gridRow: 1, gridColumn: 1 }
      : {
          gridRow: 2,
          gridColumn: 1,
          height: 35,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }

    const styleNA = isWideScreen ? {} : { padding: 5 }
    const datePickerStyle = isWideScreen ? {} : { width: '100%' }

    return (
      <div style={parentStyle}>
        {(block.calculationMethod === MODEL_PREDICTED &&
          isNil(stagedUpdates[selectedYear]?.[block.blockId])) ||
        stagedUpdates[selectedYear]?.[block.blockId]?.calculationMethod === MODEL_PREDICTED ? (
          <div style={styleNA}>{translate.phrases.abbreviations('Not Applicable')}</div>
        ) : (
          <DatePickerInput
            style={datePickerStyle}
            valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
            minDate={moment.tz(selectedYear.toString(), 'YYYY', timezone).startOf('year').toDate()}
            maxDate={moment.tz(selectedYear.toString(), 'YYYY', timezone).endOf('year').toDate()}
            value={selectedDate}
            rightSection={<FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />}
            onChange={(newDate) => {
              if (isNil(newDate)) return

              setBlockWithAllSettingsApplied(null)

              setStagedUpdates((prevState) => {
                return {
                  ...prevState,
                  [selectedYear]: {
                    ...prevState[selectedYear],
                    [block.blockId]: {
                      blockId: block.blockId,
                      calculationMethod: prevState[selectedYear][block.blockId]?.calculationMethod
                        ? prevState[selectedYear][block.blockId].calculationMethod
                        : block.calculationMethod,
                      overrideDate: new Date(newDate),
                      modified: true,
                      autoPopulated: false,
                    },
                  },
                }
              })
            }}
          />
        )}
      </div>
    )
  }

  const ApplySettingsToBlocks = ({ block }: { block: TFieldAssetSettings }) => {
    const applySettings = isWideScreen
      ? translate.phrases.banyanApp('Apply Settings to All Blocks')
      : translate.phrases.banyanApp('Apply to All')

    const settingsApplied = isWideScreen
      ? translate.phrases.banyanApp('Settings Applied to All Blocks')
      : translate.phrases.banyanApp('Settings Applied')

    const parentStyle = isWideScreen
      ? { gridRow: 2, gridColumn: 1 }
      : {
          gridRow: 1,
          gridColumn: 1,
          height: 35,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }

    return (
      <div style={parentStyle}>
        {stagedUpdates[selectedYear]?.[block.blockId]?.modified &&
          !stagedUpdates[selectedYear][block.blockId].autoPopulated && (
            <Button
              variant={'link'}
              onClick={() => {
                const updatedState: typeof stagedUpdates = { ...stagedUpdates, [selectedYear]: {} }

                initialBloomSettings.forEach((setting) => {
                  if (
                    setting.calculationMethod !==
                      stagedUpdates[selectedYear]?.[block.blockId].calculationMethod ||
                    setting.overrideDate !== stagedUpdates[selectedYear]?.[block.blockId].overrideDate
                  ) {
                    updatedState[selectedYear][setting.blockId] = {
                      ...stagedUpdates[selectedYear][block.blockId],
                      autoPopulated: true,
                      modified: false,
                    }
                  }
                })

                setBlockWithAllSettingsApplied(block.blockId)

                setStagedUpdates(updatedState)
              }}
            >
              <FontAwesomeIcon
                css={{ color: colors.primary, fontSize: 16, marginRight: 6 }}
                icon={faCircleCheck}
              />
              {applySettings}
            </Button>
          )}
        {block.blockId === blockWithAllSettingsApplied && (
          <span style={{ color: colors.midnight }}>
            <FontAwesomeIcon
              css={{ color: colors.green, fontSize: 16, marginRight: 6 }}
              icon={faCircleCheck}
            />
            {settingsApplied}
          </span>
        )}
      </div>
    )
  }

  const DatePickerCell = ({
    block,
    selectedDate,
  }: {
    block: TFieldAssetSettings
    selectedDate: Date | null
  }) => {
    const style = { display: 'grid' }

    return (
      <div style={style}>
        <OverrideDatePicker block={block} selectedDate={selectedDate} />
        <ApplySettingsToBlocks block={block} />
      </div>
    )
  }

  const SelectCell = ({ block, selectedDate }: { block: TFieldAssetSettings; selectedDate: Date | null }) => {
    const width = isWideScreen ? 'unset' : '100%'

    return (
      <>
        <Select
          style={{ width }}
          data={[
            { value: MODEL_PREDICTED, label: translate.phrases.banyanApp('Model Predicted') },
            {
              value: ONE_PERCENT_BLOOM,
              label: translate.phrases.banyanApp('1% Bloom'),
            },
          ]}
          value={
            !isNil(stagedUpdates[selectedYear]?.[block.blockId]?.calculationMethod)
              ? stagedUpdates[selectedYear]?.[block.blockId].calculationMethod
              : block.calculationMethod
          }
          onChange={(newState: string) => {
            setBlockWithAllSettingsApplied(null)

            setStagedUpdates((prevState) => {
              let overrideDate: Date | null

              if (isNil(stagedUpdates[selectedYear]?.[block.blockId] && !isNil(block.overrideDate))) {
                overrideDate = block.overrideDate || selectedDate
              } else {
                overrideDate = stagedUpdates[selectedYear]?.[block.blockId].overrideDate
              }

              return {
                ...prevState,
                [selectedYear]: {
                  ...prevState[selectedYear],
                  [block.blockId]: {
                    blockId: block.blockId,
                    calculationMethod: newState,
                    overrideDate: newState === MODEL_PREDICTED ? null : overrideDate,
                    autoPopulated: false,
                    modified: true,
                  },
                },
              }
            })
          }}
        />
      </>
    )
  }

  const { propertyId } = selectedFieldAssetsStore.useSelector((s) => ({
    propertyId: s.property,
  }))

  const selectedPropertyBlocks = fieldAssetStore.useSelector(
    (s) => s?.properties?.[Number(propertyId)]?.blocks,
  )

  const filteredPropertyBlocks = selectedPropertyBlocks
    ? Object.values(selectedPropertyBlocks).filter((value) => {
        return value.cropIds && value.cropIds.includes(2)
      })
    : []

  const selectedBlockIds = filteredPropertyBlocks.map((block) => block.blockId)

  const { data: initialBloomSettings, loading } = useApiREST<
    routes.FieldAssetSettingsGet.Request,
    routes.FieldAssetSettingsGet.Response,
    TFieldAssetSettings[]
  >({
    body: {
      bloom: {
        blockIds: selectedBlockIds,
        year: selectedYear,
      },
    },
    initialLoading: true,
    initialState: [],
    preventFetch: filteredPropertyBlocks.length === 0 || !almondEventSettingsOpened,
    shaper: (data) => {
      const bloomData = data.bloom ? data.bloom : []

      if (!(bloomData instanceof Array)) return []

      return bloomData
        .map((datum) => ({
          blockId: datum.blockId,
          blockName: datum.blockName,
          calculationMethod: datum.calculationMethod,
          overrideDate: datum.overrideDate ? new Date(datum.overrideDate) : null,
          year: datum.year,
        }))
        .sort(sortByKey('blockName'))
    },
    url: routes.FieldAssetSettingsGet.path,
    watchers: [selectedYear, almondEventSettingsOpened],
  })

  const updateButtonDisabled = !(Object.keys(stagedUpdates).length > 0)

  const items = initialBloomSettings.map((element: TFieldAssetSettings) => {
    let selectedDate: Date | null

    if (isNil(stagedUpdates[selectedYear][element.blockId]?.overrideDate)) {
      selectedDate = element.overrideDate
        ? element.overrideDate
        : moment.tz(new Date(), timezone).set('year', selectedYear).toDate()
    } else {
      selectedDate = stagedUpdates[selectedYear][element.blockId].overrideDate
    }

    if (isWideScreen) {
      return (
        <tr key={element.blockId} style={{ verticalAlign: 'top' }}>
          <td>{element.blockName}</td>
          <td>
            <SelectCell block={element} selectedDate={selectedDate} />
          </td>
          <td style={{ width: 270, marginTop: 5, textAlign: 'end' }}>
            <DatePickerCell block={element} selectedDate={selectedDate} />
          </td>
        </tr>
      )
    } else
      return (
        <tr style={{ marginBottom: 5, marginTop: 5 }}>
          <td>
            <div style={{ display: 'grid', gridTemplateRows: '50% 50%', width: '100%' }}>
              <div
                style={{
                  gridRow: 1,
                  gridColumn: 1,
                  height: 35,
                  verticalAlign: 'bottom',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                }}
              >
                {element.blockName}
              </div>
              <div
                style={{
                  height: 35,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'end',
                }}
              >
                <SelectCell block={element} selectedDate={selectedDate} />
              </div>
            </div>
          </td>
          <td style={{ width: 270, marginTop: 5, textAlign: 'end' }}>
            <div style={{ display: 'grid', gridTemplateRows: '50% 50%' }}>
              <DatePickerCell block={element} selectedDate={selectedDate} />
            </div>
          </td>
        </tr>
      )
  })

  const resetState = () => {
    const currentYear = moment.tz(timezone).startOf('year').year()

    setStagedUpdates({ [currentYear]: {} })

    setSelectedYear(currentYear)
  }

  const handleSubmit = async () => {
    const thingsToUpdate: {
      year: number
      blockId: number
      overrideDate: string
      calculationMethod: string
    }[] = []

    Object.entries(stagedUpdates).forEach(([year, blockData]) => {
      Object.entries(blockData).forEach(([blockId, bloomSettings]) => {
        thingsToUpdate.push({
          year: Number(year),
          blockId: Number(blockId),
          overrideDate: moment.tz(bloomSettings.overrideDate, timezone).toISOString(),
          calculationMethod: bloomSettings.calculationMethod,
        })
      })
    })

    if (Object.keys(thingsToUpdate).length === 0) {
      showNotification({
        message: translate.phrases.banyanApp('There were no settings to update.'),
        type: 'warning',
      })

      setAlmondEventSettingsOpened(false)

      resetState()

      refreshTheDetailsPanelData()

      return
    }

    try {
      const response = await apiFetch<
        routes.FieldAssetSettingsSet.Request,
        routes.FieldAssetSettingsSet.Response
      >({
        url: routes.FieldAssetSettingsSet.path,
        body: {
          bloomUpdate: thingsToUpdate,
        },
      })

      if (Array.isArray(response?.bloomUpdate)) {
        setAlmondEventSettingsOpened(false)

        resetState()

        showNotification({
          message: translate.phrases.banyanApp('Successfully updated settings'),
          type: 'success',
        })

        refreshTheDetailsPanelData()
      } else {
        showNotification({
          message: translate.phrases.banyanApp('Failed to update bloom settings'),
          type: 'error',
        })
      }
    } catch (error) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update bloom settings'),
        type: 'error',
      })
    }
  }

  const closeModal = () => {
    resetState()

    close()

    setAlmondEventSettingsOpened(false)
  }

  const confirmChangesStart = () => {
    let anyChanges = Object.values(stagedUpdates).some((stagedUpdate) => {
      return Object.keys(stagedUpdate).length !== 0
    })

    if (anyChanges) setConfirmModalOpened(true)
    else {
      resetState()

      setAlmondEventSettingsOpened(false)
    }
  }

  return (
    <Authorization requires={{ permission: bloomSettingsSetPermission, entity: Number(propertyId) }}>
      <>
        <ModalDrawer
          title={translate.phrases.banyanApp('Edit Bloom Settings')}
          opened={almondEventSettingsOpened}
          onClose={confirmChangesStart}
          primaryButtonText={translate.phrases.banyanApp('Save Changes')}
          primaryButtonOnPress={handleSubmit}
          primaryButtonDisabled={updateButtonDisabled}
          secondaryButtonText={translate.phrases.banyanApp('Cancel')}
          secondaryButtonOnPress={confirmChangesStart}
          zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
        >
          <div css={{ padding: 20, color: colors.midnight, display: 'grid', height: '100%' }}>
            <LoadingOverlay visible={loading} />
            {isWideScreen && (
              <div>
                <div style={{ marginBottom: 14 }}>
                  <InfoMessageBox>
                    {translate.phrases.banyanApp(
                      'It may take up to 10 minutes to update the bloom prediction model after saving your changes.',
                    )}
                  </InfoMessageBox>
                </div>
                <Select
                  data={[...Array(moment.tz(timezone).year() - 2019 + 1).keys()].map((k) =>
                    (k + 2019).toString(),
                  )}
                  value={selectedYear.toString()}
                  onChange={(newState: string) => {
                    setSelectedYear(Number(newState))

                    if (isNil(stagedUpdates[Number(newState)])) {
                      setStagedUpdates((prevState) => ({
                        ...prevState,
                        [Number(newState)]: {},
                      }))
                    }
                  }}
                  style={{ width: '100%', marginTop: 5, marginBottom: 10 }}
                />
                <Table>
                  <thead>
                    <tr>
                      <th>{translate.phrases.banyanApp('Block')}</th>
                      <th>{translate.phrases.banyanApp('Biofix Type')}</th>
                      <th>{translate.phrases.banyanApp('1% Bloom Date')}</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderBottom: '0.0625rem solid #dee2e6' }}>{items}</tbody>
                </Table>
              </div>
            )}

            {!isWideScreen && (
              <div>
                <div style={{ marginBottom: 14 }}>
                  <InfoMessageBox>
                    {translate.phrases.banyanApp(
                      'It may take up to 10 minutes to update the bloom prediction model after saving your changes.',
                    )}
                  </InfoMessageBox>
                </div>
                <Select
                  data={[...Array(moment.tz(timezone).year() - 2019 + 1).keys()].map((k) =>
                    (k + 2019).toString(),
                  )}
                  value={selectedYear.toString()}
                  onChange={(newState: string) => {
                    setSelectedYear(Number(newState))

                    if (isNil(stagedUpdates[Number(newState)])) {
                      setStagedUpdates((prevState) => ({
                        ...prevState,
                        [Number(newState)]: {},
                      }))
                    }
                  }}
                  style={{ width: '100%', marginTop: 5, marginBottom: 10 }}
                />
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: '50%' }}>{translate.phrases.banyanApp('Biofix Type')}</th>
                      <th style={{ width: '50%' }}>{translate.phrases.banyanApp('1% Bloom Date')}</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderBottom: '0.0625rem solid #dee2e6' }}>{items}</tbody>
                </Table>
              </div>
            )}
          </div>
          <ConfirmationModal
            title={translate.phrases.banyanApp('Unsaved Changes')}
            content={
              <div>
                {translate.phrases.banyanApp("Any changes you have made won't be saved.")}
                <br />
                {translate.phrases.banyanApp('Select an option below.')}
              </div>
            }
            modalWindowSize={350}
            buttonContent={
              <div
                style={{
                  color: colors.midnight,
                  display: 'grid',
                  gridTemplateColumns: '50% 50%',
                  width: '100%',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  variant={'negative'}
                  style={{ gridColumn: 1, gridRow: 1, marginRight: 2, width: 160, color: colors.midnight }}
                  onClick={() => {
                    setConfirmModalOpened(false)

                    closeModal()
                  }}
                >
                  {translate.phrases.banyanApp('Discard Changes')}
                </Button>
                <Button
                  variant={'secondary'}
                  css={{
                    'gridColumn': 2,
                    'gridRow': 1,
                    'width': 160,
                    'justifySelf': 'end',
                    'borderColor': colors.primary,
                    'color': colors.primary,
                    '&:not([disabled],[data-loading="true"]):hover': {
                      color: colors.primary,
                      background: colors.white,
                      shadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', // Matches mantine style
                    },
                  }}
                  onClick={() => {
                    setConfirmModalOpened(false)
                  }}
                >
                  {translate.phrases.banyanApp('Keep Editing')}
                </Button>
                <Button
                  variant={'primary'}
                  style={{
                    gridArea: '2 / 1 / 2 / 3',
                    marginTop: 10,
                  }}
                  onClick={() => {
                    setConfirmModalOpened(false)

                    handleSubmit()
                  }}
                >
                  {translate.phrases.banyanApp('Save Changes')}
                </Button>
              </div>
            }
            modalOpened={confirmModalOpened}
            closeModal={() => setConfirmModalOpened(false)}
          />
        </ModalDrawer>
        <Button
          variant={'link'}
          onClick={() => setAlmondEventSettingsOpened(true)}
          style={{
            marginLeft: 'auto',
            marginRight: 0,
            display: filteredPropertyBlocks.length > 0 ? 'unset' : 'none',
          }}
        >
          {translate.phrases.banyanApp('Edit Bloom Settings')}
        </Button>
      </>
    </Authorization>
  )
}
