import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconEllipsis = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 18 20')}>
      <circle cx="2.5" cy="2.5" r="2.5" fill="currentColor" />
      <circle cx="2.5" cy="9.5" r="2.5" fill="currentColor" />
      <circle cx="2.5" cy="16.5" r="2.5" fill="currentColor" />
    </svg>
  )
}
