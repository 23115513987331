import { NumberInput } from 'components/NumberInput/NumberInput'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

export const DefaultHeatmapRow = ({
  suffix: suffixProp,
  unitConverterFunctionForRendering,
  unitConverterFunctionForSaving,
}: {
  suffix?: string
  unitConverterFunctionForRendering?: typeof unitConverter[keyof typeof unitConverter]
  unitConverterFunctionForSaving?: typeof unitConverter[keyof typeof unitConverter]
}) => {
  const heatmapColoring = mapControlsStore.useSelector((s) => s.heatmapColoring)
  const heatmapExtremes = mapControlsStore.useSelector((s) => s.heatmapExtremes)

  if (!heatmapColoring.length) return null

  const suffix = suffixProp ?? unitConverterFunctionForRendering?.()?.suffix()

  return (
    <div
      css={{
        width: '100%',
        background: 'transparent',
        borderRadius: 3,
        display: 'flex',
      }}
    >
      <div>
        <NumberInput
          max={
            unitConverterFunctionForRendering
              ? (unitConverterFunctionForRendering(heatmapExtremes.max).value() as number)
              : heatmapExtremes.max
          }
          value={
            unitConverterFunctionForRendering
              ? (unitConverterFunctionForRendering(heatmapExtremes.min).value() as number)
              : heatmapExtremes.min
          }
          css={{ maxWidth: 44 }}
          styles={{ input: { padding: 0, textAlign: 'center' } }}
          hideControls
          onChange={(newMin) => {
            if (newMin === '') return

            const newValue = unitConverterFunctionForSaving
              ? (unitConverterFunctionForSaving(newMin).value() as number)
              : newMin

            mapControlsStore.setState((s) => ({
              ...s,
              heatmapExtremes: {
                ...s.heatmapExtremes,
                min: newValue,
              },
              manualExtremes: true,
            }))
          }}
        />
      </div>
      <div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            marginTop: 4,
            width: '100%',
            display: 'flex',
            background: colors.grey50,
            padding: '8px 6px',
          }}
        >
          {heatmapColoring.map((color, i) => (
            <div css={{ flex: 1, background: color, height: 12 }} key={i} />
          ))}
        </div>
        {!!suffix && <div css={{ fontSize: 12, marginTop: 4 }}>{suffix}</div>}
      </div>
      <div>
        <NumberInput
          min={
            unitConverterFunctionForRendering
              ? (unitConverterFunctionForRendering(heatmapExtremes.min).value() as number)
              : heatmapExtremes.min
          }
          value={
            unitConverterFunctionForRendering
              ? (unitConverterFunctionForRendering(heatmapExtremes.max).value() as number)
              : heatmapExtremes.max
          }
          css={{ maxWidth: 44 }}
          styles={{ input: { padding: 0, textAlign: 'center' } }}
          hideControls
          onChange={(newMax) => {
            if (newMax === '') return

            const newValue = unitConverterFunctionForSaving
              ? (unitConverterFunctionForSaving(newMax).value() as number)
              : newMax

            mapControlsStore.setState((s) => ({
              ...s,
              heatmapExtremes: {
                ...s.heatmapExtremes,
                max: newValue,
              },
              manualExtremes: true,
            }))
          }}
        />
      </div>
    </div>
  )
}
