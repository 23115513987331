import React, { useMemo, useState } from 'react'
import { translate } from 'i18n/i18n'
import { Footer } from '../components/Footer/Footer'
import { GalleryPhoto, Photo } from '@capacitor/camera'
import { showError } from '../../utils/showError'
import { installationWorkflowStore } from 'App/ServiceCenter/store/installationWorkflowStore'
import { uploadInstallationImage } from 'App/ServiceCenter/utils/uploadInstallationImage'
import { closeModal } from '@mantine/modals'
import { INSTALLATION_OVERVIEW_MODAL_ID } from '../InstallationOverviewModal'
import { showSuccess } from 'App/ServiceCenter/utils/showSuccess'
import { InstallationStep, TActiveNode } from 'App/ServiceCenter/types'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { ImageUpload } from 'App/ServiceCenter/ImageUpload/ImageUpload'

interface ReviewProps {
  onClose: () => void
}

export const Review: React.FC<ReviewProps> = ({ onClose }) => {
  const [image, setImage] = useState<Photo | GalleryPhoto | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const dropLocation = installationWorkflowStore.getState().dropInstallLocation as google.maps.LatLngLiteral

  const selectedNode = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentNode,
  ) as TActiveNode

  const installationSteps = installationWorkflowStore.useSelector(
    installationWorkflowStore.selectors.getSteps,
  )

  const handleFinishInstallation = async () => {
    setIsSaving(true)

    try {
      if (!image)
        throw new Error(translate.phrases.placeholder('Please upload a photo of the installed equipment.'))

      // Upload image
      const action = dropLocation ? 'EQUIP_INSTALL_DROP' : 'EQUIP_INSTALL_PSEUDO'

      await uploadInstallationImage(selectedNode.nodeIdentifier, image, action)

      closeModal(INSTALLATION_OVERVIEW_MODAL_ID)

      onClose()

      installationWorkflowStore.actions.reset()

      showSuccess(
        translate.phrases.placeholder('Installation Completed'),
        <>
          {translate.phrases.placeholder(
            'Now that the installation is completed your station will appear as active equipment.',
          )}
          <br />
          <br />
          {translate.phrases.placeholder('The station status should be updated within 10-15 minutes.')}
        </>,
      )
    } catch (error) {
      setIsSaving(false)

      let errorMessage: string = error as string

      if (error instanceof Error) {
        errorMessage = error.message
      }

      showError(translate.phrases.placeholder('Installation Error'), errorMessage)
    }
  }

  const handleBack = () => {
    installationWorkflowStore.actions.showPreviousStep()
  }

  const canGoBack = useMemo(
    () => installationSteps.some(({ type }) => type === InstallationStep.CHECKLIST),
    [installationSteps],
  )

  return (
    <div css={{ padding: 10 }}>
      <h3 css={{ margin: 0 }}>{translate.phrases.placeholder('Review')}</h3>

      <p css={{ lineHeight: '24px', marginTop: 10, marginBottom: 20 }}>
        {translate.phrases.placeholder('Double check that the equipment is installed properly.')}
        <br />
        <br />
        {translate.phrases.placeholder('Upload a photo and finalize the install.')}
      </p>

      <ImageUpload image={image} setImage={setImage} />

      <Footer
        onPrevious={handleBack}
        onNext={handleFinishInstallation}
        nextButtonLabel={translate.phrases.placeholder('Finish')}
        loading={isSaving}
        showPreviousButton={canGoBack}
        disableNextButton={!image}
      />
    </div>
  )
}
