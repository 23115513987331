import { css } from '@emotion/css'
import { Popover } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useClickOutside, useElementSize, useViewportSize } from '@mantine/hooks'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { useEffect, useRef, useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { DateRangePickerDropdownProps } from './types'

export const DateRangePickerDropdown = (props: DateRangePickerDropdownProps) => {
  const datePickerInputRef = useRef(null)
  const viewport = useViewportSize()
  const popoverSize = useElementSize()

  const thereIsRoomForDropdown =
    viewport.height > SharedSettings.MINIMUM_VIEWPORT_HEIGHT_FOR_DATE_RANGE_PICKER_DROPDOWN

  const [renderCalendar, setRenderCalendar] = useState<boolean>(true)

  const resetCalendar = async () => {
    await setRenderCalendar(false)

    setRenderCalendar(true)
  } // ! This reset along with defaultDate forces the calendar to re-render in the month of the selected date

  useEffect(() => {
    resetCalendar()
  }, [props.value])

  const containerRef = useClickOutside(() => {
    if (thereIsRoomForDropdown) props.dismiss()
  })

  const defaultDate = props.value[0] ? props.value[0] : undefined

  const dropDownChildren = (
    <div ref={containerRef} css={{ maxWidth: '100%' }}>
      {renderCalendar && (
        <DatePicker<'range'>
          allowSingleDateInRange={props.allowSingleDateInRange}
          defaultValue={props.defaultValue}
          maxDate={props.maxDate}
          onChange={(dates) => {
            if (dates[0]) {
              props.setValue(dates)
            }
          }}
          renderDay={(day) => props.renderDayWithValue(day, props.value)}
          ref={datePickerInputRef}
          styles={props.styles}
          type="range"
          value={props.value}
          defaultDate={defaultDate}
          size={thereIsRoomForDropdown ? 'sm' : 'lg'}
          css={{ display: 'flex', justifyContent: 'center', maxWidth: '100%' }}
        />
      )}
      <div
        css={{
          borderTop: `1px solid ${colors.grey500}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '10px',
          width: '100%',
        }}
      >
        {props.Footer({
          value: props.value,
          handleValueChange: props.setValue,
          setDropdownOpened: props.setDropdownOpened,
        })}
      </div>
    </div>
  )

  return thereIsRoomForDropdown ? (
    <Popover transitionProps={{ transition: 'slide-down' }} position="bottom">
      <div
        css={{
          border: props.dropDownOpened ? `1px solid ${colors.grey200}` : 'unset',
          borderRadius: 3,
          overflowY: 'hidden',
          boxShadow: props.dropDownOpened ? `2px 2px 2px ${colors.grey200}` : 'unset',
        }}
      >
        {props.dropDownOpened && (
          <div
            ref={popoverSize.ref}
            css={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 10px 10px',
              background: colors.white,
            }}
          >
            {dropDownChildren}
          </div>
        )}
      </div>
    </Popover>
  ) : (
    <ModalDrawer
      opened={props.dropDownOpened}
      onClose={() => props.dismiss()}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      <div
        className={css({
          justifyContent: 'center',
          alignSelf: 'center',
          justifySelf: 'center',
          display: 'flex',
          minHeight: 550,
          padding: '0 12px 12px 12px',
        })}
      >
        {dropDownChildren}
      </div>
    </ModalDrawer>
  )
}
