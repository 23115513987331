import { isNfcSupported } from 'components/NfcScanner/NfcScanner'
import { isQrSupported } from 'components/QrCodeScanner/QrCodeScanner'
import { useEffect, useState } from 'react'

export const useScannerSupportCheck = () => {
  const [isSupportQR, setIsSupportQR] = useState(false)
  const [isSupportNfc, setIsSupportNfc] = useState(false)
  const [isQRActive, setIsQRActive] = useState(false)
  const [isNFCActive, setIsNFCActive] = useState(false)
  const [scanError, setScanError] = useState<string | null>(null)

  const handleScan = (
    type: 'NFC' | 'QR',
    args: { value?: string; error?: string },
    onSuccess: (scannedData: string) => void,
  ) => {
    if (type === 'NFC') {
      setIsNFCActive(true)
    } else {
      setIsQRActive(false)
    }

    if (args.error) {
      setScanError(args.error)
    } else if (args.value) {
      onSuccess(args.value)

      setScanError(null)
    }
  }

  useEffect(() => {
    const checkSupportScan = async () => {
      setIsSupportQR(await isQrSupported())

      setIsSupportNfc(await isNfcSupported())
    }

    checkSupportScan()
  }, [])

  return {
    NFC: {
      supported: isSupportNfc,
      active: isNFCActive,
      setActive: setIsNFCActive,
    },
    QR: {
      supported: isSupportQR,
      active: isQRActive,
      setActive: setIsQRActive,
    },
    handleScan,
    scanError,
  }
}
