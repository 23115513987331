import { CSSObject } from '@emotion/react'
import { Alert, useMantineTheme } from '@mantine/core'
import { IconErrorCircle } from 'components/icons/IconErrorCircle'
import React from 'react'

export const ErrorMessage: React.FC<{ errorMessage: string | null; style?: CSSObject }> = ({
  errorMessage,
  style = {},
}) => {
  const theme = useMantineTheme()

  return (
    <Alert
      icon={
        <span css={{ color: theme.colors.red[0] }}>
          <IconErrorCircle />
        </span>
      }
      styles={{
        root: { backgroundColor: theme.colors.midnight[0] },
        message: { color: theme.colors.white[0] },
      }}
      css={{ ...style, marginTop: 20 }}
    >
      {errorMessage}
    </Alert>
  )
}
