import React from 'react'
import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconCameraFill = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 18')}>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5001 11.2004C9.82558 11.2004 10.9001 10.1259 10.9001 8.80039C10.9001 7.47491 9.82558 6.40039 8.5001 6.40039C7.17461 6.40039 6.1001 7.47491 6.1001 8.80039C6.1001 10.1259 7.17461 11.2004 8.5001 11.2004Z"
          fill="currentColor"
        />
        <path
          d="M12.364 2.40078L10.9 0.800781H6.1L4.636 2.40078H0.5V15.2008H16.5V2.40078H12.364ZM8.5 12.8008C6.292 12.8008 4.5 11.0088 4.5 8.80078C4.5 6.59278 6.292 4.80078 8.5 4.80078C10.708 4.80078 12.5 6.59278 12.5 8.80078C12.5 11.0088 10.708 12.8008 8.5 12.8008Z"
          fill="currentColor"
        />
      </svg>
    </svg>
  )
}
