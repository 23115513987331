import { NotificationProps, showNotification as mantineShowNotification } from '@mantine/notifications'
import React from 'react'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'

const baseNotificationProps: Omit<NotificationProps, 'message'> = {
  styles: (theme) => ({
    root: {
      'alignItems': 'flex-start', // puts the close icon in the top right, instead of vertical middle
      '&::before': { display: 'none' }, // gets rid of the blue bar on the left
      'marginTop': 'env(safe-area-inset-top)', // Related to the position of the Notifications component. e.g. <Notifications position="top-center" />
    },
    description: { color: theme.black, fontWeight: 500 },
  }),
}

const MessageWrapper = (props: { icon: React.ReactNode; children: React.ReactNode }) => (
  <div css={{ display: 'flex', gap: 10, marginTop: 6, marginBottom: 6 }}>
    <div>{props.icon}</div>
    <div>{props.children}</div>
  </div>
)

export type TShowNotificationArgs = { id?: string; message: string; type: 'success' | 'error' | 'warning' }

export const showNotification = (props: TShowNotificationArgs) => {
  if (HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD && hubConnectorSettings.onShowNotification) {
    return hubConnectorSettings.onShowNotification(props)
  }

  switch (props.type) {
    case 'error': {
      return mantineShowNotification({
        ...baseNotificationProps,
        ...props,
        message: (
          <MessageWrapper
            icon={
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.2773" cy="10.7034" r="10" fill={colors.red} />
                <path
                  d="M14.2773 7.50908L13.4716 6.70337L10.2773 9.89766L7.08306 6.70337L6.27734 7.50908L9.47163 10.7034L6.27734 13.8977L7.08306 14.7034L10.2773 11.5091L13.4716 14.7034L14.2773 13.8977L11.0831 10.7034L14.2773 7.50908Z"
                  fill="white"
                />
              </svg>
            }
          >
            {props.message}
          </MessageWrapper>
        ),
      })
    }

    case 'success': {
      return mantineShowNotification({
        ...baseNotificationProps,
        ...props,
        message: (
          <MessageWrapper
            icon={
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.2422" cy="10.5366" r="10" fill={colors.green} />
                <path
                  d="M8.06037 13.656L5.19673 10.8351L4.24219 11.7754L8.06037 15.5366L16.2422 7.47692L15.2876 6.53662L8.06037 13.656Z"
                  fill="white"
                />
              </svg>
            }
          >
            {props.message}
          </MessageWrapper>
        ),
      })
    }

    default: {
      return mantineShowNotification({
        ...baseNotificationProps,
        ...props,
        message: (
          <MessageWrapper
            icon={
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10.6448" r="10" fill={colors.yellow} />
                <path
                  d="M11.3916 5.98071L11.0767 13.2683H9.06982L8.75488 5.98071H11.3916ZM8.68896 15.5388C8.68896 15.1873 8.81592 14.8943 9.06982 14.6599C9.32373 14.4207 9.65332 14.301 10.0586 14.301C10.4639 14.301 10.791 14.4207 11.04 14.6599C11.2939 14.8943 11.4209 15.1873 11.4209 15.5388C11.4209 15.8904 11.2939 16.1858 11.04 16.425C10.791 16.6594 10.4639 16.7766 10.0586 16.7766C9.65332 16.7766 9.32373 16.6594 9.06982 16.425C8.81592 16.1858 8.68896 15.8904 8.68896 15.5388Z"
                  fill="white"
                />
              </svg>
            }
          >
            {props.message}
          </MessageWrapper>
        ),
      })
    }
  }
}
